import React, { useCallback, useState } from 'react';
import { getUserInfo, getToken } from '@common/services';
import { useNavigate } from 'react-router-dom';
import { logout, goToLoginApp } from '@wisory-international-ab/login-frontend';

import { SELECTED_ROLE_LOCAL_STORAGE_KEY, UserRole } from '@/constants';
import { useAppRedirectUrl } from '@/hooks';
import { trackUser } from '@/common/services/monitor';

export interface AuthContextType {
  user: API.UserInfoModel | undefined;
  selectedRole?: UserRole;
  isSigning: boolean;
  isAdvisor: boolean;
  isDecisionMaker: boolean;
  isUser: boolean;
  goToLoginPage: () => void;
  signOut: () => void;
  setUser: (user: API.UserInfoModel) => void;
  tryToLogin: () => Promise<void>;
  getProfile: () => Promise<void>;
  navigateToApp: VoidFunction;
  setSelectedRole: (newRole: UserRole) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<API.UserInfoModel | undefined>(undefined);
  const [selectedRole, setSelectedRole] = useState<UserRole | undefined>();
  const [isSigning, setIsSigning] = useState(false);
  const redirectUrl = useAppRedirectUrl();
  const getProfile = async () => {
    const userInfo = await getUserInfo();
    const localStorageData: any = localStorage.getItem(
      `${SELECTED_ROLE_LOCAL_STORAGE_KEY}_${userInfo.id}`
    );
    const role = localStorageData || userInfo.roles[0];

    setSelectedRole(role);

    trackUser({
      email: userInfo.email,
      id: userInfo.id,
      name: userInfo.fullName,
      company: userInfo?.company?.companyName,
      role,
    });

    setUser(userInfo);
  };

  const navigateToApp = () => {
    window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    navigate(redirectUrl, { replace: true });
  };

  const goToLoginPage = useCallback(() => {
    goToLoginApp(encodeURIComponent(window.location.search));
  }, []);

  const tryToLogin = async () => {
    setIsSigning(true);

    const userSession = getToken();

    if (userSession) {
      await getProfile();
      setIsSigning(false);
      navigateToApp();
    }
  };

  const signOut = () => {
    setUser(undefined);
    localStorage.removeItem(`${SELECTED_ROLE_LOCAL_STORAGE_KEY}_${user?.id}`);
    logout();
  };

  const isAdvisor = selectedRole === UserRole.Advisor;
  const isDecisionMaker = selectedRole === UserRole.DecisionMaker;
  const isUser = selectedRole === UserRole.User;

  const value = {
    user,
    isSigning,
    isAdvisor,
    isDecisionMaker,
    isUser,
    selectedRole,
    setUser,
    tryToLogin,
    goToLoginPage,
    signOut,
    getProfile,
    navigateToApp,
    setSelectedRole: (newRole: UserRole) => {
      localStorage.setItem(`${SELECTED_ROLE_LOCAL_STORAGE_KEY}_${user?.id}`, newRole);
      setSelectedRole(newRole);
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
