import classNames from 'classnames';
import styles from './styles.module.scss';

const SuccessIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M12.5 0C19.403 0 25 5.597 25 12.5S19.403 25 12.5 25 0 19.403 0 12.5 5.597 0 12.5 0zm.953 4.608a2.129 2.129 0 1 1 0 4.254 2.129 2.129 0 0 1 0-4.254zM9.845 10.05h4.444c.706 0 .902.603.772 1.066-.133.46-1.388 7.1-.946 7.849.44.749 1.46-.493 1.812-.514.35-.023.616.302.425.815-.192.511-1.314 1.644-3.676 1.644-2.361 0-3.169-1.968-2.773-3.992.396-2.025.93-4.534.961-4.88.028-.344-.018-.792-.583-.786-.204 0-.403-.005-.572-.036-.818-.153-.603-1.186.135-1.165z"
        fill="#1E7451"
      />
    </svg>
  );
};

const ErrorIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M12.5 0C19.403 0 25 5.597 25 12.5S19.403 25 12.5 25 0 19.403 0 12.5 5.597 0 12.5 0zm.953 4.608a2.129 2.129 0 1 1 0 4.254 2.129 2.129 0 0 1 0-4.254zM9.845 10.05h4.444c.706 0 .902.603.772 1.066-.133.46-1.388 7.1-.946 7.849.44.749 1.46-.493 1.812-.514.35-.023.616.302.425.815-.192.511-1.314 1.644-3.676 1.644-2.361 0-3.169-1.968-2.773-3.992.396-2.025.93-4.534.961-4.88.028-.344-.018-.792-.583-.786-.204 0-.403-.005-.572-.036-.818-.153-.603-1.186.135-1.165z"
        fill="#E22D2D"
      />
    </svg>
  );
};

type Props = {
  className?: string;
  type: 'success' | 'error';
};

const ICONS = {
  error: ErrorIcon,
  success: SuccessIcon,
};

export const SummaryBlock = ({
  children,
  className = '',
  type,
}: React.PropsWithChildren<Props>) => {
  const SignIcon = ICONS[type];

  return (
    <div className={classNames(styles.wrapper, styles[type], className)}>
      <SignIcon />
      <div>{children}</div>
    </div>
  );
};
