export const PASSWORD_POLICY = {
  requireLowerletter: 'Password must contain a lower case letter',
  requireUpperletter: 'Password must contain an upper case letter',
  requireNumber: 'Password must contain a number',
  requireSymbol: 'Password must contain a special character or a space',
  requireNoLeadingOrTrailingSpaces: 'Password must not contain a leading or trailing space',
  requireLength: 'Password must contain at least 8 characters',
};

export function isLocalhost(url: string) {
  return url.includes('localhost') || url.includes('127.0.0.1');
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkPassword(password: string) {
  const passwordLength = 8;

  let requireLowerletter = false;
  let requireUpperletter = false;
  let requireNumber = false;
  let requireSymbol = false;
  let requireLength = false;
  let requireNoLeadingOrTrailingSpaces = false;

  if (password || password === '') {
    if (/[a-z]/.test(password)) {
      requireLowerletter = true;
    } else {
      requireLowerletter = false;
    }

    if (/[A-Z]/.test(password)) {
      requireUpperletter = true;
    } else {
      requireUpperletter = false;
    }

    if (
      /[-+=!$%^&*()_|~`{}\\[\]:\\/;<>?,.@#'"]|(?:\S)\s+(?=\S)/.test(password) ||
      password.indexOf('\\') >= 0
    ) {
      requireSymbol = true;
    } else {
      requireSymbol = false;
    }

    if (/[0-9]/.test(password)) {
      requireNumber = true;
    } else {
      requireNumber = false;
    }

    if (password.length < passwordLength) {
      requireLength = false;
    } else {
      requireLength = true;
    }
    if (/^\s+|\s+$/.test(password)) {
      requireNoLeadingOrTrailingSpaces = false;
    } else {
      requireNoLeadingOrTrailingSpaces = true;
    }
  }

  return {
    requireLowerletter,
    requireUpperletter,
    requireNumber,
    requireLength,
    requireSymbol,
    requireNoLeadingOrTrailingSpaces,
  };
}

export function partialHideEmail(email: string) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
}

export function partialHidePhoneNumber(phoneNumber: string) {
  return phoneNumber.slice(0, 3) + phoneNumber.slice(3).replace(/.(?=...)/g, '*');
}
