import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { APP_ROUTES, UserRole } from '@/constants';
import { useAuth } from '@/hooks';
import { getAppConfigurations } from '@/common/utils';
import styles from './styles.module.scss';

const { myCoursesUrl, allAdvisorsUrl } = getAppConfigurations();

export const NavigationItems = ({
  className = '',
  onNavigateItemClick,
}: {
  className?: string;
  onNavigateItemClick?: VoidFunction;
}) => {
  const { isUser, isAdvisor, isDecisionMaker, user } = useAuth();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const isContentSubscriber = user?.roles.some((u) => u === UserRole.ContentSubscriber);
  const canSeeMySessions = isUser || isAdvisor;

  const navigationContent = (
    <>
      {user && (
        <div
          className={styles.link}
          onClick={() => {
            onNavigateItemClick?.();
            navigate(APP_ROUTES.HOME);
          }}
        >
          <span>{t('header.navigations.dashboard')}</span>
        </div>
      )}

      {canSeeMySessions && (
        <div
          className={styles.link}
          onClick={() => {
            onNavigateItemClick?.();
            navigate(APP_ROUTES.MY_SESSIONS);
          }}
        >
          <span>{t('header.navigations.sessions')}</span>
        </div>
      )}

      {isUser && (
        <div
          className={styles.link}
          onClick={() => {
            onNavigateItemClick?.();
            window.open(allAdvisorsUrl, '_blank');
          }}
        >
          <span>{t('header.navigations.advisors')}</span>
        </div>
      )}

      {isDecisionMaker && (
        <div
          className={styles.link}
          onClick={() => {
            onNavigateItemClick?.();
            navigate(APP_ROUTES.COMPANY);
          }}
        >
          <span>{t('header.navigations.company')}</span>
        </div>
      )}

      {isContentSubscriber && (
        <div
          className={styles.link}
          onClick={() => {
            onNavigateItemClick?.();
            window.open(myCoursesUrl, '_blank');
          }}
        >
          <span>{t('header.navigations.courses')}</span>
        </div>
      )}
    </>
  );

  return <div className={classNames(styles.wrapper, className)}>{navigationContent}</div>;
};
