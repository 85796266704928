import { Modal, notification } from 'antd';
import i18n from '@/i18n';
import { ConfirmationModal } from '../components/confirmation-modal';
import { ErrorSign, SuccessSign } from '../components/icons';

function confirmModal({
  title,
  content,
  onConfirm,
  onClose,
}: {
  title: string;
  content: JSX.Element;
  onConfirm: VoidFunction;
  onClose?: VoidFunction;
}) {
  const t = i18n.getFixedT(null, 'common');

  Modal.confirm({
    title: null,
    footer: null,
    modalRender() {
      return (
        <ConfirmationModal
          title={title}
          onConfirm={onConfirm}
          onCancel={() => {
            Modal.destroyAll();
            onClose?.();
          }}
          cancelText={t('form.cancel')}
          confirmText={t('form.yes')}
        >
          {content}
        </ConfirmationModal>
      );
    },
  });
}

function showNotification({ type, message }: { message: string; type: 'error' | 'success' }) {
  if (type === 'error') {
    notification.error({
      message,
      closeIcon: null,
      icon: <ErrorSign />,
      style: {
        backgroundColor: '#fdf3f2',
      },
    });
  } else {
    notification.info({
      message,
      closeIcon: null,
      icon: <SuccessSign />,
      style: {
        backgroundColor: '#f3f5f4',
      },
    });
  }
}

export { confirmModal, showNotification };
