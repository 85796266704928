import { Row, Col, Button, Typography, Input, Space, notification } from 'antd';
import { useImmer } from 'use-immer';
import { useMutation } from 'react-query';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { isDuplicatedArray, notEmpty } from '@/common/utils/array';
import { PeriodPicker } from '@/common/components/datetime-picker';
import { ArrowLeftIcon } from '@/common/components/icons';
import { DATE_TIME_FORMAT } from '@/constants';
import { disableBookingDate } from '@/common/utils';
import useApplicationSettings from '@/hooks/useApplicationSettings';
import styles from './styles.module.scss';

type Suggestion = { startTime: Dayjs; endTime: Dayjs } | undefined;

type SuggestedPeriodsParams = {
  suggestedSlots: { startTime: string; endTime: string }[];
  message: string;
};

type Props = {
  title: string;
  defaultMessage: string;
  isRescheduled?: boolean;
  sendSuggestions: (props: SuggestedPeriodsParams) => Promise<any>;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
};

export function SuggestedPeriods({
  title,
  isRescheduled,
  defaultMessage,
  sendSuggestions,
  onCancel,
  onSuccess,
}: Props) {
  const { appSettings } = useApplicationSettings();
  const { t } = useTranslation(['common']);
  const [userMessage, setUserMessage] = useState(defaultMessage);
  const [suggestions, setSuggestions] = useImmer<Suggestion[]>([undefined, undefined, undefined]);
  const isSelectedAtLeastTwoOptions = useMemo(
    () => suggestions.filter(notEmpty).length >= 2,
    [suggestions]
  );
  const hasDuplicatedSlots = useMemo(() => {
    return isDuplicatedArray(
      suggestions.filter(notEmpty).map((s) => s.startTime?.format(DATE_TIME_FORMAT.DATE_ONLY))
    );
  }, [suggestions]);

  const suggestSlots = useMutation(
    () => {
      return sendSuggestions({
        message: userMessage,
        suggestedSlots: suggestions.filter(notEmpty).map((s) => {
          return {
            startTime: s.startTime.toISOString(),
            endTime: s.endTime.toISOString(),
          };
        }),
      });
    },
    {
      onSuccess,
    }
  );

  const onSuggestClick = async () => {
    if (!isSelectedAtLeastTwoOptions) {
      notification.error({ message: t('home.interestForm.slotValidation') });
    } else {
      suggestSlots.mutate();
    }
  };

  return (
    <>
      <Row className={styles.messageForm}>
        <Typography.Text className={styles.label}>
          {t('home.interestForm.messageToAdvisor')}
        </Typography.Text>
        <Input.TextArea
          className={styles.textArea}
          value={userMessage}
          onChange={(e) => {
            setUserMessage(e.target.value);
          }}
          placeholder={t('home.interestForm.messageToAdvisorPlaceholder')}
        />
      </Row>
      <Row className={styles.selectTimeRow}>
        <div className={styles.title}>{title}</div>
        {suggestions.map((s, index) => {
          return (
            <Row align="middle" className={styles.suggestTimeRow} key={index} gutter={[12, 12]}>
              <Col xs={24} md={5}>
                <Typography.Text className={styles.label}>
                  {t('home.interestForm.suggestionLabel')} {index + 1}
                </Typography.Text>
              </Col>
              <Col xs={24} md={19}>
                <PeriodPicker
                  disabledTooltip={
                    isRescheduled ? (
                      <Trans
                        t={t}
                        i18nKey="message.rescheduleWarningMessage"
                        values={{ months: appSettings.suggestionMonthDiff }}
                        components={{
                          b: <b />,
                          a: (
                            <a className={styles.anchor} href="mailto:happy@wisory.se">
                              {t('share.contactUs')}
                            </a>
                          ),
                        }}
                      />
                    ) : undefined
                  }
                  disabledDate={(date) =>
                    disableBookingDate(date, {
                      isRescheduled: isRescheduled || false,
                      suggestionMonthDiff: appSettings.suggestionMonthDiff,
                    })
                  }
                  onChange={(v) => {
                    setSuggestions((draft) => {
                      draft[index] = v;
                    });
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </Row>
      <div className={styles.buttonWrapper}>
        <Button className={styles.backBtn} onClick={onCancel}>
          <Space>
            <ArrowLeftIcon />
            {t('form.back')}
          </Space>
        </Button>
        <Button
          className={styles.submitBtn}
          type="primary"
          onClick={() => {
            onSuggestClick();
          }}
          loading={suggestSlots.isLoading}
          disabled={!isSelectedAtLeastTwoOptions || hasDuplicatedSlots}
        >
          {t('form.sendSuggestions')}
        </Button>
      </div>
      {!isSelectedAtLeastTwoOptions && (
        <div className={styles.overlappingMsgWrapper}>
          <div className={styles.overlappingMsg}>{t('home.interestForm.slotValidation')}</div>
        </div>
      )}
      {hasDuplicatedSlots && (
        <div className={styles.overlappingMsgWrapper}>
          <div className={styles.overlappingMsg}>{t('home.interestForm.duplicatedTimeSpan')}</div>
        </div>
      )}
    </>
  );
}
