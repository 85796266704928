import dayjs from 'dayjs';
import { Bar } from '@ant-design/plots';
import { useMemo } from 'react';
import { Result, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getCompanyCoinHistories } from '@/common/services';
import { Card, CenteredSpinner } from '@/common/components';
import { toFormattedDate } from '@/common/utils/date';
import { useAuth } from '@/hooks';
import { API_QUERIES } from '@/constants';

export const CompanyMetrics = () => {
  const { t } = useTranslation('common');
  const { user } = useAuth();
  const { data, isLoading, isError } = useQuery({
    queryKey: [API_QUERIES.DECISION_MAKER_COMPANY_HISTORIES],
    queryFn: () => {
      if (!user?.companyId) {
        return Promise.reject(`User doesn't have company`);
      }

      return getCompanyCoinHistories(user.companyId);
    },
  });
  const items = useMemo(() => data?.items || [], [data?.items]);

  const columns: ColumnsType<API.SessionHistoryModel> = useMemo(
    () => [
      {
        title: t('company.companyAdjustmentType'),
        dataIndex: 'adjustmentType',
        key: 'adjustmentType',
        render: (text: string) => text,
      },
      {
        title: t('company.companySessionCredit'),
        key: 'sessionCredit',
        render: (record: API.SessionHistoryModel) =>
          record.companyBookingSessionHistory.bookingSessionCredit,
      },
      {
        title: t('company.companyBookingUser'),
        key: 'bookingUser',
        render: (record: API.SessionHistoryModel) => record.userBookingSessionHistory.userName,
      },
      {
        title: t('company.companyModifiedDate'),
        dataIndex: 'lastModifiedDate',
        key: 'lastModifiedDate',
        render: (text: string) => toFormattedDate(text),
      },
    ],
    [t]
  );
  const chartData = useMemo(() => {
    const result = Object.keys(
      items.reduce((acc, h) => {
        const month = dayjs(h.createdDate).format('YYYY-MM');
        if (!acc[month]) {
          acc[month] = 1;
        } else {
          acc[month] += 1;
        }

        return acc;
      }, {} as Record<string, number>)
    );

    return Object.keys(result).map((key) => {
      return {
        month: key,
        count: result[key as keyof typeof result],
      };
    });
  }, [items]);

  const config = {
    data: chartData,
    xField: 'month',
    yField: 'count',
  };

  if (isLoading) {
    return (
      <Card title={t('company.companyInvoicesTitle')}>
        <div style={{ height: 200 }}>
          <CenteredSpinner />
        </div>
      </Card>
    );
  }

  if (isError) {
    return <Result status="404" title={t('message.decisionMakerWithoutCompany')} />;
  }

  return (
    <Card title={t('company.companyInvoicesTitle')} data-dd-privacy="mask">
      <Bar {...config} color={'#1e7451'} style={{ marginBottom: 40 }} />
      <Table columns={columns} dataSource={items} rowKey="id" />
    </Card>
  );
};
