import { Button, Result } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import dayjs, { Dayjs } from 'dayjs';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api, createCounterProposalUrl } from '@/common/utils';
import { getGreetingTime, mergeDateAndTime } from '@/common/utils/date';
import {
  ErrorSection,
  MainLayout,
  ModalContent,
  ModalDialog,
  ModalHeader,
  TimePicker,
} from '@/common/components';
import { ConfirmedIcon } from '@/common/components/icons';
import { SESSION_DURATION } from '@/constants';
import styles from './styles.module.scss';

export default function AdvisorAcceptBooking() {
  const { t } = useTranslation('common');
  const [selectedTime, setSelectedTime] = useState<Dayjs>();
  const [params] = useSearchParams({ token: '' });
  const navigate = useNavigate();
  const token = params.get('token') || undefined;
  const { data, isFetched } = useQuery({
    queryFn: () => {
      if (!token) {
        return Promise.reject();
      }

      return api.pingpongApi.interestRequests.getInterestRequestByToken(
        {
          token,
        }, // @ts-ignore
        { skipGlobalErrorMessage: true }
      );
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const selected = data?.timeSlots?.find((s) => s.isSelected);

  const constructDateParams = () => {
    if (!selected || !selected.startTime || !selectedTime) {
      return;
    }
    const startTime = mergeDateAndTime(dayjs(selected.startTime), selectedTime);

    return {
      startTime: startTime.toISOString(),
      endTime: startTime.add(SESSION_DURATION, 'minutes').toISOString(),
    };
  };

  const bookAction = useMutation(() =>
    api.pingpongApi.interestRequests.bookCalendarEventByToken(
      { ...constructDateParams() },
      { token }
    )
  );

  const error = useMemo(() => {
    return (
      <MainLayout>
        <ErrorSection
          className={styles.errorWrapper}
          message={t('advisorAcceptBooking.errorTitle')}
        />
      </MainLayout>
    );
  }, [t]);

  if (!token) {
    return error;
  }

  if (bookAction.isSuccess) {
    return (
      <Result
        status="success"
        title={t('advisorAcceptBooking.successTitle')}
        extra={[
          <Button type="primary" key="login" onClick={() => navigate('/')}>
            {t('advisorAcceptBooking.loginBtn')}
          </Button>,
        ]}
      />
    );
  }

  if (!isFetched) {
    return;
  }

  if (!selected || !data) {
    return error;
  }

  const allowTime = getGreetingTime(selected.startTime, selected.endTime);

  return (
    <ModalDialog>
      <ModalHeader>
        <div className={styles.infoRow}>
          <div className={styles.iconWrapper}>
            <ConfirmedIcon size="large" />
          </div>

          <div className={styles.textWrapper}>
            <div className={styles.mainText}>{t('advisorAcceptBooking.title')}</div>
          </div>
        </div>
        <div className={styles.titleRow}>
          <div className={styles.block}>
            <div className={styles.label}>{t('advisorAcceptBooking.userText')}:</div>
            <div>{data.customerFullName}</div>
          </div>
        </div>
      </ModalHeader>
      <ModalContent>
        <div className={styles.content}>
          <div className={styles.formWraper}>
            <div className={styles.timeSelectionWrapper}>
              <div className={styles.itemWrapper}>
                <div className={styles.date}>{dayjs(selected.startTime).format('YYYY-MM-DD')}</div>
                <div>
                  <TimePicker
                    allowTime={allowTime}
                    value={selectedTime}
                    onChange={(value) => {
                      setSelectedTime(value ? value.set('seconds', 0) : undefined);
                    }}
                  />
                </div>
              </div>
              <div>{t('customerAcceptBooking.message')}</div>
            </div>
          </div>
        </div>
        <div className={styles.bottomWrapper}>
          <div className={styles.buttonsWrapper}>
            <Button
              className={styles.btnWrapper}
              onClick={() => {
                navigate(createCounterProposalUrl(selected.interestRequestId!));
              }}
              loading={bookAction.isLoading}
            >
              {t('customerAcceptBooking.alternativeBtnText')}
            </Button>
            <Button
              disabled={!selectedTime}
              className={styles.btnWrapper}
              type="primary"
              onClick={() => {
                bookAction.mutateAsync();
              }}
              loading={bookAction.isLoading}
            >
              {t('customerAcceptBooking.confirmBtnText')}
            </Button>
          </div>
        </div>
      </ModalContent>
    </ModalDialog>
  );
}

// Mobile, Success Screen
