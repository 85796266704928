import { DatePicker as AntdDatePicker, Popover, Select } from 'antd';
import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ALLOW_HOURS,
  ALLOW_MINUTES,
  TIMESPAN,
  TIMESPAN_HOURS,
  LAST_HOUR_IN_DATETIME_PICKER,
  getDateRangeFromGreetingTime,
  isSameOrBefore,
  isWeekend,
} from '@/common/utils/date';
import { DateIcon } from '../icons';
import styles from './styles.module.scss';

type Props = {
  value?: dayjs.Dayjs | null;
  allowTime?: TIMESPAN;
  className?: string;
  disabledTooltip?: React.ReactElement;
  disabledDate?: (date: Dayjs) => boolean;
  onChange?: (v: dayjs.Dayjs | null) => void;
};

function pad(num: number, size: number) {
  let numStr = num.toString();
  while (numStr.length < size) numStr = '0' + num;
  return numStr;
}

const HOUR_OPTIONS = ALLOW_HOURS.map((h) => ({ label: pad(h, 2), value: h }));
const MINUTE_OPTIONS = ALLOW_MINUTES.map((h) => ({ label: pad(h, 2), value: h }));

export function DatePicker({ value, className, onChange }: Props) {
  const now = useMemo(() => dayjs(), []);

  return (
    <AntdDatePicker
      className={classNames(styles.control, className)}
      disabledDate={(date) => {
        return isSameOrBefore(date, now) || isWeekend(date);
      }}
      value={value}
      onChange={onChange}
      suffixIcon={<DateIcon />}
      showToday={false}
    />
  );
}

export function TimePicker({
  value,
  allowTime = TIMESPAN.ALL_DAY,
  className = '',
  onChange,
}: Props) {
  const { t } = useTranslation('common');
  const options = useMemo(() => {
    const ranges = TIMESPAN_HOURS[allowTime];
    const result: { label: string; value: string }[] = [];
    const now = dayjs();

    for (let i = ranges.startHours; i <= ranges.endHours; i++) {
      if (i === LAST_HOUR_IN_DATETIME_PICKER) {
        const val = now.set('hours', i).set('minutes', 0);
        result.push({ value: val.format('HH:mm'), label: val.format('HH:mm') });
      } else {
        ALLOW_MINUTES.forEach((m) => {
          const val = now.set('hours', i).set('minutes', m);
          result.push({ value: val.format('HH:mm'), label: val.format('HH:mm') });
        });
      }
    }

    return result;
  }, [allowTime]);

  return (
    <Select
      className={classNames(styles.wrapper, className)}
      options={options}
      allowClear
      onChange={(value: string | null) => {
        if (value) {
          onChange?.(dayjs(value, 'HH:mm'));
        } else {
          onChange?.(null);
        }
      }}
      placeholder={t('form.selectTimePlaceholder')}
      value={value?.format('HH:mm')}
    />
  );
}

export function DateTimePicker({ value, disabledTooltip, disabledDate, onChange }: Props) {
  const now = useMemo(() => dayjs(), []);
  const [hour, setHour] = useState(7);
  const [minuteOptions, setMinuteOptions] = useState([...MINUTE_OPTIONS]);
  const [minute, setMinute] = useState(0);
  const [date, setDate] = useState<Dayjs | null>(value || null);
  const disabledFn = disabledDate
    ? disabledDate
    : (date: Dayjs) => {
        return isSameOrBefore(date, now) || isWeekend(date);
      };

  return (
    <div className={styles.datepickerWrapper}>
      <AntdDatePicker
        showToday={false}
        className={classNames(styles.control, styles.dateControl)}
        popupClassName={styles.datepickerPopup}
        suffixIcon={<DateIcon />}
        disabledDate={disabledFn}
        value={date}
        cellRender={(current, info) => {
          if (info.type !== 'date') {
            return info.originNode;
          }
          if (typeof current === 'number' || typeof current === 'string') {
            return <div className="ant-picker-cell-inner">{current}</div>;
          }

          const isDisabled = disabledFn(current);

          if (disabledTooltip && isDisabled) {
            return (
              <Popover
                content={disabledTooltip}
                overlayStyle={{
                  width: '200px',
                }}
              >
                <div className="ant-picker-cell-inner disabled-with-tooltip">{current.date()}</div>
              </Popover>
            );
          }

          return <div className="ant-picker-cell-inner">{current.date()}</div>;
        }}
        onChange={(v) => {
          if (v) {
            const constructDate = v.set('hours', hour).set('minutes', minute).set('seconds', 0);
            setDate(constructDate);
            onChange?.(constructDate);
          } else {
            setDate(null);
            onChange?.(null);
          }
        }}
      />
      <Select
        className={styles.control}
        options={HOUR_OPTIONS}
        value={hour}
        disabled={!date}
        onChange={(v) => {
          setHour(v);
          if (v === LAST_HOUR_IN_DATETIME_PICKER) {
            setMinuteOptions([{ label: pad(0, 2), value: 0 }]);
            setMinute(0);
          } else {
            setMinuteOptions([...MINUTE_OPTIONS]);
          }
          onChange?.(date ? date.set('hours', v).set('minutes', minute) : null);
        }}
      />
      <Select
        className={styles.control}
        options={minuteOptions}
        value={minute}
        disabled={!date}
        onChange={(v) => {
          setMinute(v);
          onChange?.(date ? date.set('hours', hour).set('minutes', v) : null);
        }}
      />
    </div>
  );
}

type PeriodPickerProps = {
  disabledTooltip?: React.ReactElement;
  disabledDate?: (date: Dayjs) => boolean;
  onChange?: (value?: { startTime: Dayjs; endTime: Dayjs }) => void;
};

export function PeriodPicker({ disabledTooltip, disabledDate, onChange }: PeriodPickerProps) {
  const { t } = useTranslation('common');
  const now = useMemo(() => dayjs(), []);
  const [period, setPeriod] = useState<TIMESPAN>(TIMESPAN.ALL_DAY);
  const options = useMemo(() => {
    return Object.keys(TIMESPAN_HOURS).map((key) => {
      const timespan = TIMESPAN_HOURS[key as keyof typeof TIMESPAN_HOURS];
      const now = dayjs();
      const startTime = now.set('hours', timespan.startHours).set('minutes', 0);
      const endTime = now.set('hours', timespan.endHours).set('minutes', 0);

      const rangeText = `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;

      return {
        value: TIMESPAN[key as TIMESPAN],
        label: t(`date.${key}`) + ` (${rangeText})`,
      };
    });
  }, [t]);
  const [date, setDate] = useState<Dayjs>();
  const disabledFn = disabledDate
    ? disabledDate
    : (date: Dayjs) => {
        return isSameOrBefore(date, now) || isWeekend(date);
      };

  return (
    <div className={styles.datepickerWrapper}>
      <AntdDatePicker
        showToday={false}
        className={classNames(styles.control, styles.dateControl)}
        popupClassName={styles.datepickerPopup}
        suffixIcon={<DateIcon />}
        cellRender={(current, info) => {
          if (info.type !== 'date') {
            return info.originNode;
          }
          if (typeof current === 'number' || typeof current === 'string') {
            return <div className="ant-picker-cell-inner">{current}</div>;
          }

          const isDisabled = disabledFn(current);

          if (disabledTooltip && isDisabled) {
            return (
              <Popover
                content={disabledTooltip}
                overlayStyle={{
                  width: '200px',
                }}
              >
                <div className="ant-picker-cell-inner disabled-with-tooltip">{current.date()}</div>
              </Popover>
            );
          }

          return <div className="ant-picker-cell-inner">{current.date()}</div>;
        }}
        disabledDate={disabledFn}
        value={date}
        onChange={(v) => {
          if (v) {
            const { startTime, endTime } = getDateRangeFromGreetingTime(v, period);
            setDate(v);
            onChange?.({ startTime, endTime });
          } else {
            setDate(undefined);
            onChange?.(undefined);
          }
        }}
      />
      <Select
        className={classNames(styles.control, styles.periodSelect)}
        options={options}
        value={period}
        disabled={!date}
        onChange={(v) => {
          if (date) {
            const { startTime, endTime } = getDateRangeFromGreetingTime(date, v);
            setPeriod(v);
            onChange?.({ startTime, endTime });
          }
        }}
      />
    </div>
  );
}
