export enum UserRole {
  Admin = 'Admin',
  Backoffice = 'Backoffice',
  User = 'User',
  Advisor = 'Advisor',
  DecisionMaker = 'DecisionMaker',
  ContentSubscriber = 'ContentSubscriber',
}

export enum CustomerProfessionalTitle {
  Finance = 'Finance',
  Sales = 'Sales',
  Marketing = 'Marketing',
  HR = 'HR',
  CEO_GM = 'CEO_GM',
  ITTech = 'IT_Tech',
  Engineering = 'Engineering',
  Logistic = 'Logistic',
  SupplyChain = 'SupplyChain',
}

export const GLOBAL_TITLE_SELECTOR_ID = 'my-wisory-global-title';
export const HEADER_SELECTOR_ID = 'my-wisory-header';
export const JWT_TOKEN = 'WISORY_JWT_TOKEN';
export const APP_ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  MY_SESSIONS: '/my-sessions',
  ANONYMOUS_USER: '/anonymous-user',
  AVAILABILITY: '/availability',
  INTEREST_REQUESTS: '/interest-requests',
  COMPANY: '/company',
  RESET_PASSWORD: '/reset-password',
  INVOICE_HISTORIES: '/invoice-histories',
  ADVISOR_AVAILABILITY: '/advisor-availability',
  AUTHORIZE: '/sign-in',
  LOGIN: '/log-in',
  UNIVERSAL_LOGIN: '/universal-login',
  LOG_OUT: '/logout',
  REGISTER: '/anonymous-user',
  CUSTOMER_ACCEPT_BOOKING: '/customer-accept-booking',
  ADVISOR_ACCEPT_BOOKING: '/advisor-accept-booking',
};
export const PROFESSIONAL_TITLE_OPTIONS = [
  {
    label: 'N/A',
    value: '',
  },
  ...Object.keys(CustomerProfessionalTitle).map((title) => ({
    label: title,
    value: title,
  })),
];

export const APP_SELECTORS = {
  MAIN: '#wisory-profile-app',
  HEADER: '#wisory-profile-header',
  CONTENT: '#wisory-profile-content',
};
export const PHONE_NUMBER_PREFIX = '+';

export const API_QUERIES = {
  ADVISOR_GET_INTEREST_REQUEST: 'ADVISOR_GET_INTEREST_REQUEST',
  ADVISOR_GET_AVAILABILITY_SLOTS: 'ADVISOR_GET_AVAILABILITY_SLOTS',
  ADVISOR_GET_RECURRING_SETTINGS: 'ADVISOR_GET_RECURRING_SETTINGS',
  ADVISOR_GET_UNAVAILABILITY_SETTINGS: 'ADVISOR_GET_UNAVAILABILITY_SETTINGS',
  UPCOMING_SESSIONS: 'UPCOMING_SESSIONS',
  CUSTOMER_GET_INTEREST_REQUEST: 'CUSTOMER_GET_INTEREST_REQUEST',
  SESSION_HISTORIES: 'SESSION_HISTORIES',
  DECISION_MAKER_COMPANY_USERS: 'DECISION_MAKER_COMPANY_USERS',
  DECISION_MAKER_COMPANY_HISTORIES: 'DECISION_MAKER_COMPANY_HISTORIES',
};

export const DATE_TIME_FORMAT = {
  DATE_ONLY: 'YYYY-MM-DD',
  DATE_AND_TIME: 'YYYY-MM-DD HH:mm',
  HOUR_AND_MINUTE: 'HH:mm',
};

export const SESSION_DURATION = 60;

export const DEFAULT_MESSAGES = {
  FIELD_IS_REQUIRED: 'This field is required.',
  FIELD_IS_INVALID_EMAIL: 'This is not a valid email address.',
};

export const SELECTED_ROLE_LOCAL_STORAGE_KEY = 'SELECTED_ROLE_LOCAL_STORAGE_KEY';

export const ERROR_CODE = {
  EMAIL_TOKEN_EXPIRED: 'token-was-expired',
  SLOT_IS_OVERLAPPING: 'appointment-slot-is-overlapping',
  COMPANY_INSUFFICENT_BALANCE: 'company-insufficient-balance',
};

export const LANGUAGES = {
  SWEDISH: 'sv',
  ENGLISH: 'en',
};

export const LANGUAGE_ISO_MAPPING = {
  sv: 'sv-SE',
  en: 'en-US',
};
