import { Row, Col, Button, message, Typography, Divider, Space } from 'antd';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { InterestRequestView } from '@/common/api/pingpongApiClient';
import { formatDate, formatTimeRange, removeSeconds } from '@/common/utils/date';
import { capitalizeFirstLetter } from '@/common/utils';
import styles from '../styles.module.scss';

type BookParams = {
  slotId: string;
};

export type AcceptInterestParams = {
  requestId: string;
  slotId: string;
  startTime: string;
  endTime: string;
};

type Props = {
  request: InterestRequestView;
  accept: (params: AcceptInterestParams) => Promise<any>;
  onStepChange: (step: number) => void;
  onDecline: VoidFunction;
  onSuccess: VoidFunction;
};

enum STEPS {
  SELECT_TIME = 0,
  SUBMIT_TIME = 1,
}

export function CustomerSubmitInterest({
  request,
  onStepChange,
  accept,
  onDecline,
  onSuccess,
}: Props) {
  const { t } = useTranslation('common');
  const today = useMemo(() => dayjs(), []);
  const [step, setStep] = useState<STEPS>(STEPS.SELECT_TIME);
  const [slotId, setSlotId] = useState<string>();

  useEffect(() => {
    onStepChange(step);
  }, [onStepChange, step]);

  const getDateTimeFromSlot = (slotId: string) => {
    const date = request.timeSlots?.find((s) => s.id === slotId)?.startTime;

    if (!date) {
      return dayjs();
    }

    return dayjs(date);
  };
  const confirmWithCustomer = useMutation(
    ({ slotId }: BookParams) => {
      const slot = request.timeSlots?.find((s) => s.id === slotId);

      return accept({
        requestId: request.id!,
        slotId,
        startTime: removeSeconds(dayjs(slot?.startTime)).toISOString(),
        endTime: removeSeconds(dayjs(slot?.endTime)).toISOString(),
      });
    },
    {
      onSuccess,
    }
  );

  const onBook = async (slotId: string) => {
    const date = request.timeSlots?.find((s) => s.id === slotId)?.startTime;

    if (!date) {
      message.error('Please select a time');
    } else {
      confirmWithCustomer.mutate({ slotId });
    }
  };

  const timeSlots = request.timeSlots || [];

  if (step === STEPS.SUBMIT_TIME && slotId) {
    const slotDateAndTime = getDateTimeFromSlot(slotId);

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <div>
            <Trans
              t={t}
              i18nKey="home.interestForm.confirmSlotMsg"
              values={{
                date: `${capitalizeFirstLetter(
                  formatDate(slotDateAndTime, 'dddd DD MMMM')
                )}, ${formatTimeRange(slotDateAndTime, slotDateAndTime.add(60, 'minutes'))}`,
              }}
              components={[<div />, <b />]}
            />
          </div>
        </div>
        <Row justify="end">
          <Space>
            <Button
              className={styles.btnWrapper}
              onClick={() => {
                setStep(STEPS.SELECT_TIME);
              }}
              disabled={confirmWithCustomer.isLoading}
            >
              {t('form.cancel')}
            </Button>
            <Button
              className={styles.btnWrapper}
              type="primary"
              onClick={() => {
                onBook(slotId);
              }}
              loading={confirmWithCustomer.isLoading}
            >
              {t('form.confirm')}
            </Button>
          </Space>
        </Row>
      </Space>
    );
  }

  return (
    <>
      {timeSlots.map((item) => {
        const isPast = dayjs(item.startTime).isBefore(today);

        return (
          <Row align="middle" className={styles.item} key={item.id!}>
            <Col span={12}>
              <Space
                className={classNames(styles.timeWrapper, {
                  [styles.expired]: isPast,
                })}
                direction="vertical"
              >
                <div className={styles.timeText}>
                  {capitalizeFirstLetter(formatDate(item.startTime, 'dddd'))}{' '}
                  {formatDate(item.startTime, 'DD MMMM')}
                </div>
                <div>
                  {isPast
                    ? t('home.interestForm.timeIsExpired')
                    : formatTimeRange(item.startTime, item.endTime)}
                </div>
              </Space>
            </Col>
            {!isPast && (
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  style={{ width: 120 }}
                  disabled={isPast}
                  loading={confirmWithCustomer.isLoading}
                  type="primary"
                  onClick={() => {
                    setSlotId(item.id!);
                    setStep(STEPS.SUBMIT_TIME);
                  }}
                >
                  {t('form.book')}
                </Button>
              </Col>
            )}
          </Row>
        );
      })}

      {request.message && (
        <div className={styles.messageWrapper}>
          <div className={styles.label}>{t('home.interestForm.messageFromAdvisor')}</div>
          <div className={styles.notesContent}>{request.message}</div>
        </div>
      )}

      <Divider />
      <Space size="large" style={{ width: '100%' }} direction="vertical">
        <Typography.Text strong>{t('home.interestForm.suggestAlternativeTitle')}</Typography.Text>
        <Button style={{ width: '100%' }} onClick={onDecline}>
          {t('home.interestForm.suggestAlternativeBtn')}
        </Button>
      </Space>
    </>
  );
}
