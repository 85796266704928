import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { API_QUERIES } from '@/constants';
import { InboxViewModel, InterestRequestType, api, createInboxData, refetch } from '@/common/utils';
import { Title } from '@/common/components';
import { PingPongIcon, RequestIcon, RescheduleIcon } from '@/common/components/icons';
import { AcceptInterestParams } from '@/common/components/interest-requests/components/submit-interest';
import { SuggestedNewInterestParams } from '@/common/components/interest-requests/components/propose-new-time';
import {
  InterestForm,
  STEPS,
} from '@/common/components/interest-requests/components/interest-form';
import styles from './styles.module.scss';

type Props = {
  isAdvisor?: boolean;
};

export const InboxBlock = ({ isAdvisor }: Props) => {
  const { t } = useTranslation('common');
  const [selectedRequest, setSelectedRequest] = useState<InboxViewModel>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stepToOpen, setStepToOpen] = useState(STEPS.SHOW_INTEREST_OPTIONS);
  const interestRequestsQuery = useQuery({
    queryKey: isAdvisor
      ? [API_QUERIES.ADVISOR_GET_INTEREST_REQUEST]
      : [API_QUERIES.CUSTOMER_GET_INTEREST_REQUEST],
    queryFn: () =>
      isAdvisor
        ? api.pingpongApi.advisorProfile.getAdvisorInterestRequests()
        : api.pingpongApi.customerProfile.getCustomerInterestRequests(),
  });
  const acceptInterestRequest = ({
    requestId,
    slotId,
    startTime,
    endTime,
  }: AcceptInterestParams) => {
    return isAdvisor
      ? api.pingpongApi.advisorProfile.advisorBooksASlotOfInterestRequest(
          requestId,
          slotId,
          {
            startTime,
            endTime,
          },
          // @ts-ignore
          { skipGlobalErrorMessage: true }
        )
      : api.pingpongApi.customerProfile.customerBookASlotOfInterestRequest(requestId, slotId, {
          startTime,
          endTime,
        });
  };

  const suggestNewRequest = ({
    requestId,
    suggestedSlots,
    message,
  }: SuggestedNewInterestParams) => {
    return isAdvisor
      ? api.pingpongApi.advisorProfile.advisorSuggestsNewSlotOfInterestRequest(requestId, {
          message,
          timeSlotRequests: suggestedSlots,
        })
      : api.pingpongApi.customerProfile.customerSuggestsNewSlotOfInterestRequest(requestId, {
          message,
          timeSlotRequests: suggestedSlots,
        });
  };

  const refetchAll = () => {
    return Promise.all([
      refetch([API_QUERIES.UPCOMING_SESSIONS]),
      refetch([API_QUERIES.ADVISOR_GET_INTEREST_REQUEST]),
      refetch([API_QUERIES.ADVISOR_GET_AVAILABILITY_SLOTS]),
      refetch([API_QUERIES.CUSTOMER_GET_INTEREST_REQUEST]),
    ]);
  };

  const renderItem = (item: InboxViewModel) => {
    let icon = <RequestIcon />;
    let message = t('home.inboxBlock.sessionRequest');

    if (item.type === InterestRequestType.NEW_TIME_REQUEST) {
      icon = <PingPongIcon />;
      message = t('home.inboxBlock.newTimeProposal');
    } else if (item.type === InterestRequestType.RESCHEDULING_REQUEST) {
      icon = <RescheduleIcon />;
      message = t('home.inboxBlock.reschedulingRequest');
    }

    return (
      <div key={item.id} className={classNames(styles.item)} data-dd-privacy="mask">
        <div className={styles.statusColumn}>{icon}</div>
        <div className={styles.contentColumn}>
          <div className={styles.name}>
            <span>{message}</span> {t('home.from')} {item.displayName}
            {isAdvisor && `, ${item.displayCompanyName}`}
          </div>
          <div className={styles.time}>{dayjs(item.request.lastModifiedDate).fromNow()}</div>
        </div>
        <div className={styles.actionColumn}>
          <Button
            className={classNames({
              [styles.hasBookingId]: !!item.bookingId,
            })}
            onClick={() => {
              setSelectedRequest(item);
            }}
          >
            {t('home.inboxBlock.replyBtn')}
          </Button>
          {item.bookingId && <div className={styles.bookingIdWrapper}>(ID {item.bookingId})</div>}
        </div>
      </div>
    );
  };

  const data = useMemo(
    () =>
      interestRequestsQuery.isLoading
        ? { pingpongRequests: [], rescheduleRequests: [], standardsRequests: [] }
        : createInboxData({
            requests: interestRequestsQuery.data?.items!,
            isAdvisor: isAdvisor || false,
          }),
    [interestRequestsQuery.data?.items, interestRequestsQuery.isLoading, isAdvisor]
  );

  useEffect(() => {
    //https://profile.wisorylab.com/?widget=interest&id=b5fe5497-61ae-4b2c-8212-37aa56b89673&step=propose
    // https://profile.wisorylab.com/advisor-availability?advisorId=42
    if (searchParams.get('widget') === 'interest') {
      const interestId = searchParams.get('id');
      const step = searchParams.get('step');

      if (interestId) {
        const interestItem = [
          ...data.pingpongRequests,
          ...data.rescheduleRequests,
          ...data.standardsRequests,
        ].find((r) => r.id === interestId);

        if (interestItem) {
          if (step === 'propose') {
            setStepToOpen(STEPS.PROPOSE_NEW_TIME);
          }
          setSelectedRequest(interestItem);
          setSearchParams({});
        }
      }
    }
  }, [data, searchParams, setSearchParams]);

  if (interestRequestsQuery.isLoading) {
    return <></>;
  }

  if (
    !data.standardsRequests.length &&
    !data.pingpongRequests.length &&
    !data.rescheduleRequests.length
  ) {
    return <></>;
  }

  return (
    <div className={styles.wrapper} data-dd-privacy="mask">
      <Title className={styles.title} type="default">
        {t('home.inboxTitle')}
      </Title>
      <div className={styles.contentWrapper}>
        {data.standardsRequests.map((s) => {
          return renderItem(s);
        })}
        {data.pingpongRequests.map((s) => {
          return renderItem(s);
        })}
        {data.rescheduleRequests.map((s) => {
          return renderItem(s);
        })}
      </div>

      {selectedRequest && (
        <InterestForm
          item={selectedRequest}
          onCancel={() => setSelectedRequest(undefined)}
          refetch={refetchAll}
          accept={acceptInterestRequest}
          suggest={suggestNewRequest}
          isAdvisor={isAdvisor || false}
          defaultStep={stepToOpen}
        />
      )}
    </div>
  );
};
