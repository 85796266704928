import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  size?: 'medium' | 'large';
};

const SIZES = {
  medium: 720,
  large: 960,
};

export function CenteredContent({ size = 'medium', children }: PropsWithChildren<Props>) {
  return (
    <div className={styles.wrapper} style={{ maxWidth: SIZES[size as keyof typeof SIZES] }}>
      {children}
    </div>
  );
}
