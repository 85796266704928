import { Button } from 'antd';
import classNames from 'classnames';
import { ModalHeader, ModalContent, ModalDialog } from '../modal';
import styles from './styles.module.scss';

type Props = {
  title: string;
  cancelText: string;
  confirmText: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

export const ConfirmationModal = ({
  title,
  children,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
}: React.PropsWithChildren<Props>) => {
  return (
    <ModalDialog width={460}>
      <ModalHeader headerCss={styles.headerWrapper} backgroundColor="white" onCancel={onCancel}>
        <div className={styles.title}>{title}</div>
      </ModalHeader>
      <ModalContent contentCss={styles.contenWrappert}>
        {children && <div className={styles.content}>{children}</div>}

        <div className={classNames(styles.buttonRow)}>
          <Button className={styles.cancelBtn} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button className={styles.confirmBtn} type="primary" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </ModalContent>
    </ModalDialog>
  );
};
