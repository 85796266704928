import { Grid, Layout } from 'antd';
import classNames from 'classnames';
import { APP_SELECTORS } from '@/constants';
import styles from './style.module.scss';

const { Content } = Layout;

type Props = React.PropsWithChildren<{}>;

const { useBreakpoint } = Grid;

export default function MainContent({ children }: Props) {
  const screens = useBreakpoint();

  return (
    <Content
      id={APP_SELECTORS.CONTENT.slice(1)}
      className={classNames(styles.contentWrapper, {
        [styles.xxlScreensize]: screens.xxl,
        [styles.lgScreenSize]: !screens.xxl && (screens.lg || screens.sm),
      })}
    >
      {children}
    </Content>
  );
}
