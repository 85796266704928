import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Title } from '../typography';
import styles from './styles.module.scss';

type Props = {
  title: string;
  className?: string;
};

export const Card = ({ title, className = '', children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.titleWrapper}>
        <Title type="large" noMarginBottom noMarginTop>
          {title}
        </Title>
      </div>
      <div className={styles.separatedLine} />
      <div>{children}</div>
    </div>
  );
};
