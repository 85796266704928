/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum BookedByEnum {
  NotBooked = 'NotBooked',
  BookByAdvisor = 'BookByAdvisor',
  BookByCustomer = 'BookByCustomer',
}

export interface BookingPingPongStatus {
  /** @format uuid */
  requestId?: string | null;
  pingPongState?: PingPongState;
  interestRequestView?: InterestRequestView;
}

export interface InterestRequestEntity {
  reason?: string | null;
  /** @format int64 */
  bookingId?: number;
  /** @format uuid */
  requestId?: string | null;
  timeSlotRequests?: TimeSlotRequestModel[] | null;
  otherTimeSlotRequest?: string | null;
  /** @format int64 */
  advisorId?: number;
  /** @format int64 */
  customerId?: number | null;
  reference?: string | null;
  notes?: string | null;
  locale?: string | null;
  interestRequestStatus?: InterestRequestStatus;
  isBooked?: boolean;
  bookedBy?: BookedByEnum;
  message?: string | null;
  /** @format uuid */
  bookedSlotId?: string | null;
  /** @format date-time */
  bookedStartTime?: string | null;
  /** @format date-time */
  bookedEndTime?: string | null;
  isRescheduled?: boolean;
  /** @format int64 */
  rescheduledUserId?: number | null;
}

export interface InterestRequestHistoryView {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  historyId?: number | null;
  /** @format int64 */
  eventSourceVersion?: number;
  /** @format uuid */
  interestRequestId?: string;
  pingPongBooking?: PingPongBookingAggregate;
}

export enum InterestRequestStatus {
  NONE = 'NONE',
  ADVISOR_COUNTER_PROPOSAL = 'ADVISOR_COUNTER_PROPOSAL',
  ADVISOR_BOOK_A_SLOT = 'ADVISOR_BOOK_A_SLOT',
  CUSTOMER_COUNTER_PROPOSAL = 'CUSTOMER_COUNTER_PROPOSAL',
  CUSTOMER_BOOK_A_SLOT = 'CUSTOMER_BOOK_A_SLOT',
  CUSTOMER_SUGGESTED_TIMESLOTS = 'CUSTOMER_SUGGESTED_TIMESLOTS',
  CANCEL = 'CANCEL',
  ADMIN_SENT_PROPOSAL_TO_USER = 'ADMIN_SENT_PROPOSAL_TO_USER',
  ADMIN_SENT_PROPOSAL_TO_ADVISOR = 'ADMIN_SENT_PROPOSAL_TO_ADVISOR',
}

export interface InterestRequestView {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  advisorId?: number;
  advisorFullName?: string | null;
  /** @format int64 */
  customerId?: number | null;
  customerFullName?: string | null;
  notes?: string | null;
  /** @format date-time */
  createdDate?: string;
  otherTimeSlotRequest?: string | null;
  timeSlots?: TimeSlotRequestModel[] | null;
  advisorSuggestTimeSlots?: TimeSlotRequestModel[] | null;
  customerSuggestTimeSlots?: TimeSlotRequestModel[] | null;
  reference?: string | null;
  reason?: string | null;
  locale?: string | null;
  status?: InterestRequestStatus;
  message?: string | null;
  /** @format int64 */
  bookingId?: number;
  /** @format int32 */
  pingPongCount?: number | null;
  /** @format date-time */
  lastModifiedDate?: string;
  isRescheduled?: boolean;
  companyName?: string | null;
  /** @format int64 */
  companyId?: number | null;
  /** @format int64 */
  rescheduledUserId?: number | null;
  /** @format int32 */
  latestVersion?: number;
  bookingQuality?: WisoryApiClientBookingQualityEnum;
  /** @format date-time */
  bookedStartTime?: string | null;
  /** @format date-time */
  bookedEndTime?: string | null;
  advisorFirstName?: string | null;
  advisorLastName?: string | null;
  customerFirstName?: string | null;
  customerLastName?: string | null;
}

export interface InterestRequestViewPaginationResult {
  items?: InterestRequestView[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface PingPongBookingAggregate {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
  /** @format int32 */
  pingPongCount?: number;
  request?: InterestRequestEntity;
}

export enum PingPongState {
  NOT_FOUND = 'NOT_FOUND',
  ADVISOR_PROPOSAL = 'ADVISOR_PROPOSAL',
  CUSTOMER_PROPOSAL = 'CUSTOMER_PROPOSAL',
}

export interface RegisterInterestModel {
  /** @format uuid */
  id?: string | null;
  /** @format int64 */
  customerId?: number | null;
  /** @format int64 */
  advisorId?: number;
  reference?: string | null;
  notes?: string | null;
  otherTimeSlotRequest?: string | null;
  /** @format date-time */
  createdDate?: string;
  timeSlots?: TimeSlotRequestModel[] | null;
  locale?: string | null;
  /** @format int64 */
  bookingId?: number | null;
}

export interface RegisterRequestCommand {
  locale?: string | null;
  /** @format int64 */
  bookingId?: number;
  /** @format int64 */
  advisorId?: number;
  reference?: string | null;
  notes?: string | null;
  timeSlotRequests?: TimeSlotRequestModel[] | null;
  otherTimeSlotRequest?: string | null;
  /** @format int64 */
  customerId?: number | null;
  message?: string | null;
  reason?: string | null;
  isReschedule?: boolean;
  /** @format date-time */
  sessionStartTime?: string | null;
  /** @format date-time */
  sessionEndTime?: string | null;
}

export interface RequestSuggestionsView {
  timeSlotRequests?: TimeSlotRequestModel[] | null;
  message?: string | null;
}

export interface StartEndDate {
  /** @format date-time */
  startTimeOffset?: string;
  /** @format date-time */
  endTimeOffset?: string;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface TimeSlotRequestModel {
  /** @format date-time */
  startTimeOffset?: string;
  /** @format date-time */
  endTimeOffset?: string;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  interestRequestId?: string | null;
  status?: TimeSlotStatusEnum;
  slotType?: InterestRequestStatus;
  hashData?: string | null;
  isSelected?: boolean;
}

export enum TimeSlotStatusEnum {
  Available = 'Available',
  Booked = 'Booked',
}

export enum WisoryApiClientBookingQualityEnum {
  Untrack = 'Untrack',
  Valid = 'Valid',
  NeedsWork = 'NeedsWork',
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://ping-pong-booking-api.wisorylab.com',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Wisory.PingPongBooking.API
 * @version v1
 * @baseUrl https://ping-pong-booking-api.wisorylab.com
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  advisorProfile = {
    /**
     * No description
     *
     * @tags AdvisorProfile
     * @name GetAdvisorInterestRequests
     * @request GET:/api/v1/users/advisor/interest-requests
     * @secure
     */
    getAdvisorInterestRequests: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestViewPaginationResult, any>({
        path: `/api/v1/users/advisor/interest-requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvisorProfile
     * @name GetAdvisorInterestRequestById
     * @request GET:/api/v1/users/advisor/interest-requests/{requestId}
     * @secure
     */
    getAdvisorInterestRequestById: (requestId: string, params: RequestParams = {}) =>
      this.http.request<InterestRequestView, any>({
        path: `/api/v1/users/advisor/interest-requests/${requestId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvisorProfile
     * @name AdvisorBooksASlotOfInterestRequest
     * @request POST:/api/v1/users/advisor/interest-requests/{requestId}/slots/{slotId}/books
     * @secure
     */
    advisorBooksASlotOfInterestRequest: (
      requestId: string,
      slotId: string,
      data: StartEndDate,
      params: RequestParams = {}
    ) =>
      this.http.request<boolean, any>({
        path: `/api/v1/users/advisor/interest-requests/${requestId}/slots/${slotId}/books`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvisorProfile
     * @name AdvisorSuggestsNewSlotOfInterestRequest
     * @request POST:/api/v1/users/advisor/interest-requests/{requestId}/suggestions
     * @secure
     */
    advisorSuggestsNewSlotOfInterestRequest: (
      requestId: string,
      data: RequestSuggestionsView,
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestView, any>({
        path: `/api/v1/users/advisor/interest-requests/${requestId}/suggestions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  bookings = {
    /**
     * No description
     *
     * @tags Bookings
     * @name GetRequestByBooking
     * @request GET:/api/v1/bookings/{bookingId}/interest-request
     * @secure
     */
    getRequestByBooking: (bookingId: number, params: RequestParams = {}) =>
      this.http.request<BookingPingPongStatus, any>({
        path: `/api/v1/bookings/${bookingId}/interest-request`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name GetStatusRequestByBooking
     * @request GET:/api/v1/bookings/{bookingId}/ping-pong-status
     * @secure
     */
    getStatusRequestByBooking: (bookingId: number, params: RequestParams = {}) =>
      this.http.request<BookingPingPongStatus, any>({
        path: `/api/v1/bookings/${bookingId}/ping-pong-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name RescheduleCalendarEvent
     * @request POST:/api/v1/bookings/{bookingId}/reschedule
     * @secure
     */
    rescheduleCalendarEvent: (
      bookingId: number,
      data: RequestSuggestionsView,
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/v1/bookings/${bookingId}/reschedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name BookCalendarEvent
     * @request POST:/api/v1/bookings/{bookingId}/books
     * @secure
     */
    bookCalendarEvent: (bookingId: number, data: StartEndDate, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/bookings/${bookingId}/books`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bookings
     * @name CancelCalendarEvent
     * @request POST:/api/v1/bookings/{bookingId}/cancel
     * @secure
     */
    cancelCalendarEvent: (bookingId: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/bookings/${bookingId}/cancel`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  customerProfile = {
    /**
     * No description
     *
     * @tags CustomerProfile
     * @name GetCustomerInterestRequests
     * @request GET:/api/v1/users/customer/interest-requests
     * @secure
     */
    getCustomerInterestRequests: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestViewPaginationResult, any>({
        path: `/api/v1/users/customer/interest-requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerProfile
     * @name GetCustomerInterestRequestById
     * @request GET:/api/v1/users/customer/interest-requests/{requestId}
     * @secure
     */
    getCustomerInterestRequestById: (requestId: string, params: RequestParams = {}) =>
      this.http.request<InterestRequestView, any>({
        path: `/api/v1/users/customer/interest-requests/${requestId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerProfile
     * @name CustomerBookASlotOfInterestRequest
     * @request POST:/api/v1/users/customer/interest-requests/{requestId}/slots/{slotId}/books
     * @secure
     */
    customerBookASlotOfInterestRequest: (
      requestId: string,
      slotId: string,
      data: StartEndDate,
      params: RequestParams = {}
    ) =>
      this.http.request<boolean, any>({
        path: `/api/v1/users/customer/interest-requests/${requestId}/slots/${slotId}/books`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerProfile
     * @name CustomerSuggestsNewSlotOfInterestRequest
     * @request POST:/api/v1/users/customer/interest-requests/{requestId}/suggestions
     * @secure
     */
    customerSuggestsNewSlotOfInterestRequest: (
      requestId: string,
      data: RequestSuggestionsView,
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestView, any>({
        path: `/api/v1/users/customer/interest-requests/${requestId}/suggestions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  home = {
    /**
     * No description
     *
     * @tags Home
     * @name HomePage
     * @request GET:/
     * @secure
     */
    homePage: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Home
     * @name AuthorizedPing
     * @request GET:/authorized-ping
     * @secure
     */
    authorizedPing: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/authorized-ping`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  interestRequests = {
    /**
     * No description
     *
     * @tags InterestRequests
     * @name CreateRequest
     * @request POST:/api/v1/interest-requests
     * @secure
     */
    createRequest: (data: RegisterRequestCommand, params: RequestParams = {}) =>
      this.http.request<RegisterInterestModel, any>({
        path: `/api/v1/interest-requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags InterestRequests
     * @name GetRequests
     * @request GET:/api/v1/interest-requests
     * @secure
     */
    getRequests: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @format int32 */
        noResponseAfter?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestViewPaginationResult, any>({
        path: `/api/v1/interest-requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags InterestRequests
     * @name GetWorkflows
     * @request GET:/api/v1/interest-requests/workflow-status/{status}
     * @secure
     */
    getWorkflows: (status: InterestRequestStatus, params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/v1/interest-requests/workflow-status/${status}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags InterestRequests
     * @name BookCalendarEventByToken
     * @request POST:/api/v1/interest-requests/books
     * @secure
     */
    bookCalendarEventByToken: (
      data: StartEndDate,
      query?: {
        token?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/v1/interest-requests/books`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InterestRequests
     * @name GetInterestRequestByToken
     * @request GET:/api/v1/interest-requests/by-token
     * @secure
     */
    getInterestRequestByToken: (
      query?: {
        token?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestView, any>({
        path: `/api/v1/interest-requests/by-token`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  interestRequestsHistory = {
    /**
     * No description
     *
     * @tags InterestRequestsHistory
     * @name GetInterestRequestHistoryByVersion
     * @request GET:/api/v1/interest-requests-history/booking/{bookingId}/history/{bookingHistoryId}
     * @secure
     */
    getInterestRequestHistoryByVersion: (
      bookingId: number,
      bookingHistoryId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<InterestRequestHistoryView, any>({
        path: `/api/v1/interest-requests-history/booking/${bookingId}/history/${bookingHistoryId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags InterestRequestsHistory
     * @name GetRequestByVersion
     * @request GET:/api/v1/interest-requests-history/{requestId}/versions/{version}
     * @secure
     */
    getRequestByVersion: (requestId: string, version: number, params: RequestParams = {}) =>
      this.http.request<InterestRequestViewPaginationResult, any>({
        path: `/api/v1/interest-requests-history/${requestId}/versions/${version}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  sensitiveData = {
    /**
     * No description
     *
     * @tags SensitiveData
     * @name GetSensitiveData
     * @request GET:/api/v1/interest-requests/review
     * @secure
     */
    getSensitiveData: (
      query: {
        otp?: string;
        salt?: string;
        token: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<string, any>({
        path: `/api/v1/interest-requests/review`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitiveData
     * @name GetSensitiveDataOtp
     * @request GET:/api/v1/interest-requests/otp
     * @secure
     */
    getSensitiveDataOtp: (
      query: {
        email?: string;
        token: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<string, any>({
        path: `/api/v1/interest-requests/otp`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
