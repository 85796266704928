/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AnonymousUserResponse {
  /** @format uuid */
  id?: string;
  email?: string | null;
  phoneNumber?: string | null;
  state?: AnonymousUserState;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  companyName?: string | null;
  /** @format int64 */
  registerUserId?: number | null;
  /** @format date-time */
  createdDate?: string;
  /** @format int64 */
  createdUserId?: number;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  lastModifiedUserId?: number;
  /** @format date-time */
  rowInsertedDate?: string;
}

export interface AnonymousUserResponsePaginationResult {
  items?: AnonymousUserResponse[] | null;
  /** @format int64 */
  totalCount?: number;
}

export enum AnonymousUserState {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Verified = 'Verified',
  PasswordReset = 'PasswordReset',
}

export interface CreateAnonymousUserView {
  /** @pattern ^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$ */
  email: string;
  /** @pattern ^\+[1-9][\s0-9]{0,25}$ */
  phoneNumber: string;
  firstName?: string | null;
  lastName?: string | null;
  /** @pattern ^[a-zA-Z0-9._]{6,}$ */
  userName: string;
  companyName?: string | null;
}

export interface CreateSkillCommand {
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
}

export interface SkillModel {
  /** @format uuid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
}

export interface SkillModelPaginationResult {
  items?: SkillModel[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface SyncAdvisorProfileCmsPostsCommand {
  wipeOutAllExistingPosts?: boolean;
  resetSettingData?: boolean;
}

export interface UpdateAdvisorProfileCmsCommand {
  /** @format int64 */
  postId?: number;
  /** @format int64 */
  advisorId?: number;
  cmsPostData?: any;
}

export interface UpdateSkillCommand {
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
}

export interface UpdateSkillsToUserCommand {
  skillIds?: string[] | null;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Wisory.Profile.API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  anonymousUser = {
    /**
     * No description
     *
     * @tags AnonymousUser
     * @name CreateAnonymousUsers
     * @request POST:/api/v1/anonymous-users
     * @secure
     */
    createAnonymousUsers: (data: CreateAnonymousUserView, params: RequestParams = {}) =>
      this.http.request<AnonymousUserResponse, any>({
        path: `/api/v1/anonymous-users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnonymousUser
     * @name GetAnonymousUsers
     * @request GET:/api/v1/anonymous-users
     * @secure
     */
    getAnonymousUsers: (
      query?: {
        Email?: string;
        PhoneNumber?: string;
        Name?: string;
        UserName?: string;
        /** @format int32 */
        PageIndex?: number;
        /** @format int32 */
        PageSize?: number;
        DynamicFilters?: string;
        DynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<AnonymousUserResponsePaginationResult, any>({
        path: `/api/v1/anonymous-users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cmsAdvisor = {
    /**
     * No description
     *
     * @tags CmsAdvisor
     * @name GetAdvisorCmsDetails
     * @request GET:/api/v1/cms/advisors/me
     * @secure
     */
    getAdvisorCmsDetails: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/cms/advisors/me`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CmsAdvisor
     * @name GetAdvisorDetailsPerPostLanguage
     * @request GET:/api/v1/cms/advisors/me/{postId}
     * @secure
     */
    getAdvisorDetailsPerPostLanguage: (postId: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/cms/advisors/me/${postId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CmsAdvisor
     * @name UpdateCmsProfileDetail
     * @request PUT:/api/v1/cms/advisors/me/{postId}
     * @secure
     */
    updateCmsProfileDetail: (
      postId: number,
      data: UpdateAdvisorProfileCmsCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/v1/cms/advisors/me/${postId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CmsAdvisor
     * @name FirstSyncRequestToCrawlAllAdvisorPagesInCmsToProfileDatabase
     * @request POST:/api/v1/cms/advisors/sync
     * @secure
     */
    firstSyncRequestToCrawlAllAdvisorPagesInCmsToProfileDatabase: (
      data: SyncAdvisorProfileCmsPostsCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/v1/cms/advisors/sync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  cmsSchemas = {
    /**
     * No description
     *
     * @tags CmsSchemas
     * @name GetAdvisorSchemaDetails
     * @request GET:/api/v1/cmsschemas/advisor
     * @secure
     */
    getAdvisorSchemaDetails: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/cmsschemas/advisor`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  home = {
    /**
     * No description
     *
     * @tags Home
     * @name HomePage
     * @request GET:/
     * @secure
     */
    homePage: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  skills = {
    /**
     * No description
     *
     * @tags Skills
     * @name GetSkills
     * @request GET:/api/v1/skills
     * @secure
     */
    getSkills: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        currentPage?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
        /** @default true */
        onlyActive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<SkillModelPaginationResult, any>({
        path: `/api/v1/skills`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Skills
     * @name CreateSkills
     * @request POST:/api/v1/skills
     * @secure
     */
    createSkills: (data: CreateSkillCommand, params: RequestParams = {}) =>
      this.http.request<SkillModel, any>({
        path: `/api/v1/skills`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Skills
     * @name GetSkillById
     * @request GET:/api/v1/skills/{skillId}
     * @secure
     */
    getSkillById: (skillId: string, params: RequestParams = {}) =>
      this.http.request<SkillModel, any>({
        path: `/api/v1/skills/${skillId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Skills
     * @name UpdateSkillById
     * @request PUT:/api/v1/skills/{skillId}
     * @secure
     */
    updateSkillById: (skillId: string, data: UpdateSkillCommand, params: RequestParams = {}) =>
      this.http.request<SkillModel, any>({
        path: `/api/v1/skills/${skillId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Skills
     * @name DeleteSkillById
     * @request DELETE:/api/v1/skills/{skillId}
     * @secure
     */
    deleteSkillById: (skillId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/v1/skills/${skillId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  userSkills = {
    /**
     * No description
     *
     * @tags UserSkills
     * @name GetSkillsByUserId
     * @request GET:/api/v1/users/me/skills
     * @secure
     */
    getSkillsByUserId: (params: RequestParams = {}) =>
      this.http.request<SkillModelPaginationResult, any>({
        path: `/api/v1/users/me/skills`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSkills
     * @name UpdateSkillsToUser
     * @request PUT:/api/v1/users/me/skills
     * @secure
     */
    updateSkillsToUser: (data: UpdateSkillsToUserCommand, params: RequestParams = {}) =>
      this.http.request<SkillModel[], any>({
        path: `/api/v1/users/me/skills`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
