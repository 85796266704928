import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import styles from './styles.module.scss';

const WisoryErrorIcon = () => {
  return (
    <svg
      width="130"
      height="120"
      viewBox="0 0 130 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m21 23.5-10.5 76 97.5 14 9-77.5-96-12.5z" fill="#fff" />
      <path
        d="m46.793 48.41 4.175 5.406-3.39 2.617-4.174-5.405-5.405 4.174-2.618-3.389 5.406-4.174-4.175-5.406 3.39-2.617 4.174 5.405 5.405-4.174 2.617 3.389-5.405 4.174zm52.012 1.814-2.617-3.39-5.405 4.175-4.175-5.405-3.389 2.617 4.175 5.405-5.406 4.175 2.618 3.389 5.405-4.175 4.175 5.406 3.389-2.618-4.175-5.405 5.405-4.175zm-5.22 28.756-1.422 11.069c-.577 4.49-4.699 7.673-9.188 7.096l-1.73-.222c-4.49-.577-7.672-4.699-7.096-9.188l.877-6.823-41.18-5.29.546-4.247 59.19 7.604h.003zm-4.793 3.7-9.519-1.222-.876 6.822a3.931 3.931 0 0 0 3.394 4.396l1.73.222a3.932 3.932 0 0 0 4.395-3.395l.876-6.822zm36.339-58.89-11.077 86.219c-.668 5.203-5.471 8.912-10.673 8.244l-88.494-11.369c-5.203-.669-8.912-5.472-8.244-10.674L17.72 9.99c.668-5.202 5.471-8.912 10.674-8.243l88.494 11.369c5.202.668 8.911 5.47 8.243 10.673zm-29.809-.915a4.283 4.283 0 0 0 8.494 1.091 4.283 4.283 0 0 0-8.494-1.091zm-13.845-1.779a4.283 4.283 0 1 0 8.496 1.09 4.283 4.283 0 0 0-8.496-1.09zm33.762 17.289L23.602 26.612 14.53 97.22a1.604 1.604 0 0 0 1.37 1.773l88.493 11.369a1.603 1.603 0 0 0 1.773-1.369l9.071-70.605.001-.003zm1.731-12.73a4.282 4.282 0 1 0-8.494-1.09 4.282 4.282 0 0 0 8.494 1.09z"
        fill="#ECB6AC"
      />
    </svg>
  );
};

type Props = {
  message?: ReactElement | string;
  extra?: string;
  className?: string;
};

export const ErrorSection = ({ message, extra, className = '' }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.content}>
        <WisoryErrorIcon />
        <div className={styles.title}>{t('errorSection.title')}</div>
        <div>
          <div className={styles.subtitle}>{message || t('errorSection.message')}</div>
          {extra && <div className={styles.paragraph}>{extra}</div>}
          <div className={styles.paragraph}>
            <Trans
              t={t}
              i18nKey="errorSection.supportMessage"
              components={{
                email: (
                  <a className={styles.anchor} href="mailto:happy@wisory.se">
                    happy@wisory.se
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
