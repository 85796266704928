import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken, refreshAccessToken, setToken } from '@common/services';
import { message } from 'antd';
import { UserSessionResult } from '@wisory-international-ab/login-frontend';
import i18n from '@/i18n';
import { showNotification } from './modal';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipGlobalErrorMessage?: boolean;
  skipInterceptor?: boolean;
  apiGroup?: 'main' | 'profile';
}

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: CustomAxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
  }
}

function pathJoin(parts: string[], sep?: string) {
  var separator = sep || '/';
  var replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

const applyInterceptors = (request: AxiosInstance) => {
  request.interceptors.request.use(
    (config: any) => {
      const token = getToken();
      const { headers } = config as CustomAxiosRequestConfig;

      if (!token) {
        return config;
      }

      return {
        ...config,
        headers: {
          ...headers,
          Authorization: `Bearer ${token.accessToken}`,
        },
        path: pathJoin([config.baseURL, config.path]),
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  request.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const isTokenExpired = error.response?.status === 401 && !originalRequest._retry;

      const reloadWhenTokenIsExpired = () => {
        showNotification({
          type: 'error',
          message: i18n.getFixedT(null, 'common')('message.sessionTimeOut'),
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      };

      if (isTokenExpired) {
        originalRequest._retry = true;

        const userSession = getToken();

        if (userSession) {
          try {
            const newUserSession = await refreshAccessToken(userSession.refreshToken);
            setToken(newUserSession as UserSessionResult);

            return request(originalRequest);
          } catch (e) {
            return Promise.reject(e);
          }
        } else if (!error.config.skipInterceptor) {
          reloadWhenTokenIsExpired();
        }

        return Promise.reject(error);
      }

      if (error.response?.status === 401) {
        reloadWhenTokenIsExpired();
      } else {
        const data = error.response?.data;

        if (!error.config.skipGlobalErrorMessage) {
          message.error(
            data && data.errorMessage
              ? data.errorMessage
              : 'There is an error, please contact our administrator for help.'
          );
        }
      }

      return Promise.reject(error.response?.data);
    }
  );

  return request;
};

export { applyInterceptors };
