import { Navigate } from 'react-router-dom';
import { FunctionComponent, Suspense } from 'react';
import { useAuth } from '@/hooks';
import { APP_ROUTES, UserRole } from '@/constants';
import { CenteredSpinner } from '../centered-spinner';
import AccessDenied from './access-denied';

type Props = {
  roles: UserRole[];
  componentToRender: FunctionComponent<any>;
};

export function PermissionGuard(props: Props) {
  const { user, selectedRole } = useAuth();
  const { componentToRender: Component, roles } = props;

  if (!user) {
    return <Navigate to={APP_ROUTES.AUTHORIZE} />;
  }

  // @ts-ignore
  const matchedRole = roles.includes(selectedRole);

  if (!matchedRole) {
    return <AccessDenied />;
  }
  return (
    <Suspense fallback={<CenteredSpinner height={200} />}>
      <Component />
    </Suspense>
  );
}
