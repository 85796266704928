import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  color?: 'pink' | 'green';
  size?: 'small' | 'medium' | 'large';
  noAnimation?: boolean;
};

export function Spinner({ size = 'small', color = 'green', noAnimation }: Props) {
  return (
    <div
      className={classNames(styles.loader, styles[size], styles[color], {
        [styles.noAnimation]: noAnimation,
      })}
    />
  );
}
