import { Link } from 'react-router-dom';
import { APP_ROUTES } from '@/constants';
import LogoImg from '../../../../../../../images/WisoryLogo.png';
import styles from './styles.module.scss';

export const Logo = () => {
  return (
    <Link className={styles.logoWrapper} to={APP_ROUTES.HOME}>
      <span className={styles.logoText}>My</span>
      <img src={LogoImg} alt="" />
    </Link>
  );
};
