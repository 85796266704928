import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { ConfirmedIcon, CancelledIcon } from '@/common/components/icons';
import { Spinner } from '@/common/components';
import { BookingCalendarEventStatus } from '@/common/api/wisoryApiClient';
import { formatDate } from '@/common/utils';
import styles from './styles.module.scss';

export enum UpcomingSessionStatuses {
  PENDING,
  VERIFIED,
  PERFORMED,
  CANCELLED,
}

type UpcomingSessionViewModel<T> = {
  displayName: string;
  displayCompanyName: string;
  startTime?: Dayjs;
  originalItem: T;
};

type Props<T> = {
  item: UpcomingSessionViewModel<T>;
  className?: string;
  isAdvisor: boolean;
  status: BookingCalendarEventStatus;
  onChargeableClick?: VoidFunction;
  onSelect: (session: T) => void;
};

export function UpcomingSessionItem<T>({
  item,
  status,
  className = '',
  isAdvisor,
  onChargeableClick,
  onSelect,
}: Props<T>) {
  const { t } = useTranslation('common');

  const isVerified = status === BookingCalendarEventStatus.VERIFIED;
  const isCancelled = status === BookingCalendarEventStatus.CANCELLED;
  const isNoShow = status === BookingCalendarEventStatus.NO_SHOW;
  const isLateCancelled = status === BookingCalendarEventStatus.LATE_CANCELLED;
  const isPerformed =
    status === BookingCalendarEventStatus.PERFORMED ||
    status === BookingCalendarEventStatus.INVOICED;
  const isPending = status === BookingCalendarEventStatus.PENDING;
  // @ts-ignore
  const bookingId = isPending ? item.originalItem.bookingId : item.originalItem?.id;
  // @ts-ignore
  const isRefunded = item.originalItem?.isRefunded || false;

  return (
    <div
      className={classNames(styles.item, className, {
        [styles.confirmed]: isVerified || isPerformed,
        [styles.lateCancelled]: isLateCancelled || isNoShow,
        [styles.cancelled]: isCancelled,
        [styles.pending]: isPending,
      })}
      data-dd-privacy="mask"
    >
      <div className={styles.statusColumn}>
        {!isPending && (
          <div className={styles.info}>{formatDate(item.startTime, 'DD MMM, HH.mm')}</div>
        )}
        <div className={styles.icon}>
          {isVerified && (
            <>
              <div className={styles.iconWrapper}>
                <ConfirmedIcon />
              </div>
              <span>{t('home.confirmed')}</span>
            </>
          )}
          {isPerformed && (
            <>
              <div className={styles.iconWrapper}>
                <ConfirmedIcon />
              </div>
              <span>{t('home.performed')}</span>
            </>
          )}
          {(isCancelled || isLateCancelled) && (
            <>
              <div className={styles.iconWrapper}>
                <CancelledIcon />
              </div>
              <span>{t(isCancelled ? 'home.cancelled' : 'home.lateCancelled')}</span>
            </>
          )}

          {isPending && (
            <>
              <div className={classNames(styles.iconWrapper, styles.pendingIcon)}>
                <Spinner noAnimation color="pink" />
              </div>
              <span className={styles.pendingText}>{t('home.pending')}</span>
            </>
          )}

          {isNoShow && onChargeableClick && (
            <>
              <span>{t('home.noShow')}</span>
            </>
          )}
        </div>
      </div>
      {!isPending && (
        <div className={styles.contentColumn}>
          <div className={styles.name}>{item.displayName}</div>
          {item.displayCompanyName && (
            <div className={styles.company}>{item.displayCompanyName}</div>
          )}
          {!item.displayCompanyName && <div className={styles.company}>&nbsp;</div>}
        </div>
      )}

      {isPending && (
        <div className={styles.contentColumn}>
          <div className={styles.name}>{item.displayName}</div>
          {item.displayCompanyName && (
            <div className={styles.company}>{item.displayCompanyName}</div>
          )}
          {!item.displayCompanyName && <div className={styles.company}>&nbsp;</div>}
        </div>
      )}

      <div className={styles.actionColumn}>
        <Button
          className={classNames({
            // @ts-ignore
            [styles.hasBookingId]: !!bookingId && !isPending,
          })}
          onClick={() => {
            onSelect(item.originalItem);
          }}
        >
          {t('home.viewDetails')}
        </Button>

        {bookingId && (
          <div className={styles.bookingIdWrapper}>
            (ID {bookingId})
            {!isRefunded && !isPerformed && !isVerified && isAdvisor && onChargeableClick && (
              <span
                role="button"
                className={styles.chargeable}
                onClick={() => {
                  onChargeableClick();
                }}
              >
                {t('home.chargeable')}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
