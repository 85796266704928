import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  bold?: boolean;
  type?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
};

export function Title({
  noMarginTop,
  noMarginBottom,
  className = '',
  type = 'default',
  bold,
  children,
}: PropsWithChildren<Props>) {
  const types = {
    'x-small': styles.xSmall,
    small: styles.small,
    default: styles.default,
    large: styles.large,
    xlarge: styles.xLarge,
  };

  return (
    <h2
      className={classNames(
        styles.title,
        {
          [styles.titleMarginTop]: !noMarginTop,
          [styles.titleMarginBottom]: !noMarginBottom,
          [styles.bold]: bold,
        },
        types[type as keyof typeof types],
        className
      )}
    >
      {children}
    </h2>
  );
}

export function Subtitle({
  noMarginTop,
  noMarginBottom,
  className = '',
  children,
  type = 'default',
}: PropsWithChildren<Props>) {
  const types = {
    small: styles.small,
    default: styles.default,
    large: styles.large,
  };

  return (
    <div
      className={classNames(
        styles.subtitle,
        {
          [styles.subtitleMarginTop]: !noMarginTop,
          [styles.subtitleMarginBottom]: !noMarginBottom,
        },
        types[type as keyof typeof types],
        className
      )}
    >
      {children}
    </div>
  );
}

export function Label({ children }: PropsWithChildren<{}>) {
  return <p className={styles.label}>{children}</p>;
}
