import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from '@/constants';

export function useAppRedirectUrl() {
  const location = useLocation();
  const { from } = (location.state as LocationState) || {
    from: { pathname: APP_ROUTES.HOME, search: '' },
  };

  return `${from.pathname}${from.search}`;
}
