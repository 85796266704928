import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { APP_ROUTES } from '@/constants';
import { useAuth } from '@/hooks';
import { confirmModal } from '@/common/utils';
import styles from './styles.module.scss';

export function LogoutButton({ className = '' }: { className?: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const location = useLocation();
  const { user, signOut } = useAuth();

  if (!user) {
    return;
  }

  return (
    <Button
      type="link"
      className={classNames(styles.logout, className)}
      onClick={() => {
        confirmModal({
          title: t('header.logout.title'),
          content: <div />,
          onConfirm: () => {
            Modal.destroyAll();
            signOut();
            navigate(APP_ROUTES.LOG_OUT, { replace: true, state: { from: location } });
          },
        });
      }}
    >
      {t('profile.logout')}
    </Button>
  );
}
