import { Api as MainApi, HttpClient as MainHttpClient } from '../api/wisoryApiClient';
import { Api as ProfileApi, HttpClient as ProfileHttpClient } from '../api/profileApiClient';
import { Api as PingPongApi, HttpClient as PingPongHttpClient } from '../api/pingpongApiClient';
import { applyInterceptors } from './request2';
import { getAppConfigurations } from './environment-variables';

const { mainApiEndpoint, profileApiEndpoint, pingpongApiEndpoint } = getAppConfigurations();

const baseAPIClient = new MainHttpClient({
  baseURL: mainApiEndpoint,
});

applyInterceptors(baseAPIClient.instance);

const profileApiClient = new ProfileHttpClient({
  baseURL: profileApiEndpoint,
});

applyInterceptors(profileApiClient.instance);

const pingpongApiClient = new PingPongHttpClient({
  baseURL: pingpongApiEndpoint,
});

applyInterceptors(pingpongApiClient.instance);

const baseAPI = new MainApi(baseAPIClient);
const profileApi = new ProfileApi(profileApiClient);
const pingpongApi = new PingPongApi(pingpongApiClient);

export * from './environment-variables';
export * from './session';
export * from './array';
export * from './query';
export * from './date';
export * from './string';
export * from './localization';
export * from './url';
export * from './modal';
export const api = {
  profileApi,
  baseAPI,
  pingpongApi,
};

export function waitForElm(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
