import { Drawer, Row, Grid } from 'antd';
import { useState } from 'react';
import classNames from 'classnames';
import { CloseIcon, MenuIcon } from '@/common/components/icons';
import { GLOBAL_TITLE_SELECTOR_ID } from '@/constants';
import { Logo } from '../logo-item';
import { NavigationItems } from '../navigation-items';
import { LanguageSwitcher } from '../language-switcher';
import { LogoutButton } from '../logout-button';
import { RoleSwitcherButton } from '../role-switcher-button';
import styles from './styles.module.scss';

const { useBreakpoint } = Grid;

export const MobileUI = () => {
  const screens = useBreakpoint();
  const isMobileScreen = screens.xs;
  const isTabletScreen = screens.sm && !screens.lg && !screens.xl && !screens.xxl;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Row
        align={'middle'}
        justify={'space-between'}
        className={classNames(styles.wrapper, {
          [styles.mobileWrapper]: isMobileScreen,
          [styles.tabletWrapper]: isTabletScreen,
        })}
      >
        <Logo />
        <div role="button" onClick={showDrawer} aria-label="Menu">
          <MenuIcon />
        </div>
      </Row>
      <div
        className={classNames(styles.title, {
          [styles.mobileWrapper]: isMobileScreen,
          [styles.tabletWrapper]: isTabletScreen,
        })}
        id={GLOBAL_TITLE_SELECTOR_ID}
      />
      <Drawer rootClassName={styles.drawer} closable={false} onClose={onClose} open={open}>
        <Row align={'middle'} justify={'space-between'}>
          <Logo />
          <div className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </div>
        </Row>
        <div className={styles.content}>
          <RoleSwitcherButton arrowColor="white" className={styles.roleSwitcherButton} />
          <NavigationItems className={styles.navigations} onNavigateItemClick={onClose} />
          <LanguageSwitcher className={styles.languageSwitcher} />
          <LogoutButton className={styles.logout} />
        </div>
      </Drawer>
    </>
  );
};
