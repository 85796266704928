import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { InboxViewModel, InterestRequestType } from '@/common/utils';
import { BookingModal } from '@/common/components';
import { AdvisorSubmitInterest } from './components/advisor';
import { CustomerSubmitInterest } from './components/customer';

export type AcceptInterestParams = {
  requestId: string;
  slotId: string;
  startTime: string;
  endTime: string;
};

type Props = {
  item: InboxViewModel;
  isAdvisor: boolean;
  accept: (params: AcceptInterestParams) => Promise<any>;
  onDecline: VoidFunction;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
};

export function SubmitInterest({ item, isAdvisor, accept, onCancel, onDecline, onSuccess }: Props) {
  const { t } = useTranslation('common');
  const [currentStep, setCurrentStep] = useState(0);
  const description = useMemo(() => {
    if (currentStep === 1) {
      return undefined;
    }

    if (item.type === InterestRequestType.NEW_INTEREST_REQUEST) {
      return t('home.interestForm.advisor.newInterestRequestDescription');
    }

    const type = isAdvisor ? 'advisor' : 'customer';

    if (item.type === InterestRequestType.NEW_TIME_REQUEST) {
      return t(`home.interestForm.${type}.newTimeRequestDescription`);
    }

    return t(`home.interestForm.${type}.reschedulingRequestDescription`);
  }, [currentStep, item.type, isAdvisor, t]);
  const title = useMemo(() => {
    if (currentStep === 1) {
      return t('home.interestForm.confirmBooking');
    }

    if (item.type === InterestRequestType.NEW_INTEREST_REQUEST) {
      return t('home.inboxBlock.sessionRequestHeading');
    } else if (item.type === InterestRequestType.NEW_TIME_REQUEST) {
      return t('home.inboxBlock.newTimeProposalHeading');
    }

    return t('home.inboxBlock.reschedulingRequestHeading');
  }, [currentStep, item.type, t]);

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };
  const content = isAdvisor ? (
    <AdvisorSubmitInterest
      request={item.request}
      accept={accept}
      onDecline={onDecline}
      onSuccess={onSuccess}
      onStepChange={onStepChange}
    />
  ) : (
    <CustomerSubmitInterest
      request={item.request}
      accept={accept}
      onDecline={onDecline}
      onSuccess={onSuccess}
      onStepChange={onStepChange}
    />
  );

  return (
    <BookingModal
      title={title}
      subtitle={t('home.inboxBlock.bookingId', { bookingId: item.bookingId })}
      description={description}
      data={item}
      onCancel={onCancel}
    >
      {content}
    </BookingModal>
  );
}
