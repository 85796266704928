import { useMemo } from 'react';
import { getCurrentLanguage } from '@/common/utils';
import { LANGUAGES } from '@/constants';
import styles from './styles.module.scss';

// const ContactViaCalendar = () => {
//   return (
//     <a
//       className={styles.anchor}
//       href="https://calendly.com/wisory-emma/wisory-user-touchdown"
//       target="_blank"
//       rel="noreferrer"
//     >
//       WISORY | touchdown (SE) - Emma Berg Edgren
//     </a>
//   );
// };

const ContactViaEmail = () => {
  return (
    <a className={styles.anchor} href="mailto:happy@wisory.se">
      happy@wisory.se
    </a>
  );
};

const ContactViaPhone = () => {
  return (
    <a className={styles.anchor} href="tel:+46762317714">
      46762317714
    </a>
  );
};

export function RescheduleInfoDialogEnglish() {
  return (
    <div>
      <ul className={styles.list}>
        <li>
          1:1-sessions may be rescheduled free of charge up until 24 hours before the confirmed
          start of the session. The proposed new date can be earlier than scheduled, but no later
          than four months into the future from the originally confirmed session date.
        </li>

        <li>
          Your session is forfeited if you attempt to reschedule within the 24 hour window. Please
          inform in writing to <ContactViaEmail /> so Wisory can inform the advisor you will not
          attend.
        </li>

        <li>
          For sessions rescheduled multiple times we encourage you to consider changing your advisor
          if your current situation requires a different expertise.
        </li>

        <li>
          A confirmed session cannot be cancelled through My Wisory, only rescheduled. In case of
          cancellation please contact <ContactViaEmail />. As long as notice has been provided with
          more than 24 hours notice you may book a different advisor If you choose not to the
          session will return to the tally of the company and be available to any other leader in
          your organisation on a first come, first served principle.
        </li>

        <li>
          Sessions where you do not connect to the session within the first 10 minutes will be
          charged in full and the session slot considered used.
        </li>

        <li>
          Leaders, you, and advisors have the right to terminate a session within the first 15
          minutes if a mismatch in expertise has occurred. The company will receive a full refund
          and Wisory Success will provide new suggestions of expertise and reactivate the session
          slot.
        </li>
        <li>
          Reach out to the Wisory Success team by SMS/phonecall primarily if you come across any
          difficulties in connecting to your session or the advisor does not appear in session.{' '}
          <ContactViaPhone /> | <ContactViaEmail />
        </li>
      </ul>
      <div>
        Remember; at Wisory you are in charge of your own learning journey. Make it count by
        ensuring you always have relevant and valuable sessions based on your current situation.
        Reach out if you wish to change focus or advisor!
      </div>
    </div>
  );
}

export function RescheduleInfoDialogSwedish() {
  return (
    <div>
      <ul className={styles.list}>
        <li>
          1:1-sessioner kan bokas om utan kostnad fram till 24 timmar före den bekräftade starttiden
          för sessionen. Det föreslagna nya datumet kan vara tidigare än det planerade, men inte
          senare än fyra månader från det ursprungligen bekräftade sessiondatumet.
        </li>

        <li>
          Din session går förlorad om du försöker boka om inom 24-timmarsfönstret. Vänligen
          informera skriftligen till <ContactViaEmail /> så att Wisory kan informera rådgivaren att
          du inte kommer delta.
        </li>

        <li>
          För sessioner som bokas om flera gånger uppmuntrar vi dig att överväga att byta rådgivare
          om din nuvarande situation kräver en annan expertis.
        </li>

        <li>
          En bekräftad session kan inte avbokas genom My Wisory, endast bokas om. Vid avbokning,
          vänligen kontakta <ContactViaEmail />. Så länge som meddelande har lämnats med mer än 24
          timmar i förväg kan du boka en annan rådgivare. Om du väljer att inte göra det kommer
          sessionen att återgå till företagets kvot och vara tillgänglig för andra ledare i din
          organisation enligt principen först till kvarn.
        </li>

        <li>
          Sessioner där du inte ansluter inom de första 10 minuterna kommer att debiteras fullt ut
          och sessionstiden anses använd.
        </li>

        <li>
          Ledare; du, och rådgivare har rätt att avbryta en session inom de första 15 minuterna om
          det uppstått en felmatchning i expertis. Företaget kommer att få full återbetalning och
          Wisory Success kommer att ge nya förslag på expertis och återaktivera sessionstiden.
        </li>
        <li>
          Kontakta Wisory Success-teamet via SMS/telefonsamtal i första hand om du stöter på
          svårigheter att ansluta till din session eller om rådgivaren inte dyker upp i sessionen.
          <br /> <ContactViaPhone /> | <ContactViaEmail />
        </li>
      </ul>
      <div>
        Kom ihåg; hos Wisory är du ansvarig för din egen läranderesa. Gör den värdefull genom att
        säkerställa att du alltid har relevanta och värdefulla sessioner inbokade baserat på din
        aktuella situation. Hör av dig om du vill byta fokus eller rådgivare!
      </div>
    </div>
  );
}

export function RescheduleInfoDialog() {
  const currentLanguage = useMemo(() => getCurrentLanguage(), []);
  const isSwedish = currentLanguage === LANGUAGES.SWEDISH;

  if (isSwedish) {
    return <RescheduleInfoDialogSwedish />;
  }

  return <RescheduleInfoDialogEnglish />;
}
