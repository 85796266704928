import { Modal as AntdModal, Grid } from 'antd';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { CloseIcon } from '../icons';
import styles from './styles.module.scss';

type Props = {
  headerCss?: string;
  contentCss?: string;
  backgroundColor?: 'white' | 'light-green' | 'light-pink';
  onCancel?: VoidFunction;
};

const { useBreakpoint } = Grid;

export function ModalContent({ contentCss, children }: PropsWithChildren<{ contentCss?: string }>) {
  return <div className={classNames(styles.contentWrapper, contentCss)}>{children}</div>;
}

export function ModalHeader({
  onCancel,
  headerCss,
  backgroundColor = 'light-green',
  children,
}: PropsWithChildren<Props>) {
  const colors = {
    white: styles.white,
    'light-green': styles.lightGreen,
    'light-pink': styles.lightPink,
  };

  return (
    <div
      className={classNames(styles.headerWrapper, headerCss, colors[backgroundColor])}
      data-dd-privacy="mask"
    >
      <div className={styles.title}>{children}</div>
      {onCancel && (
        <div className={styles.closeButton} onClick={onCancel}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
}

export function ModalDialog({
  width = 600,
  children,
}: React.PropsWithChildren<{ width?: number }>) {
  const screens = useBreakpoint();

  return (
    <AntdModal
      transitionName="none"
      maskTransitionName="none"
      title={null}
      open
      footer={null}
      width={screens.xs ? 'auto' : width}
      wrapClassName={styles.dialogWrapper}
      maskClosable={false}
    >
      <div className={classNames(styles.content)} data-dd-privacy="mask">
        {children}
      </div>
    </AntdModal>
  );
}
