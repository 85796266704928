import i18next from 'i18next';
import { LANGUAGES, LANGUAGE_ISO_MAPPING } from '@/constants';

export const getCurrentLanguage = () => {
  return i18next.resolvedLanguage || LANGUAGES.SWEDISH;
};

export const getTwoISOLanguage = () => {
  const current = getCurrentLanguage();

  return LANGUAGE_ISO_MAPPING[current as keyof typeof LANGUAGE_ISO_MAPPING];
};
