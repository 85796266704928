import { Row, Col, Button, message, Divider, Space } from 'antd';
import { useImmer } from 'use-immer';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate, formatTimeRange, getGreetingTime } from '@/common/utils/date';
import { InterestRequestView } from '@/common/api/pingpongApiClient';
import { TimePicker } from '@/common/components/datetime-picker';
import { Label } from '@/common/components';
import { capitalizeFirstLetter } from '@/common/utils/string';
import { ERROR_CODE } from '@/constants';
import { showNotification } from '@/common/utils';
import { useAuth } from '@/hooks';
import styles from '../styles.module.scss';

type BookParams = {
  date: string;
  slotId: string;
};

export type AcceptInterestParams = {
  requestId: string;
  slotId: string;
  startTime: string;
  endTime: string;
};

enum STEPS {
  SELECT_TIME = 0,
  SUBMIT_TIME = 1,
}

type Props = {
  request: InterestRequestView;
  accept: (params: AcceptInterestParams) => Promise<any>;
  onDecline: VoidFunction;
  onSuccess: VoidFunction;
  onStepChange: (step: number) => void;
};

export function AdvisorSubmitInterest({
  request,
  accept,
  onStepChange,
  onDecline,
  onSuccess,
}: Props) {
  const { t } = useTranslation('common');
  const { user } = useAuth();
  const today = useMemo(() => dayjs(), []);
  const [step, setStep] = useState<STEPS>(STEPS.SELECT_TIME);
  const [slotId, setSlotId] = useState<string>();
  const [timePerSlot, updateTimePerSlot] = useImmer<{ [slotId: string]: Dayjs | null }>({});

  useEffect(() => {
    onStepChange(step);
  }, [onStepChange, step]);

  const getDateTimeFromSlot = (slotId: string) => {
    const time = timePerSlot[slotId];
    const date = request.timeSlots?.find((s) => s.id === slotId)?.startTime;

    if (!time || !date) {
      return dayjs();
    }

    return dayjs(date)
      .set('hour', time.get('hours'))
      .set('minutes', time.get('minute'))
      .set('seconds', 0)
      .set('milliseconds', 0);
  };
  const confirmWithCustomer = useMutation(
    ({ slotId }: BookParams) => {
      const combineDateAndTime = getDateTimeFromSlot(slotId);

      return accept({
        requestId: request.id!,
        slotId,
        startTime: combineDateAndTime.toISOString(),
        endTime: combineDateAndTime.clone().add(60, 'minutes').toISOString(),
      });
    },
    {
      onSuccess,
      onError(
        error: ServerErrorResponse<{
          advisorFullname: string;
          customerCompanyName: string;
          customerFirstName: string;
          customerFullname: string;
          customerLastName: string;
          sessionDate: string;
        }>,
        variables
      ) {
        let errorMessage: any = t('message.defaultErrorMessage');
        const combineDateAndTime = getDateTimeFromSlot(variables.slotId);
        const date = capitalizeFirstLetter(`${dayjs(combineDateAndTime).format('dddd DD MMM')}`);
        const time = dayjs(combineDateAndTime).format('HH:mm');
        const params = {
          customerName: error?.errorData.customerFullname,
          customerCompany: error?.errorData.customerCompanyName,
        };

        if (error) {
          if (error?.errorCode === ERROR_CODE.SLOT_IS_OVERLAPPING) {
            if (user?.fullName === error.errorData?.advisorFullname) {
              errorMessage = t(
                'home.interestForm.advisor.acceptInterestRequest.advisorBookedWithOtherCustomer',
                {
                  ...params,
                  date,
                  time,
                }
              );
            } else {
              errorMessage = t(
                'home.interestForm.advisor.acceptInterestRequest.customerBookedWithOtherAdvisor',
                {
                  ...params,
                  date,
                  time,
                }
              );
            }
          }

          if (error?.errorCode === ERROR_CODE.COMPANY_INSUFFICENT_BALANCE) {
            errorMessage = (
              <Trans
                t={t}
                values={params}
                i18nKey="home.interestForm.advisor.acceptInterestRequest.customerHasNoCoin"
                components={{
                  newline: <br />,
                  phone: (
                    <a className={styles.rescheduleWarningAnchor} href="tel:+46762317714">
                      +46 76 231 77 14
                    </a>
                  ),
                  email: (
                    <a className={styles.rescheduleWarningAnchor} href="mailto:happy@wisory.se">
                      [happy@wisory.se]
                    </a>
                  ),
                }}
              />
            );
          }
        }

        showNotification({ type: 'error', message: errorMessage });
      },
    }
  );
  const onTimePerSlotChange = (slotId: string, time: Dayjs | null) => {
    updateTimePerSlot((draft) => {
      draft[slotId] = time;
    });
  };

  const onBook = async (slotId: string) => {
    const time = timePerSlot[slotId];
    const date = request.timeSlots?.find((s) => s.id === slotId)?.startTime;

    if (!time || !date) {
      message.error('Please select a time');
    } else {
      confirmWithCustomer.mutate({ date, slotId });
    }
  };

  const timeSlots = request.timeSlots || [];

  if (step === STEPS.SUBMIT_TIME && slotId) {
    const slotDateAndTime = getDateTimeFromSlot(slotId);

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <div>
            <Trans
              t={t}
              i18nKey="home.interestForm.confirmSlotMsg"
              values={{
                date: `${capitalizeFirstLetter(
                  formatDate(slotDateAndTime, 'dddd DD MMMM')
                )}, ${formatTimeRange(slotDateAndTime, slotDateAndTime.add(60, 'minutes'))}`,
              }}
              components={[<div />, <b />]}
            />
          </div>
        </div>
        <Row justify="end">
          <Space>
            <Button
              className={styles.btnWrapper}
              onClick={() => {
                setStep(STEPS.SELECT_TIME);
              }}
              disabled={confirmWithCustomer.isLoading}
            >
              {t('form.cancel')}
            </Button>
            <Button
              className={styles.btnWrapper}
              type="primary"
              onClick={() => {
                onBook(slotId);
              }}
              loading={confirmWithCustomer.isLoading}
            >
              {t('form.confirm')}
            </Button>
          </Space>
        </Row>
      </Space>
    );
  }

  return (
    <>
      {timeSlots.map((item) => {
        const allowTime = getGreetingTime(item.startTime, item.endTime);
        const isPast = dayjs(item.startTime).isBefore(today);
        const allowTimeTranslation =
          t('home.available') + ' ' + t(`date.${allowTime}`).toLowerCase();

        return (
          <Row align="middle" className={styles.item} key={item.id!} gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Row
                className={classNames(styles.timeWrapper, {
                  [styles.expired]: isPast,
                })}
                gutter={16}
              >
                <Col xs={14} md={24}>
                  <div className={styles.timeText}>
                    {capitalizeFirstLetter(dayjs(item.startTime).format('dddd DD MMMM'))}
                  </div>
                </Col>
                <Col xs={10} md={24}>
                  <div>{isPast ? t('home.interestForm.timeIsExpired') : allowTimeTranslation}</div>
                </Col>
              </Row>
            </Col>
            {!isPast && (
              <Col xs={24} md={12}>
                <Row gutter={16}>
                  <Col span={14}>
                    <TimePicker
                      className={styles.timepicker}
                      allowTime={allowTime}
                      value={timePerSlot[item.id!]}
                      onChange={(value) => {
                        onTimePerSlotChange(item.id!, value ? value.set('seconds', 0) : null);
                      }}
                    />
                  </Col>
                  <Col span={10}>
                    <Button
                      style={{ width: 120 }}
                      disabled={!timePerSlot[item.id!] || isPast}
                      loading={confirmWithCustomer.isLoading}
                      type="primary"
                      onClick={() => {
                        setSlotId(item.id!);
                        setStep(STEPS.SUBMIT_TIME);
                      }}
                    >
                      {t('form.book')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        );
      })}

      {request.message && (
        <div className={styles.messageWrapper}>
          <div className={styles.label}>{t('home.interestForm.messageFromUser')}</div>
          <div className={styles.notesContent}>{request.message}</div>
        </div>
      )}

      <Divider />
      <Space size="large" style={{ width: '100%' }} direction="vertical">
        <Label>{t('home.interestForm.suggestAlternativeTitle')}</Label>
        <Button style={{ width: '100%' }} onClick={onDecline}>
          {t('home.interestForm.suggestAlternativeBtn')}
        </Button>
      </Space>
    </>
  );
}
