import { Grid, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth, useGlobalTitle } from '@/hooks';
import { Title } from '@/common/components';
import { AdvisorAvailablitiy } from './components/advisor-availablity';
import styles from './styles.module.scss';
import { UpcomingSessionBlock } from './components/upcoming-session-block';
import { InboxBlock } from './components/inbox-block';
import { CompanyMetrics } from './components/company-metrics';
import { CompanyUsers } from './components/company-users';

const { useBreakpoint } = Grid;

export default function Home() {
  const { t } = useTranslation('common');
  const { user, isDecisionMaker, isAdvisor, isUser } = useAuth();
  const screens = useBreakpoint();
  const showColumnLayout = !screens.xl && !screens.xxl;
  // Set Global title to empty to clear the title
  useGlobalTitle('');

  if (!user) {
    return <Result status={404} />;
  }

  if (Object.keys(screens).length === 0) {
    return null;
  }

  const renderUserLayout = () => {
    if (showColumnLayout) {
      return (
        <div className={styles.columnLayoutWrapper}>
          <InboxBlock />
          <UpcomingSessionBlock />
        </div>
      );
    } else {
      return (
        <div className={styles.wrapper}>
          <div className={styles.leftColumn}>
            <UpcomingSessionBlock />
          </div>
          <div className={styles.rightColumn}>
            <InboxBlock />
          </div>
        </div>
      );
    }
  };

  const renderAdvisorLayout = () => {
    if (showColumnLayout) {
      return (
        <div className={styles.columnLayoutWrapper}>
          <InboxBlock isAdvisor />
          <div>
            <AdvisorAvailablitiy />
          </div>
          <div>
            <UpcomingSessionBlock isAdvisor />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.wrapper}>
          <div className={styles.leftColumn}>
            <AdvisorAvailablitiy />
          </div>
          <div className={styles.rightColumn}>
            <InboxBlock isAdvisor />
            <UpcomingSessionBlock isAdvisor />
          </div>
        </div>
      );
    }
  };

  const renderDecisionLayout = () => {
    if (!user?.companyId) {
      return (
        <Title type="large" className={styles.title}>
          {t('message.decisionMakerWithoutCompany')}
        </Title>
      );
    }

    if (showColumnLayout) {
      return (
        <div className={styles.columnLayoutWrapper}>
          <CompanyUsers />
          <CompanyMetrics />
        </div>
      );
    } else {
      return (
        <div className={styles.wrapper}>
          <div className={styles.leftColumn}>
            <CompanyUsers />
          </div>
          <div className={styles.rightColumn}>
            <CompanyMetrics />
          </div>
        </div>
      );
    }
  };

  if (isUser) {
    return renderUserLayout();
  }

  if (isAdvisor) {
    return renderAdvisorLayout();
  }

  if (isDecisionMaker) {
    return renderDecisionLayout();
  }

  return (
    <div style={{ padding: 20 }}>
      <Title type="x-large">{t('home.welcomeTitle')}</Title>
    </div>
  );
}
