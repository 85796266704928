/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressView {
  /** @format int64 */
  id?: number;
  addressLine1?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
}

export interface AppointmentAvailabilityDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  advisorId?: number;
  advisorName?: string | null;
  advisorEmail?: string | null;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  status?: AppointmentStatus;
  /** @format date-time */
  validFrom?: string | null;
  /** @format date-time */
  expiryDate?: string | null;
  /** @format date-time */
  createdDate?: string;
  externalCalendarId?: string | null;
  /** @format double */
  price?: number;
  customerName?: string | null;
  /** @format int64 */
  customerId?: number | null;
  customerEmail?: string | null;
  customerCompanyName?: string | null;
}

export enum AppointmentStatus {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
}

export interface BookingCalendarEventDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  advisorId?: number;
  advisorName?: string | null;
  advisorEmail?: string | null;
  /** @format int64 */
  customerId?: number | null;
  customerName?: string | null;
  customerFirstName?: string | null;
  customerEmail?: string | null;
  companyName?: string | null;
  /** @format int64 */
  appointmentAvailabilityId?: number | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format date-time */
  createdDate?: string;
  status?: BookingCalendarEventStatus;
  platformType?: CalendarEventPlatformType;
  shortDescription?: string | null;
  reason?: string | null;
  externalId?: string | null;
  invoice?: InvoiceView | null;
  /** @format int64 */
  invoiceId?: number | null;
  useOnlinePayment?: boolean;
  paymentType?: BookingPaymentType;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format date-time */
  lastSentAdvisorEmail?: string | null;
  locale?: string | null;
  /** @format double */
  price?: number;
  bookingCalendarEventType?: BookingCalendarEventType | null;
  canBeRebook?: boolean;
  canBeVerified?: boolean;
  /** @format int64 */
  originBookingId?: number | null;
  bookingQuality?: BookingQualityEnum;
  acceptTransitionStates?: BookingCalendarEventStatus[] | null;
  bookingCalendarEventTypeDisplayName?: string | null;
  acceptTransitionStatesName?: string | null;
  reference?: string | null;
  sessionDetailResponse?: WisoryVideoApiClientSessionDetailInternalResponse | null;
  wisoryVideoStatusResponse?: WisoryVideoApiClientGetWisoryVideoStatusResponse | null;
  googleMeetLink?: string | null;
  msTeamLink?: string | null;
  videoLink?: string | null;
  /** @format double */
  currentPriceOfStatus?: number | null;
  advisorFirstName?: string | null;
  advisorLastName?: string | null;
  customerLastName?: string | null;
  isRefunded?: boolean;
}

export enum BookingCalendarEventStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  CANCELLED = 'CANCELLED',
  POSTPONED = 'POSTPONED',
  INVOICED = 'INVOICED',
  PERFORMED = 'PERFORMED',
  LATE_CANCELLED = 'LATE_CANCELLED',
  NO_SHOW = 'NO_SHOW',
  USER_SENT_PROPOSAL = 'USER_SENT_PROPOSAL',
  ADVISOR_SENT_PROPOSAL = 'ADVISOR_SENT_PROPOSAL',
}

export enum BookingCalendarEventType {
  Website = 'Website',
  BackOffice = 'BackOffice',
  Rebuy = 'Rebuy',
}

export enum BookingCreditAdjustmentType {
  NONE = 'NONE',
  ADJUSTMENT = 'ADJUSTMENT',
  BOOKING = 'BOOKING',
  CANCELED = 'CANCELED',
  POSTPONED = 'POSTPONED',
  PRICE_UPDATE = 'PRICE_UPDATE',
}

export enum BookingPaymentType {
  COMPANY_CREDIT = 'COMPANY_CREDIT',
  CARD_PAYMENT = 'CARD_PAYMENT',
  INVOICE = 'INVOICE',
}

export enum BookingQualityEnum {
  Untrack = 'Untrack',
  Valid = 'Valid',
  NeedsWork = 'NeedsWork',
}

export interface BulkDeleteAvailabilityBookingSlotRequest {
  /** @format int64 */
  appointmentAvailabilityId?: number;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface BulkUpdateAvailabilityBookingSlotRequest {
  /** @format int64 */
  appointmentAvailabilityId?: number | null;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface BulkUpdateAvailabilityBookingSlotResponse {
  publicAppointmentAvailabilityView?: PublicAppointmentAvailabilityView | null;
  isUpdated?: boolean;
  reason?: string | null;
}

export interface BulkUpdateAvailabilityBookingSlotsRequest {
  /** @format int64 */
  advisoryId?: number;
  locale?: string | null;
  bulkUpdateAvailabilityBookingSlotsRequests?: BulkUpdateAvailabilityBookingSlotRequest[] | null;
  bulkDeleteAvailabilityBookingSlotsRequests?: BulkDeleteAvailabilityBookingSlotRequest[] | null;
}

export interface CalendarEventBookingSessionHistoryView {
  /** @format int64 */
  bookingCalendarEventId?: number | null;
  /** @format int64 */
  appointmentAvailabilityId?: number | null;
}

export enum CalendarEventPlatformType {
  NONE = 'NONE',
  GOOGLE_MEET = 'GOOGLE_MEET',
  MS_TEAMS = 'MS_TEAMS',
  ZOOM = 'ZOOM',
  WISORY_VIDEO = 'WISORY_VIDEO',
}

export interface CompanyBookingSessionHistoryProfileView {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  userBookingSessionHistoryId?: number | null;
  /** @format int64 */
  companyBookingSessionHistoryId?: number | null;
  /** @format int64 */
  calendarEventBookingSessionHistoryId?: number | null;
  userBookingSessionHistory?: UserBookingSessionHistoryView | null;
  companyBookingSessionHistory?: CompanyBookingSessionHistoryView | null;
  calendarEventBookingSessionHistory?: CalendarEventBookingSessionHistoryView | null;
  adjustmentType?: BookingCreditAdjustmentType | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
}

export interface CompanyBookingSessionHistoryProfileViewPaginationResult {
  items?: CompanyBookingSessionHistoryProfileView[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface CompanyBookingSessionHistoryView {
  /** @format int64 */
  companyId?: number | null;
  companyName?: string | null;
  companyNumber?: string | null;
  /** @format double */
  bookingSessionCredit?: number | null;
}

export interface CompanyProfileView {
  /** @format int64 */
  id?: number;
  companyName?: string | null;
  companyNumber?: string | null;
  /** @format int64 */
  mainContactId?: number | null;
  /** @format int64 */
  primaryAddressId?: number | null;
  /** @format double */
  bookingSessionCount?: number | null;
  /** @format date-time */
  lastModifiedDate?: string;
}

export interface CompanyView {
  /** @format int64 */
  id?: number;
  companyName?: string | null;
  companyNumber?: string | null;
  /** @format int64 */
  mainContactId?: number | null;
  /** @format int64 */
  primaryAddressId?: number | null;
  /** @format int64 */
  createdByUserId?: number | null;
  mainContact?: IdentityUserView | null;
  primaryAddress?: AddressView | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
  /** @format double */
  bookingSessionCredit?: number | null;
}

export interface CreateRecurringAvailabilityBookingSlotsCommand {
  /** @format int64 */
  advisorId?: number;
  locale?: string | null;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  untilDate?: string;
  repeat?: RecurringSlotSettingType | null;
  /** @format int32 */
  repeatEvery?: number | null;
  repeatEveryOn?: RepeatEveryOnType | null;
  repeatOns?: RepeatOnType[] | null;
}

export interface CreateUnavailabilityCommand {
  /** @format int64 */
  advisorId?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
}

export enum CustomerProfessionalTitle {
  Finance = 'Finance',
  Sales = 'Sales',
  Marketing = 'Marketing',
  HR = 'HR',
  CEO_GM = 'CEO_GM',
  ITTech = 'IT_Tech',
  Engineering = 'Engineering',
  Logistic = 'Logistic',
  SupplyChain = 'SupplyChain',
}

export interface ForgotPasswordView {
  /**
   * @format email
   * @minLength 1
   * @maxLength 256
   */
  emailAddress: string;
}

export interface GetAppointmentListQueryResponse {
  appointmentAvailabilities?: AppointmentAvailabilityDto[] | null;
  bookedAppointmentAvailabilities?: BookingCalendarEventDto[] | null;
}

export interface IdentityUserView {
  /** @format int64 */
  id?: number;
  email?: string | null;
  phoneNumber?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
  primaryAddress?: AddressView | null;
  description?: string | null;
  company?: CompanyView | null;
  /** @format int64 */
  primaryAddressId?: number | null;
  /** @format int64 */
  companyId?: number | null;
  professionalTitle?: CustomerProfessionalTitle | null;
  status?: UserStatusEnum;
  roles?: string[] | null;
  metadataSets?: MetadataSetView[] | null;
  fullName?: string | null;
  hadPassword?: boolean;
  /** @format int32 */
  totalSessionCount?: number | null;
  /** @format int32 */
  nextSessionCount?: number | null;
  /** @format date-time */
  nextBookingSessionDate?: string | null;
  /** @format date-time */
  lastBookingSessionDate?: string | null;
  /** @format int32 */
  totalLateSessionCount?: number | null;
  /** @format int32 */
  totalPerformedSessionCount?: number | null;
  timeZoneId?: string | null;
}

export interface InvoiceRecipientInfo {
  customerName?: string | null;
  customerEmail?: string | null;
  customerCompany?: string | null;
  customerPhoneNumber?: string | null;
  customerBillingAddress?: string | null;
}

export interface InvoiceSessionHistoryProfileView {
  /** @format int64 */
  id?: number;
  /** @format double */
  amount?: number;
  currency?: string | null;
  recipientInfo?: InvoiceRecipientInfo | null;
  invoiceContent?: StripeCheckoutOrderItem[] | null;
  paymentStatus?: PaymentStatus;
  invoiceStatus?: InvoiceStatus;
  invoiceType?: InvoiceType;
  transaction?: TransactionView | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
}

export interface InvoiceSessionHistoryProfileViewPaginationResult {
  items?: InvoiceSessionHistoryProfileView[] | null;
  /** @format int64 */
  totalCount?: number;
}

export enum InvoiceStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
}

export enum InvoiceType {
  NONE = 'NONE',
  ONLINE_BOOKING_APPOINTMENT = 'ONLINE_BOOKING_APPOINTMENT',
  ONLINE_INVOICE_BOOKING_APPOINTMENT = 'ONLINE_INVOICE_BOOKING_APPOINTMENT',
  MANUAL_INVOICE_BOOKING_APPOINTMENT = 'MANUAL_INVOICE_BOOKING_APPOINTMENT',
}

export interface InvoiceView {
  /** @format int64 */
  id?: number;
  /** @format double */
  amount?: number;
  currency?: string | null;
  recipientInfo?: InvoiceRecipientInfo | null;
  invoiceContent?: StripeCheckoutOrderItem[] | null;
  /** @deprecated */
  paymentStatus?: PaymentStatus;
  invoiceStatus?: InvoiceStatus;
  invoiceType?: InvoiceType;
  transaction?: TransactionView | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
}

export enum MetadataDisplayType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE = 'DATE',
}

export interface MetadataSetOptionView {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  metadataSetId?: number;
  displayName?: string | null;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  value?: string | null;
}

export interface MetadataSetView {
  /** @format int64 */
  id?: number;
  name?: string | null;
  description?: string | null;
  displayType?: MetadataDisplayType;
  entityType?: string | null;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format date-time */
  createdDate?: string;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
  /** @format date-time */
  lastModifiedDate?: string;
  options?: MetadataSetOptionView[] | null;
  value?: string | null;
}

export interface Operation {
  operationType?: OperationType;
  path?: string | null;
  op?: string | null;
  from?: string | null;
  value?: any;
}

export enum OperationType {
  Add = 'Add',
  Remove = 'Remove',
  Replace = 'Replace',
  Move = 'Move',
  Copy = 'Copy',
  Test = 'Test',
  Invalid = 'Invalid',
}

export enum OrderItemProductType {
  NONE = 'NONE',
  BOOKING_APPOINTMENT_AVAILABILITY = 'BOOKING_APPOINTMENT_AVAILABILITY',
}

export enum PaymentStatus {
  PAYMENT_INIT = 'PAYMENT_INIT',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
}

export interface ProfileCompanyUser {
  /** @format int64 */
  id?: number;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /** @format int64 */
  usedCoins?: number;
  /** @format int64 */
  bookedSessionCount?: number;
  /** @format int64 */
  postponedSessionCount?: number;
  /** @format int64 */
  cancelledSessionCount?: number;
  /** @format date-time */
  nextSessionDate?: string | null;
  /** @format date-time */
  lastSessionDate?: string | null;
  fullName?: string | null;
  email?: string | null;
}

export interface ProfileCompanyUserPaginationResult {
  items?: ProfileCompanyUser[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface PublicAppointmentAvailabilityView {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  advisorId?: number;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface RecurringSlotSettingModel {
  /** @format int64 */
  advisorId?: number;
  locale?: string | null;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  untilDate?: string;
  repeat?: RecurringSlotSettingType | null;
  /** @format int32 */
  repeatEvery?: number | null;
  repeatEveryOn?: RepeatEveryOnType | null;
  repeatOns?: RepeatOnType[] | null;
}

export enum RecurringSlotSettingType {
  None = 'None',
  Daily = 'Daily',
  Weekdays = 'Weekdays',
  Custom = 'Custom',
}

export enum RepeatEveryOnType {
  None = 'None',
  Week = 'Week',
  Month = 'Month',
}

export enum RepeatOnType {
  None = 'None',
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun',
  AllowedAll = 'AllowedAll',
}

export interface ResetPasswordView {
  /**
   * @format email
   * @minLength 1
   * @maxLength 256
   */
  emailAddress: string;
  /**
   * @minLength 1
   * @maxLength 256
   */
  newPassword: string;
  confirmationCode?: string | null;
}

export interface SendEmailVerificationCommand {
  emailAddress?: string | null;
  /** @format int64 */
  userId?: number;
  locale?: string | null;
}

export interface SendOtpVerificationPhoneNumberCommand {
  phoneNumber?: string | null;
  /** @format int64 */
  userId?: number;
}

export interface StripeCheckoutOrderItem {
  productId?: string | null;
  productType?: OrderItemProductType;
  /** @format int32 */
  quantity?: number;
  /** @format int64 */
  amount?: number;
  currency?: string | null;
  metadata?: Record<string, string | null>;
  productName?: string | null;
  description?: string | null;
  imageUrls?: string[] | null;
}

export enum TransactionDiscriminator {
  NONE = 'NONE',
  STRIPE_CHECKOUT_CARD_PAYMENT_TRANSACTION = 'STRIPE_CHECKOUT_CARD_PAYMENT_TRANSACTION',
  INVOICE_PAYMENT_TRANSACTION = 'INVOICE_PAYMENT_TRANSACTION',
}

export enum TransactionStatus {
  INIT = 'INIT',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum TransactionType {
  NONE = 'NONE',
  CHARGE = 'CHARGE',
  CHECK = 'CHECK',
  DEPOSIT = 'DEPOSIT',
  ONLINE = 'ONLINE',
  POS = 'POS',
  TRANSFER = 'TRANSFER',
  WITHDRAWAL = 'WITHDRAWAL',
}

export interface TransactionView {
  /** @format int64 */
  id?: number;
  status?: TransactionStatus;
  type?: TransactionType;
  externalTransactionId?: string | null;
  /** @format int64 */
  invoiceId?: number;
  transactionDiscriminator?: TransactionDiscriminator;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format int64 */
  createdByUserId?: number | null;
  /** @format int64 */
  lastModifiedByUserId?: number | null;
}

export interface UnavailabilityModel {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  advisorId?: number;
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface UpdatePasswordView {
  /** @maxLength 256 */
  oldPassword?: string | null;
  /**
   * @minLength 1
   * @maxLength 256
   */
  newPassword: string;
}

export interface UserBookingSessionHistoryView {
  /** @format int64 */
  userId?: number | null;
  userName?: string | null;
  userEmail?: string | null;
  userTelephone?: string | null;
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}

export interface VerifyEmailAddressCommand {
  /** @format int64 */
  userId?: number;
  emailAddress?: string | null;
  otp?: string | null;
}

export interface VerifyPhoneNumberCommand {
  /** @format int64 */
  userId?: number;
  phoneNumber?: string | null;
  otp?: string | null;
}

export interface WisoryVideoApiClientGetWisoryVideoStatusResponse {
  /** @format int64 */
  bookingId?: number;
  roomName?: string | null;
  /** @format int64 */
  totalPackets?: number;
  /** @format int64 */
  totalPacketLoss?: number;
  /** @format int64 */
  lowestNetworkQualityLevel?: number;
  /** @format double */
  highestPacketLoss?: number;
  /** @format int64 */
  lostPercentage?: number;
  levelStatus?: WisoryVideoApiClientVideoRoomStatus;
}

export interface WisoryVideoApiClientParticipantDetail {
  /** @format uuid */
  id?: string;
  status?: WisoryVideoApiClientParticipantStatus;
  /** @format int32 */
  type?: number;
  identity?: string | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
}

export enum WisoryVideoApiClientParticipantStatus {
  Inactive = 'Inactive',
  Join = 'Join',
  Disconnect = 'Disconnect',
  Rejoin = 'Rejoin',
}

export interface WisoryVideoApiClientSessionDetailInternalResponse {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  bookingId?: number | null;
  name?: string | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  numberOfParticipants?: number;
  status?: WisoryVideoApiClientSessionStatus;
  participants?: WisoryVideoApiClientParticipantDetail[] | null;
  videoSessionLink?: string | null;
  videoId?: string | null;
  createRoomResponseAsJson?: string | null;
  isVideoCallRoomCreated?: boolean;
}

export enum WisoryVideoApiClientSessionStatus {
  Inactive = 'Inactive',
  Open = 'Open',
  Close = 'Close',
}

export enum WisoryVideoApiClientVideoRoomStatus {
  Unknown = 'Unknown',
  Green = 'Green',
  Warning = 'Warning',
  Error = 'Error',
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Wisory Profile API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  account = {
    /**
     * No description
     *
     * @tags account
     * @name GetMyAccount
     * @request GET:/api/users/me
     * @secure
     */
    getMyAccount: (params: RequestParams = {}) =>
      this.http.request<IdentityUserView, string>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userProfile = {
    /**
     * No description
     *
     * @tags UserProfile
     * @name PartialUpdateMe
     * @request PATCH:/api/users/me
     * @secure
     */
    partialUpdateMe: (data: Operation[], params: RequestParams = {}) =>
      this.http.request<IdentityUserView, any>({
        path: `/api/users/me`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetCurrentConyProfileWithSessionHistories
     * @request GET:/api/users/me/companies/{companyId}/coin-histories
     * @secure
     */
    getCurrentConyProfileWithSessionHistories: (
      companyId: number,
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<CompanyBookingSessionHistoryProfileViewPaginationResult, string>({
        path: `/api/users/me/companies/${companyId}/coin-histories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetUsersOfDecisionMakerCompany
     * @request GET:/api/users/me/companies/{companyId}/users
     * @secure
     */
    getUsersOfDecisionMakerCompany: (
      companyId: number,
      query?: {
        /**
         * @format int32
         * @default 0
         */
        currentPage?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ProfileCompanyUserPaginationResult, string>({
        path: `/api/users/me/companies/${companyId}/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetCurrentUserProfileWithInvoiceHistories
     * @request GET:/api/users/me/invoice-histories
     * @secure
     */
    getCurrentUserProfileWithInvoiceHistories: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<InvoiceSessionHistoryProfileViewPaginationResult, string>({
        path: `/api/users/me/invoice-histories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name SendOtpVerificationMyPhoneNumber
     * @request POST:/api/users/me/phone-number-otp
     * @secure
     */
    sendOtpVerificationMyPhoneNumber: (
      data: SendOtpVerificationPhoneNumberCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<boolean, any>({
        path: `/api/users/me/phone-number-otp`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name UpdateMyPhoneNumber
     * @request PUT:/api/users/me/phone-number
     * @secure
     */
    updateMyPhoneNumber: (data: VerifyPhoneNumberCommand, params: RequestParams = {}) =>
      this.http.request<IdentityUserView, any>({
        path: `/api/users/me/phone-number`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name SendEmailVerificationMyEmail
     * @request POST:/api/users/me/email-otp
     * @secure
     */
    sendEmailVerificationMyEmail: (
      data: SendEmailVerificationCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<boolean, any>({
        path: `/api/users/me/email-otp`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name UpdateMyEmail
     * @request PUT:/api/users/me/email
     * @secure
     */
    updateMyEmail: (data: VerifyEmailAddressCommand, params: RequestParams = {}) =>
      this.http.request<IdentityUserView, any>({
        path: `/api/users/me/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetAppointmentAvailabilitySlotsForUserProfile
     * @request GET:/api/users/me/appointment-availability-slots
     * @secure
     */
    getAppointmentAvailabilitySlotsForUserProfile: (
      query?: {
        /** @default "" */
        periodFromDate?: string;
        /** @default "" */
        periodToDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GetAppointmentListQueryResponse, any>({
        path: `/api/users/me/appointment-availability-slots`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name UpdateAppointmentAvailabilitySlotsForUserProfile
     * @request PUT:/api/users/me/appointment-availability-slots
     * @secure
     */
    updateAppointmentAvailabilitySlotsForUserProfile: (
      data: BulkUpdateAvailabilityBookingSlotsRequest,
      params: RequestParams = {}
    ) =>
      this.http.request<BulkUpdateAvailabilityBookingSlotResponse[], any>({
        path: `/api/users/me/appointment-availability-slots`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetRecurringAppointmentAvailabilitySlotsForUserProfile
     * @request GET:/api/users/me/recurring-appointment-availabilities
     * @secure
     */
    getRecurringAppointmentAvailabilitySlotsForUserProfile: (params: RequestParams = {}) =>
      this.http.request<RecurringSlotSettingModel, any>({
        path: `/api/users/me/recurring-appointment-availabilities`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name CreateRecurringAppointmentAvailabilitySlotsForUserProfile
     * @request POST:/api/users/me/recurring-appointment-availabilities
     * @secure
     */
    createRecurringAppointmentAvailabilitySlotsForUserProfile: (
      data: CreateRecurringAvailabilityBookingSlotsCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<BulkUpdateAvailabilityBookingSlotResponse[], any>({
        path: `/api/users/me/recurring-appointment-availabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name CreateUnavailabilityForUserProfile
     * @request POST:/api/users/me/unavailability
     * @secure
     */
    createUnavailabilityForUserProfile: (
      data: CreateUnavailabilityCommand,
      params: RequestParams = {}
    ) =>
      this.http.request<UnavailabilityModel, any>({
        path: `/api/users/me/unavailability`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetUnavailabilityForUserProfile
     * @request GET:/api/users/me/unavailability
     * @secure
     */
    getUnavailabilityForUserProfile: (params: RequestParams = {}) =>
      this.http.request<UnavailabilityModel, any>({
        path: `/api/users/me/unavailability`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetCurrentUserProfileSessions
     * @request GET:/api/users/me/sessions
     * @secure
     */
    getCurrentUserProfileSessions: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        periodStartDate?: string;
        /** @default "" */
        periodEndDate?: string;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/users/me/sessions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetCurrentUserProfileNextSessions
     * @request GET:/api/users/me/sessions/next
     * @secure
     */
    getCurrentUserProfileNextSessions: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        periodStartDate?: string;
        /** @default "" */
        periodEndDate?: string;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/users/me/sessions/next`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name GetCurrentUserProfileLastSessions
     * @request GET:/api/users/me/sessions/last
     * @secure
     */
    getCurrentUserProfileLastSessions: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        pageIndex?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** @default "" */
        periodStartDate?: string;
        /** @default "" */
        periodEndDate?: string;
        /** @default "" */
        dynamicFilters?: string;
        /** @default "" */
        dynamicSorts?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<void, any>({
        path: `/api/users/me/sessions/last`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name CancelCalendarEventOnUserProfile
     * @request PUT:/api/users/me/{calendarEventId}/cancel
     * @secure
     */
    cancelCalendarEventOnUserProfile: (calendarEventId: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/users/me/${calendarEventId}/cancel`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name UserChangePassword
     * @request PUT:/api/users/me/password
     * @secure
     */
    userChangePassword: (data: UpdatePasswordView, params: RequestParams = {}) =>
      this.http.request<IdentityUserView, string>({
        path: `/api/users/me/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name ForgotPassword
     * @request POST:/api/users/me/forgot-password
     * @secure
     */
    forgotPassword: (data: ForgotPasswordView, params: RequestParams = {}) =>
      this.http.request<IdentityUserView, string>({
        path: `/api/users/me/forgot-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfile
     * @name ResetUserPassword
     * @request POST:/api/users/me/reset-password
     * @secure
     */
    resetUserPassword: (data: ResetPasswordView, params: RequestParams = {}) =>
      this.http.request<IdentityUserView, string>({
        path: `/api/users/me/reset-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  test = {
    /**
     * No description
     *
     * @tags test
     * @name GetCurrentCompanyProfileId
     * @request GET:/api/users/me/companies
     * @secure
     */
    getCurrentCompanyProfileId: (params: RequestParams = {}) =>
      this.http.request<CompanyProfileView, string>({
        path: `/api/users/me/companies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
