import { Button } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CancelledIcon, ConfirmedIcon } from '@/common/components/icons';
import {
  Label,
  ModalContent,
  ModalDialog,
  ModalHeader,
  SuggestedSlots,
  SummaryBlock,
} from '@/common/components';
import {
  UpcomingSessionViewModel,
  api,
  capitalizeFirstLetter,
  formatDate,
  formatTimeRange,
  refetch,
  showNotification,
} from '@/common/utils';
import { API_QUERIES } from '@/constants';
import { BookingCalendarEventStatus } from '@/common/api/wisoryApiClient';
import { SuggestedPeriods } from '@/common/components/suggested-periods';
import { RescheduleInfoDialog } from '../inbox-modal/reschedule-info';
import styles from './styles.module.scss';

type Props = {
  isAdvisor: boolean;
  session: UpcomingSessionViewModel;
  readOnly?: boolean;
  onCancel: VoidFunction;
};

const TWENTY_FOR_HOURS = 86400000;

export function DetailsSessionDialog({ session, readOnly, isAdvisor, onCancel }: Props) {
  const { t } = useTranslation('common');
  const isCancelled =
    session.status === BookingCalendarEventStatus.CANCELLED ||
    session.status === BookingCalendarEventStatus.LATE_CANCELLED;
  const isVerified = session.status === BookingCalendarEventStatus.VERIFIED;
  const isPerformed =
    session.status === BookingCalendarEventStatus.PERFORMED ||
    session.status === BookingCalendarEventStatus.INVOICED;
  const [isRescheduledScreen, setIsRescheduledScreen] = useState(false);
  const canRescheduled = useMemo(() => {
    const today = dayjs();

    return dayjs(session.startTime).diff(today) > TWENTY_FOR_HOURS;
  }, [session.startTime]);
  const [billingInfoOpen, setBillingInfoOpen] = useState<boolean>();

  const goBack = () => {
    setIsRescheduledScreen(false);
  };

  const goToProposeTimeScreen = () => {
    setIsRescheduledScreen(true);
  };

  const sessionDetailsScreen = useMemo(() => {
    return (
      <div
        className={classNames({
          [styles.cancelledText]: isCancelled,
        })}
      >
        <div className={styles.formWraper}>
          {session.purpose && (
            <div className={styles.formItem}>
              <Label>{t('home.detailsSessionDialog.purpose')}</Label>
              <p className={styles.text}>{session.purpose}</p>
            </div>
          )}
          {session.description && (
            <div className={styles.formItem}>
              <Label>{t('home.detailsSessionDialog.problem')}</Label>
              <p className={styles.text}>{session.description}</p>
            </div>
          )}
        </div>

        {!isCancelled && !isPerformed && !readOnly && (
          <div className={styles.bottomWrapper}>
            <div className={styles.buttonsWrapper}>
              <Button className={styles.reschedulingButton} onClick={goToProposeTimeScreen}>
                {t('home.detailsSessionDialog.rescheduleBtn')}
              </Button>
              {session.videoSessionUrl && (
                <Button
                  type="primary"
                  onClick={() => {
                    window.open(session.videoSessionUrl, '__blank');
                  }}
                >
                  {t('home.detailsSessionDialog.startSessionBtn')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }, [
    isCancelled,
    session.purpose,
    session.description,
    session.videoSessionUrl,
    t,
    isPerformed,
    readOnly,
  ]);

  const renderRescheduleScreen = () => {
    if (!canRescheduled) {
      return (
        <div>
          <div className={styles.rescheduleWarningTitle}>
            {t('home.detailsSessionDialog.rescheduleWarningMessage')}
          </div>
          <SummaryBlock className={styles.rescheduleWarningSummary} type="error">
            <Trans
              t={t}
              i18nKey="home.detailsSessionDialog.rescheduleWarningSummary"
              components={{
                bold: <strong />,
                newline: <br />,
                phone: (
                  <a className={styles.rescheduleWarningAnchor} href="tel:+46762317714">
                    +46 76 231 77 14
                  </a>
                ),
                email: (
                  <a className={styles.rescheduleWarningAnchor} href="mailto:happy@wisory.se">
                    [happy@wisory.se]
                  </a>
                ),
              }}
            />
          </SummaryBlock>
        </div>
      );
    }

    const defaultProposalMessage = (() => {
      const params = {
        userFirstName: session.customerFirstName,
        userFullName: session.customerFullName,
        userCompany: session.customerCompanyName,
        advisorFullName: session.advisorName,
        advisorFirstName: session.advisorFirstName,
        sessionDateRange: `${formatDate(session.startTime, 'dddd DD MMM')} ${formatTimeRange(
          session.startTime,
          session.endTime
        )}`,
      };

      return t(
        `home.interestForm.${isAdvisor ? 'advisor' : 'customer'}.counterReschedulingRequest`,
        params
      );
    })();

    if (isAdvisor) {
      return (
        <SuggestedSlots
          bookingId={session.id}
          title={t('home.interestForm.advisorTitle')}
          onCancel={goBack}
          defaultMessage={defaultProposalMessage}
          onSuccess={() => {
            showNotification({ type: 'success', message: t('message.rescheduleSuccess') });

            return Promise.all([
              refetch([API_QUERIES.UPCOMING_SESSIONS]),
              refetch([API_QUERIES.ADVISOR_GET_INTEREST_REQUEST]),
              refetch([API_QUERIES.ADVISOR_GET_AVAILABILITY_SLOTS]),
            ]).then(onCancel);
          }}
          sendSuggestions={(props) => {
            return api.pingpongApi.bookings.rescheduleCalendarEvent(session.id!, {
              message: props.message,
              timeSlotRequests: props.suggestedSlots,
            });
          }}
          isRescheduled
        />
      );
    }

    return (
      <SuggestedPeriods
        title={t('home.interestForm.userTitle')}
        defaultMessage={defaultProposalMessage}
        onCancel={goBack}
        onSuccess={() => {
          showNotification({ type: 'success', message: t('message.rescheduleSuccess') });

          return Promise.all([
            refetch([API_QUERIES.UPCOMING_SESSIONS]),
            refetch([API_QUERIES.CUSTOMER_GET_INTEREST_REQUEST]),
          ]).then(onCancel);
        }}
        sendSuggestions={(props) => {
          return api.pingpongApi.bookings.rescheduleCalendarEvent(session.id!, {
            message: props.message,
            timeSlotRequests: props.suggestedSlots,
          });
        }}
        isRescheduled
      />
    );
  };

  return (
    <ModalDialog>
      {billingInfoOpen && (
        <ModalHeader
          onCancel={() => {
            setBillingInfoOpen(false);
          }}
        >
          <div className={styles.ruleForBookingTitle}>{t('rescheduleRulesDialog.action')}</div>
        </ModalHeader>
      )}
      {!billingInfoOpen && (
        <ModalHeader
          backgroundColor={isCancelled ? 'light-pink' : 'light-green'}
          onCancel={() => {
            if (billingInfoOpen) {
              setBillingInfoOpen(false);
            } else {
              onCancel();
            }
          }}
        >
          <div className={styles.infoRow}>
            {!isRescheduledScreen && (
              <div className={styles.iconWrapper}>
                {isCancelled ? <CancelledIcon size="large" /> : <ConfirmedIcon size="large" />}
              </div>
            )}

            <div className={styles.textWrapper}>
              {isRescheduledScreen && (
                <div className={styles.mainText}>
                  {t('home.interestForm.proposeNewTime.rescheduleTitle')}
                </div>
              )}

              {!isRescheduledScreen && (
                <div className={classNames(styles.mainText, { [styles.redColor]: isCancelled })}>
                  {isVerified && t('home.detailsSessionDialog.confirmedSessionTitle')}
                  {isCancelled && t('home.detailsSessionDialog.canceledSessionTitle')}
                  {isPerformed && t('home.detailsSessionDialog.performedSessionTitle')}
                </div>
              )}

              <div className={styles.subText}>
                {t('home.inboxBlock.bookingId', { bookingId: session.id })}
                {isRescheduledScreen && (
                  <Button
                    className={styles.infoLink}
                    type="link"
                    onClick={() => setBillingInfoOpen(true)}
                  >
                    {t('rescheduleRulesDialog.action')}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={styles.titleRow}>
            <div className={styles.block}>
              <div className={styles.label}>{t('home.date')}:</div>
              <div>{capitalizeFirstLetter(formatDate(session.startTime, 'dddd DD MMM'))}</div>
            </div>
            <div className={styles.block}>
              <div className={styles.label}>{t('home.time')}:</div>
              <div>{formatTimeRange(session.startTime, session.endTime)}</div>
            </div>
            <div className={styles.block}>
              <div className={styles.label}>
                {isAdvisor ? t('home.customer') : t('home.advisor')}:
              </div>
              <div>
                {session.displayName}
                {session.displayCompanyName ? `, ${session.displayCompanyName}` : ''}
              </div>
            </div>
          </div>
        </ModalHeader>
      )}
      <ModalContent>
        {!billingInfoOpen && (
          <div className={styles.content}>{!isRescheduledScreen && sessionDetailsScreen}</div>
        )}
        {isRescheduledScreen && !billingInfoOpen && renderRescheduleScreen()}
        {billingInfoOpen && <RescheduleInfoDialog />}
      </ModalContent>
    </ModalDialog>
  );
}
