import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks';
import { getApplicationSettings } from '@/common/services';
import { CenteredSpinner } from '@/common/components';

interface ApplicationState {
  appSettings: API.PublicApplicationSettingsDto;
  loading: boolean;
}

const DEFAULT_SETTINGS: API.PublicApplicationSettingsDto = {
  suggestionMonthDiff: 6,
};

export const ApplicationContext = React.createContext<ApplicationState>({
  appSettings: DEFAULT_SETTINGS,
  loading: false,
});

const ApplicationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { t } = useTranslation('common');
  const { user } = useAuth();
  const { data, isLoading } = useQuery({
    queryFn: () => {
      return getApplicationSettings();
    },
    enabled: !!user,
  });

  if (isLoading) {
    return (
      <div style={{ height: 200 }}>
        <CenteredSpinner message={t('share.navigating')} />
      </div>
    );
  }

  return (
    <ApplicationContext.Provider
      value={{
        appSettings: data as API.PublicApplicationSettingsDto,
        loading: isLoading,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
