import { lazy } from 'react';
import { HomeOutlined, TableOutlined } from '@ant-design/icons';
import { APP_ROUTES, UserRole } from '@constants';
import HomePage from '@pages/home';

const ALL_ROLES = Object.values(UserRole);

export type AccessRoute = {
  menu: string;
  path: string;
  allowRoles: UserRole[];
  icon?: React.FunctionComponent;
  element: React.FunctionComponent;
  submenu?: boolean;
  hidden?: boolean;
};

export const PRIVATE_ROUTES: AccessRoute[] = [
  {
    menu: 'Home',
    path: APP_ROUTES.HOME,
    allowRoles: ALL_ROLES,
    element: HomePage,
    hidden: true,
  },
  {
    menu: 'My sessions',
    path: APP_ROUTES.MY_SESSIONS,
    allowRoles: [UserRole.User, UserRole.Advisor],
    element: lazy(() => import('@/pages/my-sessions')),
    icon: TableOutlined,
  },
  {
    menu: 'Manage company',
    path: APP_ROUTES.COMPANY,
    allowRoles: [UserRole.DecisionMaker, UserRole.Admin],
    element: lazy(() => import('@/pages/company')),
    icon: HomeOutlined,
  },
  {
    menu: 'Advisor availability',
    path: APP_ROUTES.ADVISOR_AVAILABILITY,
    allowRoles: [UserRole.User],
    element: lazy(() => import('@/pages/advisor-availability')),
    icon: TableOutlined,
  },
  // {
  //   menu: 'Profile',
  //   path: APP_ROUTES.PROFILE,
  //   allowRoles: ALL_ROLES,
  //   element: lazy(() => import('@pages/profile')),
  //   icon: UserOutlined,
  //   submenu: true,
  // },
];
