import { useAuth } from '@/hooks';
import { RoleSwitcherButton } from '../role-switcher-button';
import { LogoutButton } from '../logout-button';
import styles from './styles.module.scss';

// type SvgIconProps = {
//   width?: number;
//   height?: number;
//   className?: string;
// };

// const ProfileIcon = ({ className }: SvgIconProps) => {
//   return (
//     <svg
//       className={className}
//       width="36"
//       height="38"
//       viewBox="0 0 36 38"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M5.485 37.957H30.13c3.644 0 5.466-1.281 5.466-3.844 0-1.481-.434-2.97-1.301-4.464-.855-1.508-2.07-2.896-3.644-4.164-1.575-1.268-3.45-2.289-5.626-3.063-2.175-.774-4.584-1.161-7.227-1.161-2.629 0-5.031.387-7.207 1.16-2.175.775-4.057 1.796-5.645 3.064-1.575 1.268-2.796 2.656-3.664 4.164C.427 31.144 0 32.632 0 34.113c0 2.563 1.828 3.844 5.485 3.844zm-.14-4.244c-.2 0-.34-.04-.42-.12-.067-.067-.1-.174-.1-.32 0-.668.287-1.442.86-2.323.574-.88 1.415-1.735 2.523-2.562 1.121-.828 2.482-1.515 4.084-2.062 1.615-.547 3.45-.821 5.505-.821 2.069 0 3.904.274 5.506.82 1.615.548 2.976 1.235 4.084 2.063 1.107.827 1.948 1.681 2.522 2.562.587.881.881 1.655.881 2.323 0 .146-.04.253-.12.32-.08.08-.214.12-.4.12H5.345zm12.452-14.514c1.669 0 3.177-.434 4.525-1.302 1.348-.88 2.422-2.055 3.223-3.523.814-1.468 1.221-3.096 1.221-4.885 0-1.748-.407-3.336-1.221-4.764a9.352 9.352 0 0 0-3.243-3.444C20.954.427 19.452 0 17.797 0a8.212 8.212 0 0 0-4.504 1.301c-1.348.854-2.43 2.002-3.243 3.444-.801 1.441-1.201 3.036-1.201 4.784 0 1.775.4 3.397 1.2 4.865.815 1.455 1.896 2.623 3.244 3.504 1.348.867 2.85 1.3 4.504 1.3zm0-4.244a4.003 4.003 0 0 1-2.282-.701c-.694-.467-1.254-1.108-1.681-1.922-.428-.827-.641-1.768-.641-2.823 0-1.014.207-1.915.62-2.702.428-.801.988-1.428 1.682-1.882a4.183 4.183 0 0 1 2.302-.68c.841 0 1.609.226 2.303.68.707.44 1.267 1.06 1.681 1.862.427.787.64 1.688.64 2.702 0 1.055-.213 1.995-.64 2.823-.413.828-.967 1.475-1.661 1.942-.694.467-1.469.7-2.323.7z"
//         fill="#fff"
//       />
//     </svg>
//   );
// };

// const ArrowRightIcon = () => {
//   return (
//     <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path
//         d="M6.32 5.5a.755.755 0 0 0-.249-.565L1.235.211A.687.687 0 0 0 .733 0a.735.735 0 0 0-.637.354A.701.701 0 0 0 0 .72c0 .199.074.373.223.522l4.675 4.556v-.602L.223 9.758A.715.715 0 0 0 0 10.28a.701.701 0 0 0 .36.624.735.735 0 0 0 .373.096c.099 0 .19-.018.276-.053a.65.65 0 0 0 .226-.158l4.836-4.724A.792.792 0 0 0 6.32 5.5z"
//         fill="#fff"
//       />
//     </svg>
//   );
// };

// const { useBreakpoint } = Grid;

export function ProfileItem() {
  const { user } = useAuth();

  if (!user) {
    return;
  }

  return (
    <div className={styles.newWrapper}>
      <RoleSwitcherButton arrowColor="white" />
      <LogoutButton className={styles.logout} />
    </div>
  );

  // return (
  //   <div className={styles.wrapper}>
  //     <div>
  //       <Space size="middle" align="center">
  //         <ProfileIcon className={styles.profileIcon} />
  //         <div className={styles.profileSection}>
  //           <div className={styles.name}>{user?.fullName}</div>
  //           <Space className={styles.icon}>
  //             <div
  //               onClick={() => {
  //                 navigate(APP_ROUTES.PROFILE);
  //               }}
  //             >
  //               <Space align="center" size="small" style={{ marginBottom: 4 }}>
  //                 <span>{t('header.navigations.settings')}</span>
  //                 <ArrowRightIcon />
  //               </Space>
  //             </div>

  //             {!screens.xs && <RoleSwitcher arrowColor="white" />}
  //           </Space>
  //         </div>
  //       </Space>
  //     </div>
  //   </div>
  // );
}
