import { Grid } from 'antd';
import { HEADER_SELECTOR_ID } from '@/constants';
import { MobileUI } from './components/mobile-ui';
import { DesktopUI } from './components/desktop-ui';
const { useBreakpoint } = Grid;

export default function Header() {
  const screens = useBreakpoint();
  const isSmallScreen = screens.xs || (screens.sm && !screens.lg && !screens.xl && !screens.xxl);

  return (
    <div id={HEADER_SELECTOR_ID}>
      {isSmallScreen && <MobileUI />}

      {!isSmallScreen && <DesktopUI />}
    </div>
  );
}
