import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'rooks';
import { Card } from '@/common/components';
import {
  BulkDeleteAvailabilityBookingSlotRequest,
  BulkUpdateAvailabilityBookingSlotRequest,
} from '@/common/api/wisoryApiClient';
import { api, refetch } from '@/common/utils';
import { API_QUERIES, DATE_TIME_FORMAT } from '@/constants';
import { AvailablitiyForm } from './components/availablity-form';
import styles from './styles.module.scss';
import { AdvisorSlot } from './types';

export const AdvisorAvailablitiy = () => {
  const { t } = useTranslation(['common']);
  const [checkedItems, setCheckedItems] = useImmer<Record<string, AdvisorSlot>>({});
  const onBeforeUnload = useMemo(() => {
    return (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = 'Your changes will be lost!';
      return e.returnValue;
    };
  }, []);

  const getSubmitParams = useCallback(() => {
    const { newItems, deletedItems } = Object.keys(checkedItems).reduce(
      (acc, key) => {
        const item = checkedItems[key];

        if (item?.isDeleted) {
          acc.deletedItems.push(item);
        } else if (item?.isNew) {
          acc.newItems.push(item);
        }

        return acc;
      },
      {
        newItems: [] as AdvisorSlot[], // BulkUpdateAvailabilityBookingSlotRequest[],
        deletedItems: [] as AdvisorSlot[], // BulkDeleteAvailabilityBookingSlotRequest[],
      }
    );

    return {
      newItems,
      deletedItems,
    };
  }, [checkedItems]);

  const clean = (deletedItems: AdvisorSlot[], newItems: AdvisorSlot[]) => {
    setCheckedItems((draft) => {
      // Remove deleted items out of the checked items
      deletedItems.forEach((it) => {
        delete draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)];
      });

      // New items now become normal items as they are saved to server.
      newItems.forEach((it) => {
        draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)].isNew = false;
      });
    });
  };

  const submitAvailablityAction = useMutation({
    mutationFn: (params: { deletedItems: AdvisorSlot[]; newItems: AdvisorSlot[] }) => {
      return api.baseAPI.userProfile
        .updateAppointmentAvailabilitySlotsForUserProfile({
          bulkUpdateAvailabilityBookingSlotsRequests:
            params.newItems.map<BulkUpdateAvailabilityBookingSlotRequest>((it) => ({
              startTime: dayjs(it.startTime).toISOString(),
            })),
          bulkDeleteAvailabilityBookingSlotsRequests:
            params.deletedItems.map<BulkDeleteAvailabilityBookingSlotRequest>((it) => ({
              appointmentAvailabilityId: it.id,
            })),
        })
        .then(() => {
          clean(params.deletedItems, params.newItems);
          refetch([API_QUERIES.ADVISOR_GET_AVAILABILITY_SLOTS]);
        });
    },
  });

  const submit = useDebounce(() => {
    const { deletedItems, newItems } = getSubmitParams();

    if (!deletedItems.length && !newItems.length) {
      return;
    }

    submitAvailablityAction.mutate({ deletedItems, newItems });
  }, 500);

  useEffect(() => {
    const { deletedItems, newItems } = getSubmitParams();

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    if (!deletedItems.length && !newItems.length) {
      window.removeEventListener('beforeunload', onBeforeUnload);
    } else {
      window.addEventListener('beforeunload', onBeforeUnload);
      submit();
    }
  }, [checkedItems, getSubmitParams, onBeforeUnload, submit]);

  return (
    <>
      <Card
        title={t('home.availabilityTitle')}
        className={classNames(styles.wrapper, { [styles.editMode]: true })}
      >
        <AvailablitiyForm
          editMode={true}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      </Card>
    </>
  );
};
