import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { API_QUERIES, APP_ROUTES } from '@/constants';
import {
  PendingSessionViewModel,
  UpcomingSessionViewModel,
  api,
  createUpcomingSessionData,
} from '@/common/utils';
import { CenteredSpinner, Title } from '@/common/components';
import { BookingCalendarEventStatus } from '@/common/api/wisoryApiClient';
import { DetailsSessionDialog } from '../details-session-dialog';
import { PendingSessionDialog } from '../pending-session-dialog';
import { UpcomingSessionItem } from '../upcoming-session-item';
import styles from './styles.module.scss';

type Props = {
  isAdvisor?: boolean;
};

export const UpcomingSessionBlock = ({ isAdvisor }: Props) => {
  const { t } = useTranslation('common');
  const [selectedSession, setSelectedSesson] = useState<UpcomingSessionViewModel>();
  const [selectedRequest, setSelectedRequest] = useState<PendingSessionViewModel>();
  const today = dayjs().set('hours', 0).set('minutes', 0).set('seconds', 0);
  const sessionQuery = useQuery({
    queryKey: [API_QUERIES.UPCOMING_SESSIONS],
    queryFn: () =>
      api.baseAPI.userProfile.getCurrentUserProfileSessions({
        periodStartDate: today.toISOString(),
        pageSize: 100,
      }),
  });
  const interestRequestsQuery = useQuery({
    queryKey: isAdvisor
      ? [API_QUERIES.ADVISOR_GET_INTEREST_REQUEST]
      : [API_QUERIES.CUSTOMER_GET_INTEREST_REQUEST],
    queryFn: () =>
      isAdvisor
        ? api.pingpongApi.advisorProfile.getAdvisorInterestRequests()
        : api.pingpongApi.customerProfile.getCustomerInterestRequests(),
  });

  const data = useMemo(() => {
    if (!(sessionQuery.isFetched && interestRequestsQuery.isFetched)) {
      return {
        items: [],
        count: 0,
      };
    }
    const result = createUpcomingSessionData({
      // @ts-ignore Wrong Type of swagger generator
      sessions: sessionQuery.data?.items || [],
      requests: interestRequestsQuery.data?.items || [],
      isAdvisor: isAdvisor || false,
    });

    return {
      items: [...result.confirmedSessions, ...result.cancelledSessions, ...result.pendingSessions],
      count: result.confirmedSessions.length + result.pendingSessions.length, // Count only confirmed and pending sessions
    };
  }, [
    interestRequestsQuery.data?.items,
    interestRequestsQuery.isFetched,
    isAdvisor,
    // @ts-ignore
    sessionQuery.data?.items,
    sessionQuery.isFetched,
  ]);

  let content = <div />;

  if (sessionQuery.isLoading || interestRequestsQuery.isLoading) {
    content = (
      <div style={{ height: 100 }}>
        <CenteredSpinner />
      </div>
    );
  } else {
    if (!data.items.length) {
      content = (
        <div className={styles.emptyUpcomingSessions}>
          <span>{t('home.noUpcomingSession')}</span>
        </div>
      );
    } else {
      content = (
        <>
          {data.items.map((session, index) => {
            if ('request' in session) {
              return (
                <UpcomingSessionItem
                  key={session.id}
                  item={{
                    displayName: session.displayName,
                    displayCompanyName: session.displayCompanyName,
                    originalItem: session,
                  }}
                  status={BookingCalendarEventStatus.PENDING}
                  onSelect={setSelectedRequest}
                  isAdvisor={isAdvisor || false}
                />
              );
            }
            return (
              <UpcomingSessionItem
                key={session.id}
                item={{
                  displayName: session.displayName,
                  displayCompanyName: session.displayCompanyName,
                  startTime: session.startTime,
                  originalItem: session,
                }}
                status={session.status}
                onSelect={setSelectedSesson}
                className={classNames({
                  [styles.firstItem]: index === 0,
                })}
                isAdvisor={isAdvisor || false}
              />
            );
          })}
        </>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <Title className={styles.title} type="large">
        {t('home.upcomingSessionsTitle')} {data.count > 0 && <span>{`(${data.count})`}</span>}
      </Title>
      <div className={styles.bottomLine} />
      <div className={styles.contentWrapper}>{content}</div>
      {selectedSession && (
        <DetailsSessionDialog
          session={selectedSession}
          onCancel={() => setSelectedSesson(undefined)}
          isAdvisor={isAdvisor!}
        />
      )}
      {selectedRequest && (
        <PendingSessionDialog
          request={selectedRequest.request}
          onCancel={() => setSelectedRequest(undefined)}
          isAdvisor={isAdvisor}
        />
      )}
      <div className={styles.sessionHistoryWrapper}>
        <Link className={styles.link} to={APP_ROUTES.MY_SESSIONS}>
          {t('sessionHistory.title')}
        </Link>
      </div>
    </div>
  );
};
