import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SuggestedSlots, SuggestedParams, BookingModal } from '@/common/components';
import { SuggestedPeriods } from '@/common/components/suggested-periods';
import { InboxViewModel, InterestRequestType } from '@/common/utils';

type Props = {
  title: string;
  item: InboxViewModel;
  suggest: (params: SuggestedNewInterestParams) => Promise<any>;
  onCancel: VoidFunction;
  onDecline: VoidFunction;
  onSuccess: VoidFunction;
  isAdvisor: boolean;
};

export type SuggestedNewInterestParams = {
  requestId: string;
} & SuggestedParams;

export function ProposeNewTime({
  title,
  item,
  isAdvisor,
  suggest,
  onCancel,
  onDecline,
  onSuccess,
}: Props) {
  const { t } = useTranslation('common');
  const defaultProposalMessage = useMemo(() => {
    const params = {
      userFirstName: item.request.customerFirstName,
      userFullName: item.request.customerFullName,
      userCompany: item.request.companyName,
      advisorFullName: item.request.advisorFullName,
      advisorFirstName: item.request.advisorFirstName,
    };
    if (item.type === InterestRequestType.NEW_INTEREST_REQUEST) {
      return t('home.interestForm.advisor.counterNewInterestRequest', params);
    }

    return t(
      `home.interestForm.${isAdvisor ? 'advisor' : 'customer'}.counterProposalRequest`,
      params
    );
  }, [
    isAdvisor,
    item.request.advisorFirstName,
    item.request.advisorFullName,
    item.request.companyName,
    item.request.customerFirstName,
    item.request.customerFullName,
    item.type,
    t,
  ]);
  const isRescheduled = item.type === InterestRequestType.RESCHEDULING_REQUEST;

  const content = useMemo(
    () =>
      isAdvisor ? (
        <SuggestedSlots
          bookingId={item.request.bookingId || 0}
          title={title}
          defaultMessage={defaultProposalMessage}
          onCancel={onDecline}
          onSuccess={onSuccess}
          sendSuggestions={(props) => {
            return suggest({
              requestId: item.request.id!,
              message: props.message,
              suggestedSlots: props.suggestedSlots,
            });
          }}
          isRescheduled={isRescheduled}
        />
      ) : (
        <SuggestedPeriods
          title={title}
          onCancel={onDecline}
          onSuccess={onSuccess}
          defaultMessage={defaultProposalMessage}
          sendSuggestions={(props) => {
            return suggest({
              requestId: item.request.id!,
              message: props.message,
              suggestedSlots: props.suggestedSlots,
            });
          }}
          isRescheduled={isRescheduled}
        />
      ),
    [
      defaultProposalMessage,
      isAdvisor,
      isRescheduled,
      item.request.bookingId,
      item.request.id,
      onDecline,
      onSuccess,
      suggest,
      title,
    ]
  );

  const description = useMemo(() => {
    const type = isAdvisor ? 'advisor' : 'customer';

    return t(`home.interestForm.${type}.proposeNewTime`);
  }, [isAdvisor, t]);

  return (
    <BookingModal
      title={
        isRescheduled
          ? t('home.interestForm.proposeNewTime.rescheduleTitle')
          : t('home.interestForm.proposeNewTime.counterProposalTitle')
      }
      subtitle={t('home.inboxBlock.bookingId', { bookingId: item.bookingId })}
      description={description}
      data={item}
      onCancel={onCancel}
    >
      {content}
    </BookingModal>
  );
}
