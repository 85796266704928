import dayjs, { Dayjs } from 'dayjs';
import i18n from '@/i18n';

export const ALLOW_MINUTES = [0, 15, 30, 45];
export const ALLOW_HOURS = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
export const LAST_HOUR_IN_CALENDAR = 23;
export const LAST_HOUR_IN_DATETIME_PICKER = 22;

export enum TIMESPAN {
  ALL_DAY = 'ALL_DAY',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export const TIMESPAN_HOURS = {
  [TIMESPAN.MORNING]: {
    startHours: 8,
    endHours: 12,
  },
  [TIMESPAN.AFTERNOON]: {
    startHours: 13,
    endHours: 17,
  },
  [TIMESPAN.EVENING]: {
    startHours: 17,
    endHours: 19,
  },
  [TIMESPAN.ALL_DAY]: {
    startHours: 8,
    endHours: 17,
  },
};

export const ALL_DAY_HOURS = Array(24)
  .fill(null)
  .map((_, i) => i);

export const mergeDateAndTime = (date: Dayjs, time: Dayjs) => {
  return date
    .set('hours', time.get('hours'))
    .set('minutes', time.get('minutes'))
    .set('seconds', 0)
    .set('milliseconds', 0);
};

export const getDisabledHoursByPeriod = (greetingTime: TIMESPAN) => {
  if (greetingTime === TIMESPAN.ALL_DAY) {
    return ALL_DAY_HOURS.filter((v) => {
      return v < 7 || v > 19;
    });
  }

  return ALL_DAY_HOURS.filter((v) => {
    return v < TIMESPAN_HOURS[greetingTime].startHours || v > TIMESPAN_HOURS[greetingTime].endHours;
  });
};

export const formatDate = (str: any, format: string) => {
  const obj = dayjs(str);

  if (!obj.isValid()) {
    return '-';
  }

  return obj.format(format);
};

export const formatTimeRange = (from: any, to: any) => {
  const fromObj = dayjs(from);
  const toObj = dayjs(to);

  if (!fromObj.isValid() || !toObj.isValid()) {
    return '-';
  }

  return `${fromObj.format('HH:mm')} - ${toObj.format('HH:mm')}`;
};

export const toFormattedDate = (date: string) => {
  if (!date) return '-';
  return dayjs(date).format('YYYY-MM-DD HH:mm [GMT]Z');
};

export const removeSeconds = (date: Dayjs) => {
  return date.set('seconds', 0).set('milliseconds', 0);
};

export const removeMinutesAndSeconds = (v: Dayjs) => {
  return v.set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
};

export const formatWeekRange = (day: Dayjs) => {
  const monday = day.startOf('weeks').day(1);
  const friday = day.startOf('weeks').day(5);
  return `${monday.format('MMMM DD')} - ${friday.format('MMMM DD, YYYY')}`;
};

export const getGreetingTime2 = (startTimeStr?: string, endTimeStr?: string) => {
  const startTime = dayjs(startTimeStr);
  const endTime = dayjs(endTimeStr);

  const timespan =
    Object.keys(TIMESPAN_HOURS).find((key) => {
      const range = TIMESPAN_HOURS[key as keyof typeof TIMESPAN_HOURS];

      return startTime.get('hours') >= range.startHours && endTime.get('hours') <= range.endHours;
    }) || TIMESPAN.ALL_DAY;

  const ranges = TIMESPAN_HOURS[timespan as keyof typeof TIMESPAN_HOURS];

  // Timezone issues, temporarily support Swedish
  const startLabel = removeMinutesAndSeconds(startTime.set('hours', ranges.startHours)).format(
    'HH:mm'
  );
  const endLabel = removeMinutesAndSeconds(endTime.set('hours', ranges.endHours)).format('HH:mm');
  const timespanLabel = i18n.getFixedT(null, 'common')(`date.${timespan}`);

  return `${timespanLabel} (${startLabel} - ${endLabel})`;
};

export const getGreetingTime = (startTimeStr?: string, endTimeStr?: string) => {
  const startTime = dayjs(startTimeStr);
  const endTime = dayjs(endTimeStr);

  const timespan =
    Object.keys(TIMESPAN_HOURS).find((key) => {
      const range = TIMESPAN_HOURS[key as keyof typeof TIMESPAN_HOURS];

      return startTime.get('hours') >= range.startHours && endTime.get('hours') <= range.endHours;
    }) || TIMESPAN.ALL_DAY;

  return timespan as TIMESPAN;
};

export const getDateRangeFromGreetingTime = (selectedDate: Dayjs, timespan: TIMESPAN) => {
  const allDayRanges = TIMESPAN_HOURS[TIMESPAN.ALL_DAY];
  let startTime = removeMinutesAndSeconds(selectedDate.set('hour', allDayRanges.startHours));
  let endTime = removeMinutesAndSeconds(selectedDate.set('hour', allDayRanges.endHours));
  const hours = TIMESPAN_HOURS[timespan];

  startTime = selectedDate.set('hour', hours.startHours).set('minute', 0).set('second', 0);
  endTime = selectedDate.set('hour', hours.endHours).set('minute', 0).set('second', 0);

  return {
    startTime,
    endTime,
  };
};

export const isSameOrAfter = (a: Dayjs, b: Dayjs) => {
  return a.isAfter(b) || a.isSame(b);
};

export const isSameOrBefore = (a: Dayjs, b: Dayjs) => {
  return a.isBefore(b) || a.isSame(b);
};

export const isAfter = (a: Dayjs, b: Dayjs) => {
  return a.isAfter(b);
};

export const isBefore = (a: Dayjs, b: Dayjs) => {
  return a.isBefore(b);
};

export const isWeekend = (a: Dayjs) => {
  const weekends = [0, 6];

  return weekends.includes(a.day());
};

export const disableBookingDate = (
  date: Dayjs,
  options: { isRescheduled: boolean; suggestionMonthDiff: number }
) => {
  const now = dayjs();
  const defaultFilter = isSameOrBefore(date, now) || isWeekend(date);

  if (options.isRescheduled) {
    const maxScheduleDate = now.add(options.suggestionMonthDiff, 'months');
    return defaultFilter || date.isAfter(maxScheduleDate);
  }

  return defaultFilter;
};
