import { Button, Col, Row } from 'antd';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useImmer } from 'use-immer';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { random } from 'lodash-es';
import {
  AppointmentAvailabilityDto,
  BookingCalendarEventDto,
  BulkDeleteAvailabilityBookingSlotRequest,
  BulkUpdateAvailabilityBookingSlotRequest,
} from '@/common/api/wisoryApiClient';
import { InterestRequestView, InterestRequestStatus } from '@/common/api/pingpongApiClient';
import { api, getTwoISOLanguage, produceAdvisorSlots, showNotification } from '@/common/utils';
import { CenteredSpinner } from '@/common/components';
import { API_QUERIES, DATE_TIME_FORMAT } from '@/constants';
import { ArrowLeftIcon, ArrowRightIcon } from '@/common/components/icons';
import { ALLOW_MINUTES } from '@/common/utils/date';
import { capitalizeFirstLetter } from '@/common/utils/string';
import { AdvisorSlot, ProposalSlot } from '../../../../types';
import styles from './styles.module.scss';
import { BlockHourItem } from './components/block-hour-item';

type Props = {
  firstDayOfTheWeek: Dayjs;
  setFirstDayOfTheWeek: (v: Dayjs) => void;
};

function getRange(d: Dayjs) {
  const startDate = d.set('hours', 0).set('minutes', 0).set('seconds', 0);
  const endDate = d.set('hours', 23).set('minutes', 0).set('seconds', 0);

  let time = startDate;
  const range = [time];
  do {
    time = time.add(60, 'minutes');
    range.push(time);
  } while (time.isBefore(endDate));

  return range;
}

export const MyAvailability = ({ firstDayOfTheWeek, setFirstDayOfTheWeek }: Props) => {
  const today = useMemo(() => dayjs(), []);
  const contentElem = useRef<HTMLDivElement>(null);
  const isScrolled = useRef(false);
  const { t } = useTranslation('common');
  const { fromDate, toDate } = useMemo(() => {
    const fromDate = firstDayOfTheWeek
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0);
    const toDate = firstDayOfTheWeek
      .endOf('week')
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0);

    return { fromDate, toDate };
  }, [firstDayOfTheWeek]);

  const [submittingItems, setSubmittingItems] = useImmer<Record<string, boolean>>({});
  const [checkedItems, setCheckedItems] = useImmer<Record<string, AdvisorSlot>>({});

  const clean = useCallback(
    (deletedItems: AdvisorSlot[], newItems: AdvisorSlot[]) => {
      setCheckedItems((draft) => {
        // Remove deleted items out of the checked items
        deletedItems.forEach((it) => {
          delete draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)];
        });

        // New items now become normal items as they are saved to server.
        newItems.forEach((it) => {
          draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)].isNew = false;
        });
      });
    },
    [setCheckedItems]
  );

  // const restore = useCallback(
  //   (deletedItems: AdvisorSlot[], newItems: AdvisorSlot[]) => {
  //     setCheckedItems((draft) => {
  //       // Remove deleted items out of the checked items
  //       deletedItems.forEach((it) => {
  //         draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)] = it;
  //       });

  //       // New items now become normal items as they are saved to server.
  //       newItems.forEach((it) => {
  //         delete draft[it.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)];
  //       });
  //     });
  //   },
  //   [setCheckedItems]
  // );

  const submitAvailablityAction = useMutation({
    mutationFn: (params: { deletedItems: AdvisorSlot[]; newItems: AdvisorSlot[] }) => {
      clean(params.deletedItems, params.newItems);

      return api.baseAPI.userProfile.updateAppointmentAvailabilitySlotsForUserProfile({
        bulkUpdateAvailabilityBookingSlotsRequests:
          params.newItems.map<BulkUpdateAvailabilityBookingSlotRequest>((it) => ({
            startTime: dayjs(it.startTime).toISOString(),
          })),
        bulkDeleteAvailabilityBookingSlotsRequests:
          params.deletedItems.map<BulkDeleteAvailabilityBookingSlotRequest>((it) => ({
            appointmentAvailabilityId: it.id,
          })),
        locale: getTwoISOLanguage(),
      });
      // .then(
      //   () => {
      //     // refetch([API_QUERIES.ADVISOR_GET_AVAILABILITY_SLOTS]);
      //   },
      //   () => {
      //     restore(params.deletedItems, params.newItems);
      //   }
      // );
    },
  });

  const mapDataToState = useCallback(
    (params: {
      availableSlots: AppointmentAvailabilityDto[];
      bookedSlots: BookingCalendarEventDto[];
      proposalSlots: InterestRequestView[];
    }) => {
      const allData = produceAdvisorSlots({
        availableSlots: params.availableSlots,
        bookedSlots: params.bookedSlots,
        proposalSlots: params.proposalSlots,
      });

      const viewModel = allData.reduce((acc, item) => {
        acc[item.startTime.format(DATE_TIME_FORMAT.DATE_AND_TIME)] = item;
        return acc;
      }, {} as Record<string, AdvisorSlot>);

      setCheckedItems(() => {
        return {
          ...viewModel,
        };
      });
    },
    [setCheckedItems]
  );

  const {
    isLoading: isRequestsFetching,
    isFetched: isRequestsFetched,
    data: requests,
  } = useQuery({
    queryKey: [API_QUERIES.ADVISOR_GET_INTEREST_REQUEST],
    queryFn: () => api.pingpongApi.advisorProfile.getAdvisorInterestRequests(),
  });

  const {
    isLoading: isAvailabilitiesFetching,
    isFetched: isAvailabilitiesFetched,
    data: availabilities,
  } = useQuery({
    queryKey: [
      API_QUERIES.ADVISOR_GET_AVAILABILITY_SLOTS,
      fromDate.format(DATE_TIME_FORMAT.DATE_ONLY),
      toDate.format(DATE_TIME_FORMAT.DATE_ONLY),
    ],
    queryFn: () => {
      return api.baseAPI.userProfile.getAppointmentAvailabilitySlotsForUserProfile({
        periodFromDate: fromDate.toISOString(),
        periodToDate: toDate.toISOString(),
      });
    },
  });

  const proposalSlots = useMemo(() => {
    return (requests?.items || [])
      .filter((s) => {
        return s.status === InterestRequestStatus.ADVISOR_COUNTER_PROPOSAL;
      })
      .reduce((acc, item) => {
        return acc.concat(
          (item.timeSlots || []).map<ProposalSlot>((s) => {
            return {
              id: random(),
              startTime: dayjs(s.startTime),
              endTime: dayjs(s.endTime),
              customerName: item.customerFullName || '',
              customerCompanyName: item.companyName || '',
              advisorName: item.advisorFullName || '',
            };
          })
        );
      }, [] as ProposalSlot[]);
  }, [requests?.items]);

  useEffect(() => {
    if (isRequestsFetched && isAvailabilitiesFetched) {
      mapDataToState({
        availableSlots: availabilities?.appointmentAvailabilities || [],
        bookedSlots: availabilities?.bookedAppointmentAvailabilities || [],
        proposalSlots: requests?.items || [],
      });

      if (contentElem.current && !isScrolled.current) {
        contentElem.current.scroll({ top: 324 });
        isScrolled.current = true;
      }
    }
  }, [
    availabilities?.appointmentAvailabilities,
    availabilities?.bookedAppointmentAvailabilities,
    isAvailabilitiesFetched,
    isRequestsFetched,
    mapDataToState,
    requests?.items,
  ]);

  const weekNumber = firstDayOfTheWeek.week();
  const defaultRanges = useMemo(() => getRange(firstDayOfTheWeek), [firstDayOfTheWeek]);
  const daysInWeek = useMemo(() => {
    return [1, 2, 3, 4, 5].map((n) => firstDayOfTheWeek.day(n));
  }, [firstDayOfTheWeek]);
  const isLoading = isAvailabilitiesFetching || isRequestsFetching;

  const getOverlappingProposalSlot = (slot: Dayjs) => {
    const startTime = slot;
    const endTime = slot.add(60, 'minutes');

    const existing = proposalSlots.find((s) => {
      return (
        startTime.isBetween(s.startTime, s.endTime) ||
        endTime.isBetween(s.startTime, s.endTime) ||
        startTime.isSame(s.startTime) ||
        endTime.isSame(s.endTime)
      );
    });

    return existing;
  };

  const onSelect = async (slot: Dayjs) => {
    const proposal = getOverlappingProposalSlot(slot);

    if (proposal) {
      showNotification({
        type: 'error',
        message: t('home.availabilityOverlappingMsg', {
          customerName: proposal.customerName,
          customerCompanyName: proposal.customerCompanyName,
        }),
      });
      return;
    }

    const viewModel: AdvisorSlot = {
      id: Math.random(),
      startTime: slot,
      endTime: slot.add(60, 'minutes'),
      isDisabled: false,
      isDeleted: false,
      isNew: true,
      type: 'slot',
    };

    setSubmittingItems((draft) => {
      draft[viewModel.id.toString()] = true;
    });

    setCheckedItems((draft) => {
      draft[slot.format(DATE_TIME_FORMAT.DATE_AND_TIME)] = viewModel;
    });

    try {
      const data = await submitAvailablityAction.mutateAsync({
        newItems: [viewModel],
        deletedItems: [],
      });

      setCheckedItems((draft) => {
        draft[slot.format(DATE_TIME_FORMAT.DATE_AND_TIME)] = {
          ...viewModel,
          id: data[0].publicAppointmentAvailabilityView?.id || viewModel.id,
        };
      });
    } catch {
      setCheckedItems((draft) => {
        delete draft[slot.format(DATE_TIME_FORMAT.DATE_AND_TIME)];
      });
    } finally {
      setSubmittingItems((draft) => {
        draft[viewModel.id.toString()] = false;
      });
    }
  };

  const findSelectedItem = (t: Dayjs) => {
    const mappedToDayJS = ALLOW_MINUTES.map((m) => t.set('minutes', m));
    let selectedItem;

    for (let index = 0; index < mappedToDayJS.length; index++) {
      const item = checkedItems[mappedToDayJS[index].format(DATE_TIME_FORMAT.DATE_AND_TIME)];

      if (item && !item.isDeleted) {
        selectedItem = item;
      }
    }

    return selectedItem;
  };

  const weekTitle = useMemo(() => {
    const today = dayjs();
    const currentWeekNumber = today.week();
    const month = capitalizeFirstLetter(firstDayOfTheWeek.format('MMMM'));

    if (weekNumber === currentWeekNumber) {
      return `${month} (${t('home.advisorAvailability.thisWeek')})`;
    }

    if (weekNumber === currentWeekNumber + 1) {
      return `${month} (${t('home.advisorAvailability.nextWeek')})`;
    }

    return `${month} (${t('home.advisorAvailability.week')} ${weekNumber})`;
  }, [firstDayOfTheWeek, t, weekNumber]);

  const onRemove = useCallback(
    async (s: Dayjs) => {
      const viewModel = checkedItems[s.format(DATE_TIME_FORMAT.DATE_AND_TIME)];

      setCheckedItems((draft) => {
        draft[s.format(DATE_TIME_FORMAT.DATE_AND_TIME)].isDeleted = true;
      });

      try {
        await submitAvailablityAction.mutateAsync({ newItems: [], deletedItems: [viewModel] });
      } catch {
        setCheckedItems((draft) => {
          delete draft[s.format(DATE_TIME_FORMAT.DATE_AND_TIME)];
        });
      } finally {
        setSubmittingItems((draft) => {
          draft[viewModel.id.toString()] = false;
        });
      }
    },
    [checkedItems, setCheckedItems, setSubmittingItems, submitAvailablityAction]
  );

  return (
    <div className={styles.wrapper} data-dd-privacy="mask">
      <Row className={styles.navigationWrapper} align="middle" justify="space-between">
        <Col>
          <Button
            className={styles.arrowButton}
            onClick={() => {
              setFirstDayOfTheWeek(firstDayOfTheWeek.subtract(7, 'days'));
            }}
            icon={<ArrowLeftIcon color="#1e7451" />}
          />
        </Col>
        <Col>
          <div className={styles.weekTitle}>{weekTitle}</div>
        </Col>
        <Col>
          <Button
            className={styles.arrowButton}
            onClick={() => {
              setFirstDayOfTheWeek(firstDayOfTheWeek.add(7, 'days'));
            }}
            icon={<ArrowRightIcon />}
          />
        </Col>
      </Row>
      <div className={styles.headingWrapper}>
        <div className={styles.emptyHourLabel} />
        {daysInWeek.map((d) => {
          const key = d.format('DD ddd');
          return (
            <div className={styles.headingItem} key={key}>
              {key}
            </div>
          );
        })}
      </div>
      <Row className={styles.contentWrapper} ref={contentElem}>
        <div className={styles.timeColumn}>
          {defaultRanges.map((d) => {
            return (
              <div
                className={styles.timeItemWrapper}
                key={d.format(DATE_TIME_FORMAT.DATE_AND_TIME)}
              >
                <div className={styles.timeItem}>
                  <span className={styles.hourText}>
                    {d.format(DATE_TIME_FORMAT.HOUR_AND_MINUTE)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        {isLoading && (
          <div className={styles.loadingWrapper}>
            <CenteredSpinner size="large" />
          </div>
        )}

        {!isLoading && (
          <div className={styles.slotColumn}>
            {daysInWeek.map((d) => {
              const ranges = getRange(d);

              return (
                <div
                  key={d.format(DATE_TIME_FORMAT.DATE_AND_TIME)}
                  className={styles.slotColumnItem}
                >
                  {ranges.map((t, index) => {
                    const currentSlot = findSelectedItem(t);
                    const prevSlot = index > 0 ? findSelectedItem(ranges[index - 1]) : undefined;

                    const nextSlot =
                      index < ranges.length - 1 ? findSelectedItem(ranges[index + 1]) : undefined;

                    return (
                      <BlockHourItem
                        key={t.format(DATE_TIME_FORMAT.DATE_AND_TIME)}
                        isFirst={index === 0}
                        isLast={index === ranges.length - 1}
                        time={t}
                        currentSlot={currentSlot}
                        prevSlot={prevSlot}
                        nextSlot={nextSlot}
                        saving={submittingItems[currentSlot ? currentSlot.id.toString() : '']}
                        editMode={true}
                        onRemove={onRemove}
                        onSelect={onSelect}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </Row>
      <div className={styles.captionWrapper}>
        <div className={styles.captionItem}>
          <div className={styles.availabilityCaption} /> {t('home.yourAvailabilityCaption')}
        </div>
        <div className={styles.captionItem}>
          <div className={styles.confirmedSession} /> {t('home.confirmedSessionCaption')}
        </div>
        <div className={styles.captionItem}>
          <div className={styles.proposalSession} /> {t('home.proposalSessionCaption')}
        </div>
      </div>
      <div className={styles.captionNotesWrapper}>
        {t('home.advisorAvailability.captionNote', {
          timezone: `${today.format('z')} ${dayjs.tz.guess()}`,
        })}
      </div>
    </div>
  );
};
