import { Button, Result } from 'antd';
import { useAuth } from '@/hooks';

export default function AccessDenied() {
  const { signOut, goToLoginPage } = useAuth();

  return (
    <Result
      status="404"
      title="You don't have permission to access this page"
      extra={
        <Button
          onClick={() => {
            signOut();
            goToLoginPage();
          }}
          type="primary"
        >
          Login with another account
        </Button>
      }
    />
  );
}
