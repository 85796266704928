import { changeLanguage } from 'i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { setCookie } from '@wisory-international-ab/login-frontend';
import EnglandFlag from '@/images/flag_en.png';
import SwedenFlag from '@/images/flag_sv.png';
import { LANGUAGES } from '@/constants';
import { getCurrentLanguage } from '@/common/utils';
import styles from './styles.module.scss';

export function LanguageSwitcher({ className = '' }: { className?: string }) {
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  const isSwedish = currentLanguage === LANGUAGES.SWEDISH;

  return (
    <div
      className={classNames(styles.wrapper, className)}
      role="banner"
      onClick={() => {
        const nextLanguage = isSwedish ? LANGUAGES.ENGLISH : LANGUAGES.SWEDISH;
        setCookie('i18next', nextLanguage);
        setCurrentLanguage(nextLanguage);
        changeLanguage(nextLanguage);
        dayjs.updateLocale(nextLanguage, {
          weekStart: 1,
        });
        dayjs.locale(nextLanguage);
        //window.location.reload();
      }}
    >
      <img src={isSwedish ? EnglandFlag : SwedenFlag} alt="" />
    </div>
  );
}
