import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxViewModel, showNotification } from '@/common/utils';
import { AcceptInterestParams, SubmitInterest } from '../submit-interest';
import { ProposeNewTime, SuggestedNewInterestParams } from '../propose-new-time';

export enum STEPS {
  SHOW_INTEREST_OPTIONS,
  PROPOSE_NEW_TIME,
}

type Props = {
  item: InboxViewModel;
  isAdvisor: boolean;
  defaultStep?: STEPS;
  refetch: () => Promise<any>;
  accept: (params: AcceptInterestParams) => Promise<any>;
  suggest: (params: SuggestedNewInterestParams) => Promise<any>;
  onCancel: VoidFunction;
};

export function InterestForm({
  item,
  isAdvisor,
  defaultStep = STEPS.SHOW_INTEREST_OPTIONS,
  accept,
  suggest,
  refetch,
  onCancel,
}: Props) {
  const { t } = useTranslation('common');
  const [step, setSteps] = useState<STEPS>(defaultStep);

  let content = <></>;

  const goBackToInterestOptions = () => {
    setSteps(STEPS.SHOW_INTEREST_OPTIONS);
  };

  const closeDialogAndRefetch = () => {
    onCancel();
    refetch();
  };

  if (step === STEPS.SHOW_INTEREST_OPTIONS) {
    content = (
      <SubmitInterest
        isAdvisor={isAdvisor}
        accept={accept}
        item={item}
        onCancel={onCancel}
        onDecline={() => {
          setSteps(STEPS.PROPOSE_NEW_TIME);
        }}
        onSuccess={() => {
          showNotification({
            type: 'success',
            message: t('home.interestForm.submitInterest.success'),
          });
          closeDialogAndRefetch();
        }}
      />
    );
  } else if (step === STEPS.PROPOSE_NEW_TIME) {
    content = (
      <ProposeNewTime
        suggest={suggest}
        title={isAdvisor ? t('home.interestForm.advisorTitle') : t('home.interestForm.userTitle')}
        item={item}
        onCancel={onCancel}
        onDecline={goBackToInterestOptions}
        onSuccess={() => {
          showNotification({ type: 'success', message: t('home.interestForm.notification') });
          closeDialogAndRefetch();
        }}
        isAdvisor={isAdvisor}
      />
    );
  }

  return content;
}
