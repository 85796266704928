import { Popover, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UserRole } from '@/constants';
import { useAuth } from '@/hooks';
import styles from './styles.module.scss';

const ROLES_TO_FRIENDLY_NAME = {
  [UserRole.User]: 'User',
  [UserRole.Advisor]: 'Advisor',
  [UserRole.Admin]: 'Admin',
  [UserRole.DecisionMaker]: 'Decision maker',
  [UserRole.ContentSubscriber]: 'Content subscriber',
  [UserRole.Backoffice]: 'Backoffice',
};

const ArrowDownIcon = ({ color }: { color: string }) => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.16 6.66a.755.755 0 0 0 .565-.249l4.724-4.835a.687.687 0 0 0 .21-.503.734.734 0 0 0-.353-.637.701.701 0 0 0-.366-.096.715.715 0 0 0-.522.224L5.862 5.238h.602L1.9.564A.715.715 0 0 0 1.38.34.701.701 0 0 0 .756.7a.735.735 0 0 0-.096.373.652.652 0 0 0 .211.503L5.595 6.41c.17.166.358.249.565.249z"
        fill={color}
      />
    </svg>
  );
};

type Props = {
  arrowColor?: 'black' | 'white';
  className?: string;
};

export function RoleSwitcherButton({ className = '', arrowColor = 'black' }: Props) {
  const { t } = useTranslation('common');
  const { user, selectedRole, setSelectedRole } = useAuth();
  // We don't display additional role selector for subscriber as they don't have any different actions
  const displayedRoles = (user?.roles || []).filter((r) => r !== UserRole.ContentSubscriber);

  if (displayedRoles.length <= 1) {
    return null;
  }

  return (
    <Popover
      overlayClassName={styles.popoverWrapper}
      placement="bottom"
      content={
        <div className={styles.settingsWrapper}>
          {displayedRoles.map((r) => {
            return (
              <div
                key={r}
                role="button"
                className={styles.linkWrapper}
                onClick={() => {
                  setSelectedRole(r);
                }}
              >
                <Button type="link" className={styles.link}>
                  {ROLES_TO_FRIENDLY_NAME[r]}
                </Button>
              </div>
            );
          })}
        </div>
      }
      trigger="hover"
    >
      <div role="button" className={classNames(styles.item, className)}>
        <Space align="center" size="small">
          <span>
            {t('header.navigations.role', {
              role: ROLES_TO_FRIENDLY_NAME[selectedRole!],
            })}
          </span>
          <ArrowDownIcon color={arrowColor} />
        </Space>
      </div>
    </Popover>
  );
}
