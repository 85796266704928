import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo, useState } from 'react';
import { Grid, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { AdvisorSlot } from '@/pages/home/components/advisor-availablity/types';
import { DATE_TIME_FORMAT, SESSION_DURATION } from '@/constants';
import { RemoveIcon } from '@/common/components/icons';
import {
  ALLOW_MINUTES,
  LAST_HOUR_IN_CALENDAR,
  formatDate,
  formatTimeRange,
} from '@/common/utils/date';
import { UpcomingSessionViewModel, capitalizeFirstLetter } from '@/common/utils';
import { DetailsSessionDialog } from '@/pages/home/components/details-session-dialog';
import { ConfirmationModal } from '@/common/components/confirmation-modal';
import { PendingSessionDialog } from '@/pages/home/components/pending-session-dialog';
import { InterestRequestView } from '@/common/api/pingpongApiClient';
import variables from '../../variables.module.scss';
import styles from './styles.module.scss';

type Props = {
  time: Dayjs;
  currentSlot?: AdvisorSlot;
  /** This property will help to make sure users cannot select a quarter that connect with prev slot
   * Ex: a user select a slot from 7:15 - 8:15, in UI we should prevent user to select a slot from 8:00 - 9:00
   * They can select a slot from 8:30 - 9:30
   */
  prevSlot?: AdvisorSlot;
  nextSlot?: AdvisorSlot;
  editMode: boolean;
  isFirst: boolean;
  isLast: boolean;
  saving: boolean;
  onSelect: (time: Dayjs) => void;
  onRemove: (time: Dayjs) => void;
};

// const SLOT_HEIGHT = Number.parseFloat(variables.slotHeight);
const QUARTER_MINUTES_HEIGHT = Number.parseFloat(variables.quarterHeight);

const { useBreakpoint } = Grid;

export function BlockHourItem({
  time,
  editMode,
  currentSlot,
  prevSlot,
  nextSlot,
  isFirst,
  isLast,
  saving,
  onRemove,
  onSelect,
}: Props) {
  const today = useMemo(() => dayjs(), []);
  const screens = useBreakpoint();
  const { t } = useTranslation('common');
  const currentHour = time.get('hours');
  const [selectedSession, setSelectedSession] = useState<UpcomingSessionViewModel>();
  const [selectedRequest, setSelectedRequest] = useState<InterestRequestView>();
  const [currentPlaceholder, setCurrentTime] = useState<number | undefined>();
  const isPast = time.isBefore(today);
  const isLastPastHour =
    today.set('minutes', 0).format(DATE_TIME_FORMAT.DATE_AND_TIME) ===
    time.format(DATE_TIME_FORMAT.DATE_AND_TIME);
  const showLastDisableItemIndicator = isPast && isLastPastHour;

  const isPrevSlotOverlapSelectingItem = (m: number) => {
    if (!prevSlot) {
      return false;
    }

    const timeToCompare = time.set('minutes', m);
    const maxTime = time.set('hours', LAST_HOUR_IN_CALENDAR).set('minutes', 0);

    return prevSlot.endTime.isAfter(timeToCompare) || prevSlot.endTime.isAfter(maxTime);
  };

  const isNextSlotOverlapSelectingItem = (m: number) => {
    if (!nextSlot) {
      return false;
    }

    const timeToCompare = time.set('minutes', m).add(SESSION_DURATION, 'minutes');

    return timeToCompare.isAfter(nextSlot.startTime);
  };

  const canAddOnlyOneSlot = () => {
    if (prevSlot && nextSlot) {
      const diff =
        nextSlot.startTime.set('seconds', 0).diff(prevSlot.endTime.set('seconds', 0)) || 0;

      return diff / 60000 === SESSION_DURATION;
    }

    return false;
  };

  const ranges = ALLOW_MINUTES.slice(0);

  return (
    <>
      <div
        className={classNames(styles.slotItem, {
          [styles.disabled]: isPast,
          [styles.indicator]: showLastDisableItemIndicator,
          [styles.hasTopBorderRadius]: isFirst,
          [styles.hasBottomBorderRadius]: isLast,
        })}
        role="button"
        onMouseLeave={() => {
          setCurrentTime(undefined);
        }}
      >
        {ranges.map((minutes) => {
          const startTime = time.add(minutes, 'minutes');
          const format = screens.xs
            ? startTime.format('HH.mm')
            : startTime.format('HH.mm') +
              '-' +
              startTime.add(SESSION_DURATION, 'minutes').format('HH.mm');
          const selectedMinutes = currentSlot?.startTime.get('minutes');
          const selected =
            selectedMinutes === minutes &&
            (currentSlot?.startTime.isAfter(today) || currentSlot?.startTime.isSame(today)); // The slot that is past should not be display as selected
          const booked = currentSlot?.isBooked;
          const proposal = currentSlot?.type === 'proposal';
          const value = time.set('minutes', minutes).set('seconds', 0);
          const isNotActive = minutes !== currentPlaceholder && !selected;

          return (
            <React.Fragment key={minutes}>
              <div
                className={styles.quarterMinuteWrapper}
                onClick={(e) => {
                  const selectedMinutes = document
                    .elementsFromPoint(e.clientX, e.clientY)
                    .find((e) => e?.className.includes('timeBlock'));
                  const minutesToAdd = parseInt(selectedMinutes?.getAttribute('data-time') || '0');
                  const timeToAdd = time.set('minutes', minutesToAdd).set('seconds', 0);

                  if (selectedMinutes && !selected) {
                    onSelect(timeToAdd);
                  }
                }}
                onMouseEnter={(e) => {
                  if (!editMode || currentSlot || isPast) {
                    return;
                  }

                  const minuteAtMouseHover = time.set('minutes', minutes);

                  if (canAddOnlyOneSlot()) {
                    console.log(
                      'canAddOnlyOneSlot',
                      minuteAtMouseHover.format(DATE_TIME_FORMAT.HOUR_AND_MINUTE)
                    );
                    setCurrentTime(nextSlot?.startTime.get('minutes'));
                  }

                  if (
                    isPrevSlotOverlapSelectingItem(minutes) ||
                    isNextSlotOverlapSelectingItem(minutes)
                  ) {
                    return;
                  }

                  if (minutes !== currentPlaceholder) {
                    setCurrentTime(currentHour === LAST_HOUR_IN_CALENDAR ? 0 : minutes);
                  }
                }}
              >
                <div
                  className={classNames(styles.placeholder, {
                    [styles.selected]: selected,
                    [styles.booked]: booked,
                    [styles.proposal]: proposal,
                    [styles.hidden]: isNotActive && !selected,
                  })}
                  onMouseMove={(e) => {
                    // Mousemove event won't trigger with the space has AdvisorSlot
                    if (selected || isNotActive) {
                      return;
                    }

                    const rect = e.currentTarget.getBoundingClientRect();
                    //   const x = e.clientX - rect.left; //x position within the element.
                    const y = e.clientY - rect.top; //y position within the element.

                    if (currentHour === LAST_HOUR_IN_CALENDAR || currentSlot || !editMode) {
                      return;
                    }

                    // When users move their mouse, we get the "y" and compare it with the default height
                    // ex: 8:00 has height 7.5px, 8:15 will have height from 7.5 to 15px, if the y is 10px the 8:15 will be selected.
                    if (y > QUARTER_MINUTES_HEIGHT) {
                      const newQuarterMinutes = ranges.find((m) => y < m);

                      if (newQuarterMinutes) {
                        setCurrentTime(newQuarterMinutes);
                      }
                    }
                  }}
                >
                  {selected && !booked && !proposal && editMode && (
                    <div className={classNames(styles.selectedBlock)}>
                      <span className={styles.info}>{format}</span>
                      <span
                        title="Remove a slot"
                        className={styles.removeBtn}
                        role="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            title: null,
                            footer: null,
                            modalRender() {
                              return (
                                <ConfirmationModal
                                  title={t('home.advisorAvailability.removeSlot.title')}
                                  onConfirm={() => {
                                    onRemove(value);
                                    Modal.destroyAll();
                                  }}
                                  onCancel={() => {
                                    Modal.destroyAll();
                                  }}
                                  cancelText={t('form.cancel')}
                                  confirmText={t('form.remove')}
                                >
                                  <Space direction="vertical">
                                    <div>
                                      <b>{t('home.date')}:</b>{' '}
                                      {capitalizeFirstLetter(formatDate(value, 'dddd DD MMM'))}
                                    </div>
                                    <div>
                                      <b>{t('home.time')}:</b>{' '}
                                      {formatTimeRange(value, value.add(60, 'minutes'))}
                                    </div>
                                  </Space>
                                  <div className={styles.confirmModalMessage}>
                                    {t('home.advisorAvailability.removeSlot.description')}
                                  </div>
                                </ConfirmationModal>
                              );
                            },
                          });
                        }}
                      >
                        <RemoveIcon />
                      </span>
                    </div>
                  )}

                  {(!selected || booked || proposal || !editMode) && (
                    <div
                      className={classNames(styles.normalBlock, 'timeBlock', {
                        [styles.clickable]: booked || proposal,
                      })}
                      data-time={minutes}
                      onClick={() => {
                        if (booked) {
                          setSelectedSession(currentSlot.session);
                        }

                        if (proposal) {
                          setSelectedRequest(currentSlot.request);
                        }
                      }}
                    >
                      {format}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {showLastDisableItemIndicator && <div className={styles.lastDisabledItem} />}
      {selectedSession && (
        <DetailsSessionDialog
          session={selectedSession}
          onCancel={() => setSelectedSession(undefined)}
          isAdvisor={true}
        />
      )}

      {selectedRequest && (
        <PendingSessionDialog
          request={selectedRequest}
          onCancel={() => setSelectedRequest(undefined)}
          isAdvisor={true}
        />
      )}
    </>
  );
}
