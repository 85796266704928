import { Row, Col, Typography, Input, Button, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useImmer } from 'use-immer';
import { AppointmentStatus } from '@/common/api/wisoryApiClient';
import { getOverlappingSlots } from '@/common/services';
import { isDuplicatedArray, notEmpty, showNotification } from '@/common/utils';
import { disableBookingDate, removeSeconds, TIMESPAN } from '@/common/utils/date';
import { DATE_TIME_FORMAT, SESSION_DURATION } from '@/constants';
import { capitalizeFirstLetter } from '@/common/utils/string';
import useApplicationSettings from '@/hooks/useApplicationSettings';
import { DateTimePicker } from '../datetime-picker';
import { ArrowLeftIcon } from '../icons';
import styles from './styles.module.scss';

type Suggestions = (Dayjs | null)[];

export type SuggestedParams = {
  suggestedSlots: { startTime: string; endTime: string }[];
  message: string;
};

type Props = {
  bookingId: number;
  title: string;
  defaultMessage: string;
  isRescheduled?: boolean;
  sendSuggestions: (props: SuggestedParams) => Promise<any>;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
};

export function SuggestedSlots({
  title,
  isRescheduled,
  defaultMessage,
  bookingId,
  sendSuggestions,
  onCancel,
  onSuccess,
}: Props) {
  const { appSettings } = useApplicationSettings();
  const { t } = useTranslation(['common']);
  const [userMessage, setUserMessage] = useState(defaultMessage);
  const [suggestions, setSuggestions] = useImmer<Suggestions>([null, null, null]);
  const hasDuplicatedSlots = useMemo(() => {
    return isDuplicatedArray(
      suggestions.filter(notEmpty).map((s) => s?.format(DATE_TIME_FORMAT.DATE_AND_TIME))
    );
  }, [suggestions]);
  const [overlappingSlots, setOverlappingSlots] = useImmer<(string | undefined)[]>([
    undefined,
    undefined,
    undefined,
  ]);
  const isSelectedAtLeastTwoOptions = useMemo(
    () => suggestions.filter((s) => s).length >= 2,
    [suggestions]
  );
  const errorSlots = overlappingSlots.filter((s) => s);

  const checkOverlappingSlots = useMutation(
    (data: { startTime: string; endTime: string; excludeBookingId: number }) => {
      return getOverlappingSlots(data);
    }
  );

  const suggestSlots = useMutation(
    () => {
      return sendSuggestions({
        message: userMessage,
        suggestedSlots: suggestions.filter(notEmpty).map((s) => {
          const timeWithoutSeconds = removeSeconds(s);
          return {
            startTime: timeWithoutSeconds.toISOString(),
            endTime: timeWithoutSeconds.clone().add(SESSION_DURATION, 'minutes').toISOString(),
          };
        }),
      });
    },
    {
      onSuccess,
    }
  );

  const onSuggestClick = async () => {
    if (!isSelectedAtLeastTwoOptions) {
      showNotification({ type: 'error', message: t('home.interestForm.slotValidation') });
    } else {
      suggestSlots.mutate();
    }
  };

  return (
    <>
      <Row className={styles.messageForm}>
        <Typography.Text className={styles.label}>
          {t('home.interestForm.messageToUser')}
        </Typography.Text>
        <Input.TextArea
          className={styles.textArea}
          value={userMessage}
          onChange={(e) => {
            setUserMessage(e.target.value);
          }}
          placeholder={t('home.interestForm.messageToUserPlaceholder')}
        />
      </Row>
      <Row className={styles.selectTimeRow}>
        <div className={styles.title}>{title}</div>
        {suggestions.map((s, index) => {
          return (
            <Row align="middle" className={styles.suggestTimeRow} key={index} gutter={[12, 12]}>
              <Col xs={24} md={6}>
                <Typography.Text className={styles.label}>
                  {t('home.interestForm.suggestionLabel')} {index + 1}
                </Typography.Text>
              </Col>
              <Col xs={24} md={18}>
                <DateTimePicker
                  className={styles.datepicker}
                  allowTime={TIMESPAN.ALL_DAY}
                  value={suggestions[index]}
                  disabledTooltip={
                    isRescheduled ? (
                      <Trans
                        t={t}
                        i18nKey="message.rescheduleWarningMessage"
                        values={{ months: appSettings.suggestionMonthDiff }}
                        components={{
                          b: <b />,
                          a: (
                            <a className={styles.anchor} href="mailto:happy@wisory.se">
                              {t('share.contactUs')}
                            </a>
                          ),
                        }}
                      />
                    ) : undefined
                  }
                  disabledDate={(date) =>
                    disableBookingDate(date, {
                      isRescheduled: isRescheduled || false,
                      suggestionMonthDiff: appSettings.suggestionMonthDiff,
                    })
                  }
                  onChange={async (v) => {
                    if (!v) {
                      setOverlappingSlots((draft) => {
                        draft[index] = undefined;
                      });
                    } else {
                      const startTime = v.toISOString();
                      const endTime = v.clone().add(SESSION_DURATION, 'minutes').toISOString();
                      const existing = suggestions.some((s) => {
                        return (
                          s?.format(DATE_TIME_FORMAT.DATE_AND_TIME) ===
                          v.format(DATE_TIME_FORMAT.DATE_AND_TIME)
                        );
                      });

                      if (existing) {
                        setSuggestions((draft) => {
                          draft[index] = v;
                        });

                        showNotification({
                          type: 'error',
                          message: t('home.interestForm.duplicatedTime'),
                        });

                        return;
                      }

                      const data = await checkOverlappingSlots.mutateAsync({
                        startTime,
                        endTime,
                        excludeBookingId: bookingId,
                      });
                      const bookedSessions = data.filter(
                        (s) => s.status !== AppointmentStatus.AVAILABLE
                      );

                      if (bookedSessions.length > 0) {
                        const session = data[0];
                        const date = capitalizeFirstLetter(
                          `${dayjs(session.startTime).format('dddd DD MMM')}`
                        );
                        const time = dayjs(session.startTime).format('HH:mm');
                        const customerName = session.customerName;
                        const msg = t('home.interestForm.advisorSuggest.overlappingMsg', {
                          time,
                          date,
                          customerCompany: session.customerCompanyName,
                          customerName,
                        });
                        showNotification({
                          type: 'error',
                          message: msg,
                        });

                        setOverlappingSlots((draft) => {
                          draft[index] = msg;
                        });
                      } else {
                        setOverlappingSlots((draft) => {
                          draft[index] = undefined;
                        });
                      }
                    }

                    setSuggestions((draft) => {
                      draft[index] = v;
                    });
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </Row>
      <div className={styles.buttonWrapper}>
        <Button className={styles.backBtn} onClick={onCancel}>
          <Space>
            <ArrowLeftIcon />
            {t('form.back')}
          </Space>
        </Button>
        <Button
          className={styles.submitBtn}
          type="primary"
          onClick={() => {
            onSuggestClick();
          }}
          disabled={errorSlots.length > 0 || !isSelectedAtLeastTwoOptions || hasDuplicatedSlots}
          loading={suggestSlots.isLoading || checkOverlappingSlots.isLoading}
        >
          {t('form.sendSuggestions')}
        </Button>
      </div>
      {errorSlots.length > 0 && (
        <div className={styles.overlappingMsgWrapper}>
          <div className={styles.overlappingMsg}>{errorSlots[0]}</div>
        </div>
      )}
      {!isSelectedAtLeastTwoOptions && (
        <div className={styles.overlappingMsgWrapper}>
          <div className={styles.overlappingMsg}>{t('home.interestForm.slotValidation')}</div>
        </div>
      )}
      {hasDuplicatedSlots && (
        <div className={styles.overlappingMsgWrapper}>
          <div className={styles.overlappingMsg}>{t('home.interestForm.duplicatedTime')}</div>
        </div>
      )}
    </>
  );
}
