import { useMemo, useEffect } from 'react';
import { GLOBAL_TITLE_SELECTOR_ID, HEADER_SELECTOR_ID } from '@/constants';

export const useGlobalTitle = (title: string) => {
  const targetNode = useMemo(() => document.getElementById(HEADER_SELECTOR_ID), []);

  useEffect(() => {
    const setTitle = () => {
      const titleWrapper = document.getElementById(GLOBAL_TITLE_SELECTOR_ID);

      if (titleWrapper) {
        titleWrapper.innerText = title;
      }
    };
    // Callback function to execute when mutations are observed
    const callback = (mutations: MutationRecord[]): void => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          // When screen is resized and header is toggled between MobileUI and DesktopUI
          setTimeout(() => {
            setTitle();
          }, 0);
        }
      }
    };

    const observer = new MutationObserver(callback);

    if (targetNode) {
      observer.observe(targetNode, { attributes: true, childList: true, subtree: true });
    }

    setTitle();

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
