import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { getAppConfigurations } from '../utils/environment-variables';

const { environment, appVersion } = getAppConfigurations();

const defaultParams = {
  applicationId: '06edc93c-680a-4501-99a4-753d573f02f6',
  clientToken: 'pubd848d6460f116872b37bccd4c771bfb2',
  site: 'datadoghq.eu',
  service: 'profile-frontend',
  env: environment,
  version: appVersion,
};

function setup(): void {
  if (environment === 'local') {
    return;
  }

  datadogRum.init({
    ...defaultParams,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    ...defaultParams,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

function logInfo(msg: string, context?: Record<string, unknown>): void {
  if (environment === 'local') {
    return;
  }

  datadogLogs.logger.info(msg, context);
}

function trackUser(user: any) {
  if (environment === 'local') {
    return;
  }

  datadogRum.setUser(user);
}

export { setup, logInfo, trackUser };
