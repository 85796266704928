import dayjs, { Dayjs } from 'dayjs';
import { random } from 'lodash-es';
import { AdvisorSlot } from '@/pages/home/components/advisor-availablity/types';
import {
  InterestRequestView,
  InterestRequestStatus,
  TimeSlotRequestModel,
} from '../api/pingpongApiClient';
import {
  AppointmentAvailabilityDto,
  BookingCalendarEventDto,
  BookingCalendarEventStatus,
} from '../api/wisoryApiClient';
import { formatDate } from './date';

export type PendingSessionViewModel = {
  id: string;
  displayName: string;
  displayCompanyName: string;
  request: InterestRequestView;
};

export type UpcomingSessionViewModel = {
  id: any;
  startTime: Dayjs;
  endTime: Dayjs;
  displayName: string;
  displayCompanyName: string;
  description: string;
  advisorName: string;
  advisorFirstName: string;
  customerFirstName: string;
  customerFullName: string;
  customerCompanyName: string;
  purpose: string;
  status: BookingCalendarEventStatus;
  videoSessionUrl?: string;
};

export enum InterestRequestType {
  NEW_INTEREST_REQUEST,
  NEW_TIME_REQUEST,
  RESCHEDULING_REQUEST,
}

export type InboxViewModel = {
  id: any;
  displayName: string;
  displayCompanyName: string;
  bookingId: string | number;
  type: InterestRequestType;
  request: InterestRequestView;
};

const sortTimeSlots = (timeSlots: TimeSlotRequestModel[]) => {
  return [...timeSlots].sort((a, b) => {
    const aTime = dayjs(a.startTime).toDate();
    const bTime = dayjs(b.startTime).toDate();

    return aTime.getTime() - bTime.getTime();
  });
};

export function createUpcomingViewModel(s: BookingCalendarEventDto, isAdvisor: boolean) {
  const customerName = s.invoice ? s.invoice.recipientInfo?.customerName || '' : s.customerName!;
  const customerCompanyName = s.invoice
    ? s.invoice.recipientInfo?.customerCompany || ''
    : s.companyName!;

  return {
    id: s.id!,
    displayName: isAdvisor ? customerName : s.advisorName!,
    displayCompanyName: isAdvisor ? customerCompanyName : '',
    advisorName: s.advisorName || '',
    advisorFirstName: s.advisorFirstName || '',
    customerFirstName: s.customerFirstName || '',
    customerFullName: s.customerName || '',
    customerCompanyName: s.companyName || '',
    startTime: dayjs(s.startTime),
    endTime: dayjs(s.endTime),
    description: s.shortDescription || '',
    purpose: s.reason || '',
    status: s.status!,
    isRefunded: s.isRefunded,
    videoSessionUrl: s.videoLink || undefined,
  };
}

export function createSessionHistoryData(params: {
  sessions: BookingCalendarEventDto[];
  isAdvisor: boolean;
}) {
  const groups = {} as Record<string, UpcomingSessionViewModel[]>;

  params.sessions
    .filter((s) => {
      // If session is cancelled or late cancelled and it is refunded, don't show it in History
      if (
        [BookingCalendarEventStatus.CANCELLED, BookingCalendarEventStatus.LATE_CANCELLED].includes(
          s.status!
        ) &&
        s.isRefunded
      )
        return false;

      return (
        [
          BookingCalendarEventStatus.CANCELLED,
          BookingCalendarEventStatus.VERIFIED,
          BookingCalendarEventStatus.PERFORMED,
          BookingCalendarEventStatus.LATE_CANCELLED,
          BookingCalendarEventStatus.NO_SHOW,
          BookingCalendarEventStatus.INVOICED,
        ].includes(s.status!) &&
        s.startTime &&
        s.endTime
      );
    })
    .forEach((s) => {
      const key = formatDate(s.startTime, 'MMM YYYY');
      const session: UpcomingSessionViewModel = createUpcomingViewModel(s, params.isAdvisor);

      if (groups[key]) {
        groups[key].push(session);
      } else {
        groups[key] = [session];
      }
    });

  return groups;
}

export function createUpcomingSessionData(params: {
  sessions: BookingCalendarEventDto[];
  requests: InterestRequestView[];
  isAdvisor: boolean;
}) {
  const pendingSessions = params.requests
    .filter((s) => {
      if (params.isAdvisor) {
        return (
          s.status === InterestRequestStatus.ADVISOR_COUNTER_PROPOSAL ||
          s.status === InterestRequestStatus.ADMIN_SENT_PROPOSAL_TO_USER
        );
      }

      return (
        s.status === InterestRequestStatus.CUSTOMER_COUNTER_PROPOSAL ||
        s.status === InterestRequestStatus.CUSTOMER_SUGGESTED_TIMESLOTS ||
        s.status === InterestRequestStatus.ADMIN_SENT_PROPOSAL_TO_ADVISOR
      );
    })
    .map<PendingSessionViewModel>((s) => {
      return {
        id: s.id!,
        displayName: params.isAdvisor ? s.customerFullName! : s.advisorFullName!,
        displayCompanyName: params.isAdvisor ? s.companyName! : '',
        bookingId: s.bookingId!,
        request: {
          ...s,
          timeSlots: sortTimeSlots(s.timeSlots || []),
        },
      };
    });

  const { confirmedSessions, cancelledSessions } = params.sessions.reduce(
    (acc, s) => {
      const session: UpcomingSessionViewModel = createUpcomingViewModel(s, params.isAdvisor);

      if (
        [BookingCalendarEventStatus.CANCELLED, BookingCalendarEventStatus.LATE_CANCELLED].includes(
          session.status
        )
      ) {
        acc.cancelledSessions.push(session);
      } else if (session.status === BookingCalendarEventStatus.VERIFIED) {
        acc.confirmedSessions.push(session);
      }

      return acc;
    },
    {
      confirmedSessions: [] as UpcomingSessionViewModel[],
      cancelledSessions: [] as UpcomingSessionViewModel[],
    }
  );

  confirmedSessions.sort((a, b) => (dayjs(a.startTime).isAfter(dayjs(b.startTime)) ? 1 : -1));

  return {
    confirmedSessions,
    cancelledSessions,
    pendingSessions,
  };
}

export function createInboxData(params: { requests: InterestRequestView[]; isAdvisor: boolean }) {
  const { pingpongRequests, rescheduleRequests, standardsRequests } = params.requests
    .filter((s) =>
      params.isAdvisor
        ? [
            InterestRequestStatus.CUSTOMER_BOOK_A_SLOT,
            InterestRequestStatus.CUSTOMER_COUNTER_PROPOSAL,
            InterestRequestStatus.CUSTOMER_SUGGESTED_TIMESLOTS,
            InterestRequestStatus.ADMIN_SENT_PROPOSAL_TO_ADVISOR,
          ].includes(s.status!)
        : [
            InterestRequestStatus.ADVISOR_COUNTER_PROPOSAL,
            InterestRequestStatus.ADVISOR_BOOK_A_SLOT,
            InterestRequestStatus.ADMIN_SENT_PROPOSAL_TO_USER,
          ].includes(s.status!)
    )
    .reduce(
      (acc, s) => {
        const item = {
          id: s.id,
          displayName: params.isAdvisor ? s.customerFullName! : s.advisorFullName!,
          displayCompanyName: params.isAdvisor ? s.companyName! : '',
          bookingId: s.bookingId || '',
          request: {
            ...s,
            timeSlots: sortTimeSlots(s.timeSlots || []),
          },
        };
        if (s.isRescheduled) {
          acc.rescheduleRequests.push({
            ...item,
            type: InterestRequestType.RESCHEDULING_REQUEST,
          });
        } else if (s.pingPongCount! <= 1) {
          acc.standardsRequests.push({
            ...item,
            type: InterestRequestType.NEW_INTEREST_REQUEST,
          });
        } else if (s.pingPongCount! > 1) {
          acc.pingpongRequests.push({
            ...item,
            type: InterestRequestType.NEW_TIME_REQUEST,
          });
        }

        return acc;
      },
      {
        rescheduleRequests: [] as InboxViewModel[],
        standardsRequests: [] as InboxViewModel[],
        pingpongRequests: [] as InboxViewModel[],
      }
    );

  return {
    pingpongRequests,
    rescheduleRequests,
    standardsRequests,
  };
}

export const getCustomerName = (s: any) => {
  return s.invoice?.recipientInfo?.customerName || s.customerName;
};

export const produceAdvisorSlots = (params: {
  availableSlots: AppointmentAvailabilityDto[];
  bookedSlots: BookingCalendarEventDto[];
  proposalSlots: InterestRequestView[];
}) => {
  const advisorAvailableSlots = params.availableSlots.map<AdvisorSlot>((d) => {
    return {
      id: d.id!,
      startTime: dayjs(d.startTime),
      endTime: dayjs(d.endTime),
      isDisabled: false,
      isDeleted: false,
      isBooked: false,
      isNew: false,
      type: 'slot',
    };
  });

  const advisorBookedSlots = params.bookedSlots
    .filter(
      (s) =>
        s.status === BookingCalendarEventStatus.VERIFIED ||
        s.status === BookingCalendarEventStatus.PENDING
    )
    .map<AdvisorSlot>((d) => {
      return {
        id: d.id!,
        startTime: dayjs(d.startTime),
        endTime: dayjs(d.endTime),
        isDisabled: true,
        isDeleted: false,
        isNew: false,
        isBooked: true,
        type: 'slot',
        session: {
          id: d.id!,
          displayName: d.customerName!,
          displayCompanyName: d.companyName!,
          advisorName: d.advisorName || '',
          advisorFirstName: d.advisorFirstName || '',
          customerFirstName: d.customerFirstName || '',
          customerFullName: d.customerName || '',
          customerCompanyName: d.companyName || '',
          startTime: dayjs(d.startTime),
          endTime: dayjs(d.endTime),
          description: d.shortDescription || '',
          purpose: d.reason || '',
          status: d.status!,
          videoSessionUrl: d.videoLink || undefined,
        },
      };
    });

  const proposalSlots = params.proposalSlots
    .filter((s) => {
      return s.status === InterestRequestStatus.ADVISOR_COUNTER_PROPOSAL;
    })
    .reduce((acc, item) => {
      return acc.concat(
        (item.timeSlots || []).map<AdvisorSlot>((s) => {
          return {
            id: random(),
            startTime: dayjs(s.startTime),
            endTime: dayjs(s.endTime),
            isDisabled: true,
            isDeleted: false,
            isNew: false,
            isBooked: false,
            type: 'proposal',
            tooltip: `Slot has been proposed to ${item.customerFullName}`,
            request: item,
          };
        })
      );
    }, [] as AdvisorSlot[]);

  return [...advisorAvailableSlots, ...advisorBookedSlots, ...proposalSlots];
};
