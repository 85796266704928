import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getDomain } from '@wisory-international-ab/login-frontend';
import common_en from './en/common.json';
import common_sv from './sv/common.json';

const i18n = use(initReactI18next).use(LanguageDetector);
const domain = getDomain(window.location.href);

i18n.init({
  detection: {
    order: ['cookie', 'navigator'],
    cookieDomain: domain,
  },
  interpolation: { escapeValue: false }, // React already does escaping
  fallbackLng: 'en',
  supportedLngs: ['en', 'sv'],
  resources: {
    en: {
      common: common_en,
    },
    sv: { common: common_sv },
  },
  debug: process.env.NODE_ENV === 'development',
});

export default i18n;
