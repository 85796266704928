import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useState } from 'react';
import { InboxViewModel } from '@/common/utils';
import { UserIcon } from '../../../../common/components/icons';
import { ModalContent, ModalDialog, ModalHeader } from '../../../../common/components/modal';
import styles from './styles.module.scss';
import { RescheduleInfoDialog } from './reschedule-info';

type Props = {
  title: string;
  subtitle?: string;
  description?: string;
  data: InboxViewModel;
  onCancel: VoidFunction;
};

export function BookingModal({
  title,
  subtitle,
  description,
  data,
  children,
  onCancel,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation('common');
  const [billingInfoOpen, setBillingInfoOpen] = useState<boolean>();

  return (
    <ModalDialog>
      {billingInfoOpen && (
        <ModalHeader
          onCancel={() => {
            setBillingInfoOpen(false);
          }}
        >
          <div className={styles.ruleForBookingTitle}>{t('rescheduleRulesDialog.action')}</div>
        </ModalHeader>
      )}
      {!billingInfoOpen && (
        <ModalHeader
          onCancel={() => {
            if (billingInfoOpen) {
              setBillingInfoOpen(false);
            } else {
              onCancel();
            }
          }}
        >
          <div className={styles.titleRow}>
            <div>
              <div className={styles.title}>{title}</div>
              {subtitle && (
                <div className={styles.subtitle}>
                  {subtitle}
                  <Button
                    className={styles.infoLink}
                    type="link"
                    onClick={() => setBillingInfoOpen(true)}
                  >
                    {t('rescheduleRulesDialog.action')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.infoRow}>
            <UserIcon />
            <div>
              <div className={styles.fullName}>{data.displayName}</div>
              {data.displayCompanyName && (
                <div className={styles.companyName}>{data.displayCompanyName}</div>
              )}
            </div>
          </div>
          {description && <div className={styles.descriptionRow}>{description}</div>}
        </ModalHeader>
      )}

      <ModalContent>
        {!billingInfoOpen && children}
        {billingInfoOpen && <RescheduleInfoDialog />}
      </ModalContent>
    </ModalDialog>
  );
}
