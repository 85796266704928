import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getCookie } from '@wisory-international-ab/login-frontend';
import { RequireAuth, PermissionGuard } from '@common/components';
import { APP_ROUTES, APP_SELECTORS } from '@constants';
import AuthProvider from '@context/auth';
import { QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { useDidMount } from 'rooks';
import { useTranslation } from 'react-i18next';
import swedishAntd from 'antd/locale/sv_SE';
import englishAntd from 'antd/locale/en_US';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LogoutPage from '@/pages/logout';
import CustomerAcceptBooking from '@/pages/customer-accept-booking';
import AdvisorAcceptBooking from '@/pages/advisor-accept-booking';
import { queryClient } from './common/utils';
import { PRIVATE_ROUTES } from './access';
import 'dayjs/locale/en';
import 'dayjs/locale/sv';
import { NotFoundPage } from './pages/not-found';

const currentLocale = getCookie('i18next') || 'en';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.updateLocale(currentLocale, {
  weekStart: 1,
});
dayjs.locale(currentLocale);

function App() {
  const { t } = useTranslation('common');

  useDidMount(() => {
    window.document.title = t('header.document.title');
  });

  return (
    <div id={APP_SELECTORS.MAIN.slice(1)}>
      <ConfigProvider locale={currentLocale === 'en' ? englishAntd : swedishAntd}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <Routes>
                <Route element={<RequireAuth />}>
                  {PRIVATE_ROUTES.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <PermissionGuard
                          componentToRender={route.element}
                          roles={route.allowRoles}
                        />
                      }
                    />
                  ))}
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route
                  path={APP_ROUTES.CUSTOMER_ACCEPT_BOOKING}
                  element={<CustomerAcceptBooking />}
                />
                <Route
                  path={APP_ROUTES.ADVISOR_ACCEPT_BOOKING}
                  element={<AdvisorAcceptBooking />}
                />
                <Route path={APP_ROUTES.LOG_OUT} element={<LogoutPage />} />
              </Routes>
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
}
//http://local.wisorylab.com:3000/customer-accept-booking?token=358195288D63E3468BBEB6180049896D52685062F88E757A913013D05E93D5B7

export default App;
