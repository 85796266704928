import { Grid } from 'antd';
import classNames from 'classnames';
import { APP_SELECTORS, GLOBAL_TITLE_SELECTOR_ID } from '@/constants';
import { ProfileItem } from '../profile-item';
import { Logo } from '../logo-item';
import { NavigationItems } from '../navigation-items';
import { LanguageSwitcher } from '../language-switcher';
import { MobileUI } from '../mobile-ui';
import styles from './styles.module.scss';

const { useBreakpoint } = Grid;

export function DesktopUI() {
  const screens = useBreakpoint();
  const isSmallScreen = screens.xs || (screens.sm && !screens.lg && !screens.xl && !screens.xxl);
  const showColumnLayout = !screens.xxl;

  if (isSmallScreen) {
    return <MobileUI />;
  }

  return (
    <div id={APP_SELECTORS.HEADER.slice(1)} className={classNames(styles.headerWrapper)}>
      <div
        className={classNames(styles.headerContainer, {
          [styles.lgScreenSize]: !showColumnLayout,
        })}
      >
        <div className={styles.leftContent}>
          <Logo />
          <div className={styles.menuList}>
            <NavigationItems />
          </div>
        </div>
        <div className={styles.menu}>
          <ProfileItem />
          <LanguageSwitcher className={styles.languageSwitcher} />
        </div>
      </div>
      <div
        className={classNames(styles.navigationHeaderTitleWrapper, {
          [styles.lgScreenSize]: !showColumnLayout,
        })}
        id={GLOBAL_TITLE_SELECTOR_ID}
      />
    </div>
  );
}
