export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function isDuplicatedArray(arr: any[]) {
  if (arr.length !== new Set(arr).size) {
    return true;
  }

  return false;
}
