import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      //refetchOnWindowFocus: true,
      //refetchOnMount: true,
      refetchOnReconnect: false,
    },
  },
});

const refetch = (queries: string[]) => {
  queryClient.invalidateQueries(queries);
};

export { queryClient, refetch };
