import { Layout } from 'antd';
import MainContent from './components/main-content';
import Header from './components/header';
import styles from './styles.module.scss';

type Props = React.PropsWithChildren<{}>;

export function MainLayout({ children }: Props) {
  return (
    <Layout className={styles.layout}>
      <Header />
      <MainContent>{children}</MainContent>
    </Layout>
  );
}
