import { CenteredSpinner, MainLayout } from '@common/components';
import { Outlet } from 'react-router-dom';
import { getToken } from '@wisory-international-ab/login-frontend';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks';
import ApplicationProvider from '@/context/application-settings';

export function RequireAuth() {
  const { t } = useTranslation('common');
  const { goToLoginPage, getProfile, user } = useAuth();

  useEffect(() => {
    const userSession = getToken();

    if (!userSession) {
      goToLoginPage();
    } else if (userSession && !user) {
      getProfile().catch(() => {
        goToLoginPage();
      });

      return;
    }
  }, [getProfile, goToLoginPage, user]);

  if (!user) {
    return (
      <div style={{ height: 200 }}>
        <CenteredSpinner message={t('share.navigating')} />
      </div>
    );
  }

  return (
    <ApplicationProvider>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </ApplicationProvider>
  );
}
