import { Button, Result } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import {
  api,
  capitalizeFirstLetter,
  createCounterProposalUrl,
  formatDate,
  formatTimeRange,
} from '@/common/utils';
import { ConfirmedIcon } from '@/common/components/icons';
import {
  ErrorSection,
  MainLayout,
  ModalContent,
  ModalDialog,
  ModalHeader,
} from '@/common/components';
import { trackUser } from '@/common/services/monitor';
import { ERROR_CODE } from '@/constants';
import styles from './styles.module.scss';

type SubmitBookingError = {
  statusCode: string;
  errorCode: string;
  errorMessage: string;
  errorData: {
    customerFullname: string;
    customerFirstName: string;
    customerLastName: string;
    customerCompanyName: string;
    sessionDate: string;
  };
};

// const sampleData = {
//   id: 'd89c7067-9e5c-471d-bbab-5697faae36f6',
//   advisorId: 310,
//   advisorFullName: 'Advisor Ross',
//   customerId: 311,
//   customerFullName: 'Ross Customer',
//   notes: 'Hello',
//   createdDate: '2024-04-28T06:14:12Z',
//   timeSlots: [
//     {
//       id: '9702d762-ad94-4117-8ab4-c1478249810d',
//       interestRequestId: 'd89c7067-9e5c-471d-bbab-5697faae36f6',
//       status: 'Available',
//       slotType: 'NONE',
//       hashData: 'F026D265E90B1F254A18D6CB368D16D07F78131DC670C907979368B804CF77AD',
//       isSelected: true,
//       version: 10,
//       startTime: '2024-04-30T00:00:00Z',
//       endTime: '2024-04-30T01:00:00Z',
//     },
//     {
//       id: 'afcb0178-b370-48d1-a762-03171159dec1',
//       interestRequestId: 'd89c7067-9e5c-471d-bbab-5697faae36f6',
//       status: 'Available',
//       slotType: 'NONE',
//       hashData: '29545FA17E5D7B07F15C68667276893570CAA577F943DFE305E92202B6C44C42',
//       isSelected: false,
//       version: 10,
//       startTime: '2024-04-29T00:00:00Z',
//       endTime: '2024-04-29T01:00:00Z',
//     },
//   ],
//   reason: 'Challenged,Understanding/Clarity',
//   locale: 'en-US',
//   status: 'ADMIN_SENT_PROPOSAL_TO_USER',
//   message: '',
//   bookingId: 4327,
//   pingPongCount: 10,
//   lastModifiedDate: '2024-04-28T06:57:59Z',
//   isRescheduled: true,
//   wasRescheduled: true,
//   companyName: 'Wisory QA Company',
//   companyId: 1,
//   rescheduledUserId: 310,
//   latestVersion: 10,
//   bookingQuality: 'Untrack',
//   bookedStartTime: '2024-04-30T02:00:00+00:00',
//   bookedEndTime: '2024-04-30T03:00:00+00:00',
//   advisorFirstName: 'Advisor',
//   advisorLastName: 'Ross',
//   customerFirstName: 'Ross',
//   customerLastName: 'Customer',
// };

const TokenError = () => {
  const { t } = useTranslation('common');
  return (
    <MainLayout>
      <ErrorSection
        className={styles.errorWrapper}
        message={t('advisorAcceptBooking.errorTitle')}
      />
    </MainLayout>
  );
};

const SubmitError = (props: { message: JSX.Element }) => {
  return (
    <MainLayout>
      <ErrorSection className={styles.errorWrapper} message={props.message} />
    </MainLayout>
  );
};

export default function CustomerAcceptBooking() {
  const { t } = useTranslation('common');
  const [params] = useSearchParams({ token: '' });
  const navigate = useNavigate();
  const token = params.get('token') || undefined;
  const { data, isError } = useQuery({
    // @ts-ignore
    queryFn: () => {
      return api.pingpongApi.interestRequests.getInterestRequestByToken(
        {
          token,
        }, // @ts-ignore
        { skipGlobalErrorMessage: true }
      );
    },
    onSuccess(successData) {
      trackUser({
        name: successData.customerFullName,
        id: successData.customerId,
        company: successData.companyName,
        role: 'User',
      });
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!token,
  });

  const bookAction = useMutation(() => {
    return api.pingpongApi.interestRequests.bookCalendarEventByToken(
      {},
      { token }, // @ts-ignore
      { skipGlobalErrorMessage: true }
    );
  });

  if (!token) {
    return <TokenError />;
  }

  const selected = data?.timeSlots?.find((s) => s.isSelected);

  if (!selected || !data || isError) {
    return <TokenError />;
  }

  if (bookAction.isSuccess) {
    return (
      <Result
        status="success"
        title={t('customerAcceptBooking.successTitle')}
        extra={[
          <Button type="primary" key="login" onClick={() => navigate('/')}>
            {t('customerAcceptBooking.loginBtn')}
          </Button>,
        ]}
      />
    );
  } else if (bookAction.isError) {
    const error = bookAction?.error as SubmitBookingError;
    const message =
      error?.errorCode === ERROR_CODE.COMPANY_INSUFFICENT_BALANCE ? (
        <Trans
          t={t}
          i18nKey="errorSection.companyInsufficientBalance"
          values={{
            fullName: error.errorData.customerFullname,
            companyName: error.errorData.customerCompanyName,
          }}
        />
      ) : (
        <Trans t={t} i18nKey="errorSection.generalSubmitError" />
      );

    return <SubmitError message={message} />;
  }

  return (
    <MainLayout>
      <ModalDialog>
        <ModalHeader>
          <div className={styles.infoRow}>
            <div className={styles.iconWrapper}>
              <ConfirmedIcon size="large" />
            </div>

            <div className={styles.textWrapper}>
              <div className={styles.mainText}>{t('customerAcceptBooking.title')}</div>
            </div>
          </div>
          <div className={styles.titleRow}>
            <div className={styles.block}>
              <div className={styles.label}>{t('home.date')}:</div>
              <div>{capitalizeFirstLetter(formatDate(selected.startTime, 'dddd DD MMM'))}</div>
            </div>
            <div className={styles.block}>
              <div className={styles.label}>{t('home.time')}:</div>
              <div>{formatTimeRange(selected.startTime, selected.endTime)}</div>
            </div>
            <div className={styles.block}>
              <div className={styles.label}>{t('home.advisor')}:</div>
              <div>{data.advisorFullName}</div>
            </div>
          </div>
        </ModalHeader>
        <ModalContent>
          <div className={styles.content}>
            <div>{t('customerAcceptBooking.message')}</div>
          </div>
          <div className={styles.bottomWrapper}>
            <div className={styles.buttonsWrapper}>
              <Button
                className={styles.btnWrapper}
                onClick={() => {
                  navigate(createCounterProposalUrl(selected.interestRequestId!));
                }}
                loading={bookAction.isLoading}
              >
                {t('customerAcceptBooking.alternativeBtnText')}
              </Button>
              <Button
                className={styles.btnWrapper}
                type="primary"
                onClick={() => {
                  bookAction.mutateAsync();
                }}
                loading={bookAction.isLoading}
              >
                {t('customerAcceptBooking.confirmBtnText')}
              </Button>
            </div>
          </div>
        </ModalContent>
      </ModalDialog>
    </MainLayout>
  );
}

/*
{
    "id": "d89c7067-9e5c-471d-bbab-5697faae36f6",
    "advisorId": 310,
    "advisorFullName": "Advisor Ross",
    "customerId": 311,
    "customerFullName": "Ross Customer",
    "notes": "Hello",
    "createdDate": "2024-04-28T06:14:12Z",
    "timeSlots": [
        {
            "id": "9702d762-ad94-4117-8ab4-c1478249810d",
            "interestRequestId": "d89c7067-9e5c-471d-bbab-5697faae36f6",
            "status": "Available",
            "slotType": "NONE",
            "hashData": "F026D265E90B1F254A18D6CB368D16D07F78131DC670C907979368B804CF77AD",
            "isSelected": true,
            "version": 10,
            "startTime": "2024-04-30T00:00:00Z",
            "endTime": "2024-04-30T01:00:00Z"
        },
        {
            "id": "afcb0178-b370-48d1-a762-03171159dec1",
            "interestRequestId": "d89c7067-9e5c-471d-bbab-5697faae36f6",
            "status": "Available",
            "slotType": "NONE",
            "hashData": "29545FA17E5D7B07F15C68667276893570CAA577F943DFE305E92202B6C44C42",
            "isSelected": false,
            "version": 10,
            "startTime": "2024-04-29T00:00:00Z",
            "endTime": "2024-04-29T01:00:00Z"
        }
    ],
    "reason": "Challenged,Understanding/Clarity",
    "locale": "en-US",
    "status": "ADMIN_SENT_PROPOSAL_TO_USER",
    "message": "",
    "bookingId": 4327,
    "pingPongCount": 10,
    "lastModifiedDate": "2024-04-28T06:57:59Z",
    "isRescheduled": true,
    "wasRescheduled": true,
    "companyName": "Wisory QA Company",
    "companyId": 1,
    "rescheduledUserId": 310,
    "latestVersion": 10,
    "bookingQuality": "Untrack",
    "bookedStartTime": "2024-04-30T02:00:00+00:00",
    "bookedEndTime": "2024-04-30T03:00:00+00:00",
    "advisorFirstName": "Advisor",
    "advisorLastName": "Ross",
    "customerFirstName": "Ross",
    "customerLastName": "Customer"
}

*/
