import { Table } from 'antd';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getCompanyUsers } from '@/common/services';
import { Card, CenteredSpinner } from '@/common/components';
import { useAuth } from '@/hooks';
import { API_QUERIES } from '@/constants';

export const CompanyUsers = () => {
  const { t } = useTranslation('common');
  const { user } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: [API_QUERIES.DECISION_MAKER_COMPANY_USERS],
    queryFn: () => {
      if (!user?.companyId) {
        return Promise.reject(`User doesn't have company`);
      }

      return getCompanyUsers(user.companyId);
    },
  });

  const columns: ColumnsType<API.ProfileCompanyUserModel> = useMemo(
    () => [
      {
        title: t('company.companyUserFullName'),
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text: string) => text,
      },
      {
        title: t('company.companyUserUsedCoins'),
        dataIndex: 'usedCoins',
        key: 'usedCoins',
        render: (text: string) => text,
      },
      {
        title: t('company.companyUserBookedSession'),
        dataIndex: 'bookedSessionCount',
        key: 'bookedSessionCount',
        render: (text: string) => text,
      },
      {
        title: t('company.companyUserPostponedSession'),
        dataIndex: 'postponedSessionCount',
        key: 'postponedSessionCount',
        render: (text: string) => text,
      },
      {
        title: t('company.companyUserCancelledSession'),
        dataIndex: 'cancelledSessionCount',
        key: 'cancelledSessionCount',
        render: (text: string) => text,
      },
    ],
    [t]
  );

  return (
    <Card title={t('company.companyUsersTitle')} data-dd-privacy="mask">
      <Table
        loading={{
          spinning: isLoading,
          indicator: (
            <div style={{ height: 100 }}>
              <CenteredSpinner />
            </div>
          ),
        }}
        locale={{
          emptyText: () => {
            if (isLoading) {
              return '';
            }

            return t('company.companyUserNoData');
          },
        }}
        columns={columns}
        dataSource={data?.items || []}
        rowKey="id"
      />
    </Card>
  );
};
