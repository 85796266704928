import { Divider } from 'antd';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Updater } from 'use-immer';
import { isWeekend } from '@/common/utils/date';
import { AdvisorSlot } from '../../types';
import styles from './styles.module.scss';
import { MyAvailability } from './components/my-availability';

type Props = {
  editMode: boolean;
  checkedItems: Record<string, AdvisorSlot>;
  setCheckedItems: Updater<Record<string, AdvisorSlot>>;
};

export const AvailablitiyForm = ({ editMode, checkedItems, setCheckedItems }: Props) => {
  const dayToDisplay = useMemo(() => {
    const today = dayjs();

    if (isWeekend(today)) {
      //If today is weekend, display next week
      return today.startOf('week').weekday(7);
    }

    return today.startOf('week');
  }, []);
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState(dayToDisplay);

  return (
    <div>
      <Divider className={styles.divider} />

      <MyAvailability
        firstDayOfTheWeek={firstDayOfTheWeek}
        setFirstDayOfTheWeek={setFirstDayOfTheWeek}
      />
    </div>
  );
};
