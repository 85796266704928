import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DateIcon, TimeIcon } from '@/common/components/icons';
import { InterestRequestView } from '@/common/api/pingpongApiClient';
import { getGreetingTime2 } from '@/common/utils/date';
import { ModalContent, ModalDialog, ModalHeader, Spinner } from '@/common/components';
import styles from './styles.module.scss';

type Props = {
  request: InterestRequestView;
  isAdvisor?: boolean;
  onCancel: VoidFunction;
};

export function PendingSessionDialog({ request, isAdvisor, onCancel }: Props) {
  const { t } = useTranslation('common');

  return (
    <ModalDialog>
      <ModalHeader backgroundColor="light-pink" onCancel={onCancel}>
        <div className={styles.infoRow}>
          <div className={styles.iconWrapper}>
            <Spinner noAnimation color="pink" />
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.mainText}>
              {isAdvisor
                ? t('home.waitingForUserConfirmation')
                : t('home.waitingForAdvisorConfirmation')}
            </div>
            <div className={styles.subText}>
              {t('home.inboxBlock.bookingId', { bookingId: request.bookingId })}
            </div>
          </div>
        </div>
        <div className={styles.titleRow}>
          <div className={styles.label}>
            {t(`${isAdvisor ? 'home.customer' : 'home.advisor'}`)}:
          </div>
          {isAdvisor && (
            <div>
              {request.customerFullName}, {request.companyName}
            </div>
          )}
          {!isAdvisor && <div>{request.advisorFullName}</div>}
        </div>
        <div className={styles.descriptionRow}>
          {t(
            `${
              isAdvisor
                ? 'home.pendingDialog.advisorDescription'
                : 'home.pendingDialog.customerDescription'
            }`
          )}
        </div>
      </ModalHeader>
      <ModalContent>
        <div className={styles.slotSection}>
          {(request.timeSlots || []).map((s, index) => {
            return (
              <div className={styles.timeslotWrapper} key={s.id}>
                {isAdvisor && (
                  <div className={styles.itemWrapper}>
                    <div className={styles.label}>
                      {t('home.interestForm.suggestionLabel')} {index + 1}
                    </div>
                    <div className={styles.date}>
                      <DateIcon /> {dayjs(s.startTime).format('YYYY-MM-DD')}
                    </div>
                    <div className={styles.time}>
                      <TimeIcon /> {dayjs(s.startTime).format('HH:mm')}
                    </div>
                  </div>
                )}
                {!isAdvisor && (
                  <div className={styles.itemWrapper}>
                    <div className={styles.label}>
                      {t('home.interestForm.suggestionLabel')} {index + 1}
                    </div>
                    <div className={styles.date}>
                      <DateIcon /> {dayjs(s.startTime).format('YYYY-MM-DD')}
                    </div>
                    <div className={styles.time}>
                      <TimeIcon /> {getGreetingTime2(s.startTime, s.endTime)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {request.message && (
          <div className={styles.messageToUserWrapper}>
            <div className={styles.messageLabel}>
              {isAdvisor
                ? t('home.interestForm.messageToUser')
                : t('home.interestForm.messageToAdvisor')}
            </div>
            <div className={styles.messageWrapper}>{request.message}</div>
          </div>
        )}
      </ModalContent>
    </ModalDialog>
  );
}
